import { useFormik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";

import {
  CALENDAR_DRAGGING_TYPE,
  OPEN_CLOSE_BOOKING_TYPES,
} from "../../../../constants/calendar";
import { CalendarPriceSchema, CalendarNightSchema } from "../../../../schemas";
import styles from "../style.module.css";
import Calendar from "react-calendar";

const PricePopup = (props) => {
  const today = new Date();
  const [startDate, setStartDate] = useState(
    props?.priceDates?.startDate
      ? moment(props.priceDates.startDate).format("DD MMM YYYY")
      : null
  );

  const [endDate, setEndDate] = useState(
    props?.priceDates?.endDate
      ? moment(props.priceDates.endDate).format("DD MMM YYYY")
      : null
  );

  useEffect(() => {
    setStartDate(
      props?.priceDates?.startDate
        ? moment(props.priceDates.startDate).format("DD MMM YYYY")
        : null
    );

    setEndDate(
      props?.priceDates?.endDate
        ? moment(props.priceDates.endDate).format("DD MMM YYYY")
        : null
    );
  }, [props.priceDates]);

  useEffect(() => {
    if (startDate && endDate)
      props.setPriceDragDate({
        startDate: moment(startDate).startOf("day").utc().toISOString(),
        endDate: moment(endDate).startOf("day").utc().toISOString(),
      });
  }, [startDate, endDate]);

  const disablePastDates = ({ date, view }) => {
    return date < today;
  };

  const pricePopupFormik = useFormik({
    initialValues: { price: 0, openCloseStatus: null, night: 0 },
    validationSchema: CalendarPriceSchema,
    onSubmit: (values) => {
      let openCloseStatus;

      if (props.selectedDates.length > 1) {
        openCloseStatus = values.openCloseStatus;
      } else if (
        (props.priceDetails.isBookable === OPEN_CLOSE_BOOKING_TYPES.OPEN &&
          values.openCloseStatus === OPEN_CLOSE_BOOKING_TYPES.OPEN) ||
        (props.priceDetails.isBookable === OPEN_CLOSE_BOOKING_TYPES.CLOSE &&
          values.openCloseStatus === OPEN_CLOSE_BOOKING_TYPES.CLOSE)
      ) {
        openCloseStatus = null;
      } else {
        openCloseStatus = values.openCloseStatus;
      }

      props.updatePrice(values, openCloseStatus);
    },
    enableReinitialize: true,
  });

  const nightsPopupFormik = useFormik({
    initialValues: { night: 0 },
    onSubmit: (values) => {
      props.updatePrice(values.night);
    },
    enableReinitialize: true,
    validationSchema: CalendarNightSchema,
  });

  useEffect(() => {
    if (props.priceDetails && typeof props.priceDetails.minNight == "number") {
      nightsPopupFormik.setValues({
        night: props.priceDetails.minNight,
      });
    }
    if (props.priceDetails && typeof props.priceDetails.price == "number") {
      pricePopupFormik.setValues({
        price: parseFloat(props.priceDetails.price),
        openCloseStatus: props.priceDetails.isBookable,
        night: props.priceDetails.minNight,
      });
    }
  }, [props.priceDetails]);

  return (
    <div
      className={`modal fade ${props.show ? "show d-block" : ""}`}
      id="calendar-price"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              Change{" "}
              {props?.priceDetails?.dragType ===
              CALENDAR_DRAGGING_TYPE.NIGHT_DRAGGING
                ? "Length of stay"
                : "Price"}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => props.handlePriceModal(false)}
            ></button>
          </div>
          <div className="px-3 pt-2">
            <p className={styles.categoryName}>
              {props?.selectedProperty?.category_id?.name} (Room ID:{" "}
              {props?.selectedProperty?.unitNumber})
            </p>
            <p className={styles.rate}>Standard Rate</p>
            <p className={styles.priceDate}>
              {moment(startDate).format("DD MMM YYYY")}{" "}
              {!moment(startDate).isSame(moment(endDate))
                ? `to ${moment(endDate).format("DD MMM YYYY")}`
                : ""}
            </p>
            {startDate && endDate ? (
              <div className="d-flex">
                <Calendar
                  value={startDate}
                  onChange={setStartDate}
                  tileDisabled={disablePastDates}
                />
                <Calendar
                  value={endDate}
                  onChange={setEndDate}
                  tileDisabled={disablePastDates}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
          {props?.priceDetails?.dragType ===
          CALENDAR_DRAGGING_TYPE.PRICE_DRAGGING ? (
            <Fragment>
              <div className="px-3 d-flex pt-2">
                <label className={`rad ${styles.openCloseStatus}`}>
                  <input
                    type="radio"
                    checked={
                      pricePopupFormik.values.openCloseStatus ===
                      OPEN_CLOSE_BOOKING_TYPES.OPEN
                    }
                    name="radio"
                    onChange={() =>
                      pricePopupFormik.setFieldValue(
                        "openCloseStatus",
                        OPEN_CLOSE_BOOKING_TYPES.OPEN
                      )
                    }
                  />
                  <span className="checkmark"></span>
                  Vacant
                </label>
                <label className={`rad ${styles.openCloseStatus}`}>
                  <input
                    type="radio"
                    checked={
                      pricePopupFormik.values.openCloseStatus ===
                      OPEN_CLOSE_BOOKING_TYPES.CLOSE
                    }
                    name="radio"
                    onChange={() =>
                      pricePopupFormik.setFieldValue(
                        "openCloseStatus",
                        OPEN_CLOSE_BOOKING_TYPES.CLOSE
                      )
                    }
                  />
                  <span className="checkmark"></span>
                  Close
                </label>
              </div>
              <div className="px-3">
                {pricePopupFormik.errors.openCloseStatus ? (
                  <p className="text-danger mb-0">
                    {pricePopupFormik.errors.openCloseStatus}
                  </p>
                ) : null}
              </div>
              <div className="modal-body">
                <label>Price</label>
                <input
                  type="text"
                  name="price"
                  id="price"
                  className="form-control px-3 py-2"
                  value={pricePopupFormik.values.price}
                  onChange={pricePopupFormik.handleChange}
                  onBlur={pricePopupFormik.handleBlur}
                />
                {pricePopupFormik.touched.price &&
                pricePopupFormik.errors.price ? (
                  <p className="text-danger mb-0">
                    {pricePopupFormik.errors.price}
                  </p>
                ) : null}
              </div>
              <div className="modal-body">
                <label>Minimum length of stay</label>
                <input
                  type="text"
                  name="night"
                  className="form-control px-3 py-2"
                  value={pricePopupFormik.values.night}
                  onChange={pricePopupFormik.handleChange}
                  onBlur={pricePopupFormik.handleBlur}
                />
                {pricePopupFormik.touched.night &&
                pricePopupFormik.errors.night ? (
                  <p className="text-danger mb-0">
                    {pricePopupFormik.errors.night}
                  </p>
                ) : null}
              </div>
            </Fragment>
          ) : (
            <Fragment>
              {/* <div className="modal-body">
                <label>Minimum length of stay</label>
                <input
                  type="text"
                  name="night"
                  className="form-control px-3 py-2"
                  value={nightsPopupFormik.values.night}
                  onChange={nightsPopupFormik.handleChange}
                  onBlur={nightsPopupFormik.handleBlur}
                />
                {nightsPopupFormik.touched.night &&
                nightsPopupFormik.errors.night ? (
                  <p className="text-danger mb-0">
                    {nightsPopupFormik.errors.night}
                  </p>
                ) : null}
              </div> */}
            </Fragment>
          )}
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={(event) =>
                props.priceDetails.dragType ===
                CALENDAR_DRAGGING_TYPE.PRICE_DRAGGING
                  ? pricePopupFormik.handleSubmit(event)
                  : nightsPopupFormik.handleSubmit(event)
              }
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricePopup;
