import ADMIN_ACTIONS from "../actions";

const initialState = {
    dashboardDetails: {},
};

const adminDashboardReducer = (state = initialState, action) =>  {
    if(action.type === ADMIN_ACTIONS.ADMIN_DASHBOARD_SUCCESS) {
        return {
            ...state,
            dashboardDetails: action.dashboardDetails,
        };
    }

    return state;
};

export default adminDashboardReducer;