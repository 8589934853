import { Axios } from "../lib";

const calculateGCDOfFile = (width, height, targetRatio) => {
  const actualRatio = width / height;
  const tolerance = 0.01; // Allow a 1% tolerance for floating point inaccuracies
  return Math.abs(actualRatio - targetRatio) < tolerance;
};

// const isAspectRatio4by3 = (width, height) => {
//   const divisor = calculateGCDOfFile(width, height);
//   const aspectRatio = width / divisor + ":" + height / divisor;

//   return (
//     aspectRatio === "4:3" ||
//     aspectRatio === "3:4" ||
//     aspectRatio === "3:2" ||
//     aspectRatio === "2:3"
//   );
// };

const isAspectRatio4by3 = (width, height) => {
  // Target aspect ratios
  const aspectRatios = [
    4 / 3,  // 4:3
    3 / 4,  // 3:4
    3 / 2,  // 3:2
    2 / 3   // 2:3
  ];

  // Check if the image aspect ratio is close to any of the target ratios
  return aspectRatios.some(ratio => calculateGCDOfFile(width, height, ratio));
};


const processImage = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.onerror = (error) => {
      console.error("FileReader error:", error);
      resolve({
        file,
        aspectRatio: null,
        is4by3: false,
        error: true,
        errorMessage: "Failed to read the file.",
      });
    };

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onerror = (error) => {
        console.error("Image load error:", error);
        // Return data with error status
        resolve({
          file,
          aspectRatio: null,
          is4by3: false,
          error: true,
          errorMessage: "Failed to load the image.",
        });
      };

      img.onload = () => {
        const aspectRatio = img.width / img.height;
        const isApprox4by3 = isAspectRatio4by3(img.width, img.height); // Assuming you have a helper function to check the 4:3 aspect ratio.

        resolve({
          file,
          aspectRatio,
          is4by3: isApprox4by3,
          error: false,
          errorMessage: "",
        });
      };
    };

    reader.readAsDataURL(file);
  });
};

const uploadImage = (imagesFormData) => {
  return Axios.post("v1/files/upload", imagesFormData);
};

export { calculateGCDOfFile, isAspectRatio4by3, processImage, uploadImage };
