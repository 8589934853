import ADMIN_ACTIONS from "../actions";
import VENDOR_ACTIONS from "../actions";

const initialState = {
    payoutLoader: false,
    payoutCards: [],
    payoutCardsCount: 0,
   
};

const adminPayoutReducer = (state = initialState, action) => {

    switch (action.type) {
        case ADMIN_ACTIONS.REQUEST_ADMIN_PAYOUT_CARDS:
            return {
                ...state,
                payoutLoader: action.status,
            };


        case ADMIN_ACTIONS.ADMIN_PAYOUT_CARDS_SUCCESS:
            return {
                ...state,
                payoutCards: action.payouts,
                payoutCardsCount: action.count,
            };

        default:
            return state;
    }
};

export default adminPayoutReducer;