import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { MESSAGES, PAYOUT_CARD_STATUS } from "../../../../constants";
import { APP_ROUTES } from "../../../../routes";
import tableStyles from "../../../../components/Table/style.module.css";
import commonStyles from '../../../../pages/Common/styles/common.module.css';
import { VendorPayoutAction } from "../../../../store/vendor/thunk";
import { Button } from "../../../../components";

const ViewPayoutCards = ({ listPayoutCards, loader, deletePayoutCard }) => {
    const navigate = useNavigate();

    const { payouts: { payoutCardLoader, payoutCards } } = useSelector((state) => state.vendor);

    const getPayouts = () => {

        listPayoutCards(loader);
    };

    const removeCard = (id) => {
        const payload = { bankAccountId: id };
        deletePayoutCard(payload, navigate, loader);
    };

    useEffect(() => {
        getPayouts();
    }, []);

    return (
        <div className={commonStyles["vendor-listing"]}>
            <div className="d-flex justify-content-between align-items-center">
                <ul className="paginaion">
                    <li>
                        <Link to="/">Dashboard</Link>
                    </li>
                    <li>
                        <Link to={APP_ROUTES.VIEW_PAYOUTS}>Payouts</Link>
                    </li>
                    <li className="active">
                        <Link to={APP_ROUTES.VIEW_PAYOUTS_SETTINGS}>Bank Details</Link>
                    </li>
                </ul>
                <Link to={APP_ROUTES.ADD_PAYOUT_CARD}>
                    <Button type='button' handleClick={() => { }} title="Add Bank Detail" />
                </Link>
            </div>


            <div className={tableStyles["listing-table"]}>
                <div className={tableStyles["listing-table-scrl"]}>
                    <table cellPadding={0} cellSpacing={0}>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Bank Account No.</th>
                                <th>Bank Name</th>
                                <th>Bank Identifier Code</th>
                                <th>Status</th>
                                <th>Country</th>
                                <th>City</th>
                                <th>State</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                payoutCardLoader ?
                                    <tr className={tableStyles.noresult}>
                                        <td colSpan={9}>{MESSAGES.LOADING}</td>
                                    </tr>
                                    :
                                    null
                            }
                            {
                                !payoutCardLoader && payoutCards.length === 0 ?
                                    <tr className={tableStyles.noresult}>
                                        <td colSpan={9}>{MESSAGES.PAYOUTS.NO_CARD_FOUND}</td>
                                    </tr>
                                    :
                                    null
                            }
                            {!payoutCardLoader && payoutCards.map((item, index) => (
                                <tr key={index}>
                                    <td>{++index}</td>
                                    <td>{item.accountNumber}</td>
                                    <td>{item.bankName}</td>
                                    <td>{item.bicCode}</td>
                                    <td>{PAYOUT_CARD_STATUS[item.cardStatus]}</td>

                                    <td>{item.address.country}</td>
                                    <td>{item.address.state}</td>
                                    <td>{item.address.city}</td>
                                    <td>
                                        <Link to={`/payout/settings/card/${item._id}`}>
                                            <i className="fas fa-eye"></i>
                                        </Link>

                                        <button onClick={() => removeCard(item._id)}>
                                            <i className="fas fa-trash"></i>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    listPayoutCards: (loader) => dispatch(VendorPayoutAction.listPayoutCards(loader)),
    deletePayoutCard: (payload, navigate, loader) => dispatch(VendorPayoutAction.deletePayoutCard(payload, navigate, loader))
});

export default connect(null, mapDispatchToProps)(ViewPayoutCards);