import ADMIN_ACTIONS from "../actions";

const initialState = {
    cms: {}
};

const adminCMSReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADMIN_ACTIONS.SET_CMS_DETAILS:
            return { ...state, cms: action.cms };

        default:
            return state;
    }
};

export default adminCMSReducer;