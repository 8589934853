import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Switch from "react-switch";
import { connect, useSelector } from "react-redux";
import Swal from "sweetalert2";

import { Pagination } from "../../../components";
import { APP_ROUTES } from "../../../routes";
import { AdminFAQAction } from "../../../store/admin/thunk";
import { MESSAGES } from "../../../constants";
import tableStyles from "../../../components/Table/style.module.css";
import commonStyles from "../../Common/styles/common.module.css";

const Faqs = ({ loader, list, update, remove }) => {
    const navigate = useNavigate();

    const { faq: { faqs, faqCount, requestFAQList } } = useSelector((state) => state.admin);

    const [pagination, setPagination] = useState({ offset: 0, limit: 10 });

    const switchHandler = async (faq_id, status) => {
        let payload = { faq_id, status };

        const query = {
            limit: pagination.limit,
            offset: pagination.offset,
        }

        update(payload, query, loader, navigate);
    };

    const deleteFaq = async (faq_id) => {
        Swal.fire({
            title: MESSAGES.REMOVE_REQUEST,
            text: MESSAGES.FAQ.REQUEST_REMOVE,
            confirmButtonText: "Yes",
            showCancelButton: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                let payload = { faq_id };

                remove(payload, pagination, loader);
            }
        });
    };

    useEffect(() => {
        list(pagination, loader);
    }, [pagination]);

    return (
        <div className={commonStyles["vendor-listing"]}>
            <h3>
                FAQs
                <Link className={commonStyles["cm-btn"]} to={APP_ROUTES.ADD_FAQ}>
                    Add FAQ
                </Link>
            </h3>
            <div className={tableStyles["listing-table"]}>
                <div className={tableStyles["listing-table-scrl"]}>
                    <table cellPadding={0} cellSpacing={0}>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Question</th>
                                <th className="answr">Answer</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {requestFAQList && <tr><td colSpan={4} className="text-center">{MESSAGES.LOADING}</td></tr>}
                            {!requestFAQList && faqs.length === 0 && <tr><td colSpan={4} className="text-center">{MESSAGES.FAQ.NOT_FOUND}</td></tr>}
                            {!requestFAQList && faqs.map((faq, index) => (
                                <tr key={faq._id}>
                                    <td>{++index}</td>
                                    <td>{faq.question}</td>
                                    <td>{faq.answer}</td>
                                    <td>
                                        <Switch
                                            onColor="#e89532"
                                            onChange={() =>
                                                switchHandler(
                                                    faq._id,
                                                    faq.status === "ACTIVE" ? "INACTIVE" : "ACTIVE"
                                                )
                                            }
                                            checked={faq.status === "ACTIVE"}
                                        />
                                    </td>
                                    <td>
                                        <Link state={{ faq }} to={`/faq/${faq._id}`}>
                                            <i className="fas fa-edit"></i>
                                        </Link>
                                        <button onClick={() => deleteFaq(faq._id)}>
                                            <i className="fas fa-trash"></i>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>

                    </table>
                </div>
            </div>
            <Pagination count={faqCount} setPagination={setPagination} />
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    list: (query, loader) => dispatch(AdminFAQAction.list(query, loader)),
    update: (payload, query, loader, navigate) => dispatch(AdminFAQAction.update(payload, query, loader, navigate)),
    remove: (payload, query, loader) => dispatch(AdminFAQAction.remove(payload, query, loader)),
});

export default connect(null, mapDispatchToProps)(Faqs);
