const consectiveBookableRooms = (bookings) => {
    let obj = { true: 0, false: 0 };
    let consectiveBookings = [];
    let lastBooking = bookings[0].bookable;

    for (let i = 0; i < bookings.length; i++) {
        if (i === 0) {
            obj = {
                soldOut: bookings[i].bookable === 2 ? 1 : 0,
                open: bookings[i].bookable === 1 ? 1 : 0,
                closed: bookings[i].bookable === 3 ? 1 : 0
            };
            lastBooking = bookings[i].bookable
        }
        else if (lastBooking === bookings[i].bookable) {
            obj = {
                soldOut: bookings[i].bookable === 2 ? obj.soldOut + 1 : 0,
                open: bookings[i].bookable === 1 ? obj.open + 1 : 0,
                closed: bookings[i].bookable === 3 ? obj.closed + 1 : 0
            };
            if (i === bookings.length - 1) {
                consectiveBookings.push(obj);
            }
        }
        else {
            consectiveBookings.push(obj)
            obj = {
                soldOut: bookings[i].bookable === 2 ? 1 : 0,
                open: bookings[i].bookable === 1 ? 1 : 0,
                closed: bookings[i].bookable === 3 ? 1 : 0
            }
            if (i === bookings.length - 1) {
                if (lastBooking === bookings[i].bookable) {
                    consectiveBookings[consectiveBookings.length - 1] = {
                        soldOut: bookings[i].bookable === 2 ? obj.soldOut + 1 : 0,
                        open: bookings[i].bookable === 1 ? obj.open + 1 : 0,
                        closed: bookings[i].bookable === 3 ? obj.closed + 1 : 0
                    }
                } else {
                    consectiveBookings.push(obj)
                }
            }
            lastBooking = bookings[i].bookable
        }
    }

    return consectiveBookings;
};

export {
    consectiveBookableRooms,
};