import ADMIN_ACTIONS from "../actions";

const initialState = {
    faqs: [],
    faqCount: 0,
    faqDetails: {},
    requestFAQList: false,
    requestFAQ: false,
};

const admninFAQReducer = (state = initialState, action) => {

    switch (action.type) {
        case ADMIN_ACTIONS.REQUEST_SPECIFIC_FAQ:
            return {
                ...state,
                requestFAQ: action.status,
            };
        case ADMIN_ACTIONS.REQUEST_FAQ_LIST:
            return {
                ...state,
                requestFAQList: action.status,
            };
        case ADMIN_ACTIONS.SET_FAQ_LIST:
            return {
                ...state,
                faqs: action.faqs,
                faqCount: action.count,
            };

        case ADMIN_ACTIONS.SET_SPECIFIC_FAQ:
            return {
                ...state,
                faqDetails: action.faqDetails
            };

        default:
            return state;

    }
};

export default admninFAQReducer;