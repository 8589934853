const VENDOR_ACTIONS = {
    SET_PROPERTY_PROMOTIONS: "SET_PROPERTY_PROMOTIONS",
    SET_PROPERTY_PRICE: "SET_PROPERTY_PRICE",
    SET_TOUR_PRICE: "SET_TOUR_PRICE",
    UPDATE_PROPERTY_PRICE: "UPDATE_PROPERTY_PRICE",
    SET_PRICE_MODAL: "SET_PRICE_MODAL",
    SET_PRICE_DATES: "SET_PRICE_DATES",
    SET_SPECIFIC_PROMOTION: "SET_SPECIFIC_PROMOTION",
    SET_CAR_DETAILS: "SET_CAR_DETAILS",
    SET_PROPERTY_BOOKING_REQUESTS: "SET_PROPERTY_BOOKING_REQUESTS",
    SET_CAR_BOOKING_REQUESTS: "SET_CAR_BOOKING_REQUESTS",
    SET_TOUR_BOOKING_REQUESTS: "SET_TOUR_BOOKING_REQUESTS",
    REQUEST_SPECIFIC_BOOKING: "REQUEST_SPECIFIC_BOOKING",
    SET_SPECIFIC_BOOKING_REQUEST: "SET_SPECIFIC_BOOKING_REQUEST",
    RESET_PAYMENT_STATUS: "RESET_PAYMENT_STATUS", 
    SET_REQUEST_LOADER: "SET_REQUEST_LOADER",
    SET_TOUR_DETAILS: "SET_TOUR_DETAILS",
    SET_TOUR_PRICING_LOADER: "SET_TOUR_PRICING_LOADER",
    REQUEST_SPECIFIC_TOUR: "REQUEST_SPECIFIC_TOUR",
    REQUEST_PAYOUTS: "REQUEST_PAYOUTS",
    SET_PAYOUTS: "SET_PAYOUTS",
    SET_CREATE_PROPERTY_LOADER: "SET_CREATE_PROPERTY_LOADER",
    REQUEST_PAYOUT_CARD: 'REQUEST_PAYOUT_CARD',
    REQUEST_SPECIFIC_PAYOUT_CARD: 'REQUEST_SPECIFIC_PAYOUT_CARD',
    SPECIFIC_PAYOUT_CARD_SUCCESS: 'SPECIFIC_PAYOUT_CARD_SUCCESS',
    PAYOUT_CARD_SUCCESS: 'PAYOUT_CARD_SUCCESS'
};

export default VENDOR_ACTIONS;