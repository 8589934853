import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { connect, useSelector } from "react-redux";
import { debounce } from "lodash";
import nsearch from "../../../assets/images/nsearch.png";
import dn from "../../../assets/images/dn.png";
import fltic from "../../../assets/images/fltic.png";
import plus from "../../../assets/images/plus.png";
import grlocation from "../../../assets/images/grlocation.svg";

import {
  SORTING_FIELDS,
  USER_TYPE,
  CATEGORY_TYPE,
  MESSAGES,
} from "../../../constants";
import { APP_ROUTES } from "../../../routes";
import { CommonAction } from "../../../store/common/thunk";
import { AdminPropertyAction } from "../../../store/admin/thunk";
import { VendorPropertyAction } from "../../../store/vendor/thunk";
import { Button, Pagination, PropertyCarTour } from "../../../components";
import {
  FilterIcon,
  SearchIcon,
  LocationWhiteIcon,
  DeleteIcon,
  ViewIcon,
  GuestIcon,
  BedIcon,
  BathroomIcon,
} from "../../../assets/images";
import tableStyles from "../../../components/Table/style.module.css";
import commonStyles from "../styles/common.module.css";
import AddButton from "../../../components/Button/AddButton";

const Properties = ({
  loader,
  propertyList,
  approveProperty,
  removeProperty,
  categoriesList,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    propertiesList,
    propertiesCount,
    user,
    categories,
    loadingPropertyList,
  } = useSelector((state) => state.common);

  const [textSearch, setTextSearch] = useState("");
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 6,
  });
  const [category, setCategory] = useState("");
  const [approval, setApproval] = useState("");
  const [sorting, setSorting] = useState({ type: null, order: null });
  const [mobFilter, setMobFilter] = useState(false);
  const [isApprove, setIsApprove] = useState(false);

  // useEffect(()=>{
  //   if(window.localStorage.getItem("pageName") == location?.pathname) {
  //     // setPagination({...pagination, poffset : window.localStorage.getItem("pageNo") })
  //     setPagination((prev) => {
  //       return { ...prev, offset: window.localStorage.getItem("pageNo") * prev.limit };
  //   });
  //     // window.localStorage.removeItem("pageName")
  //     // window.localStorage.removeItem("pageNo")
  //   }

  // },[])

  const getProperties = async (
    offset = location?.pathname === window.localStorage.getItem("pageName")
      ? window.localStorage.getItem("PageNo") * 6
      : pagination.offset,
    search = textSearch
  ) => {
    let query = {
      limit: pagination.limit,
      offset,
      sort: sorting.type,
      sortType: sorting.order,
    };

    if (search && search.length) query.searchString = search;
    if (category) query.categoryId = category;

    if (approval) query.isApproved = parseInt(approval, 10) === 1;
    propertyList(query, loader);
  };

  const switchHandler = async (property_id, isApproved) => {
    const query = { property_id, isApproved };
    approveProperty(query, loader, pagination).then((res) => {
      setIsApprove(!isApprove);
    });

    // setIsApprove(!isApprove);
  };

  const deleteProperty = async (property_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to remove this property",
      confirmButtonText: "Yes",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const query = { property_id };

        removeProperty(query, loader, pagination);
      }
    });
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value.length ? e.target.value : null);
    setPagination({ offset: 0, limit: pagination.limit });
  };

  const handleApprovalChange = (e) => {
    setApproval(e.target.value.length ? e.target.value : null);
    setPagination({ offset: 0, limit: pagination.limit });
  };

  const handleSortingChange = (e) => {
    const value = e.target.value.split("_");

    if (value.length > 1) {
      setSorting({ type: value[0], order: parseInt(value[1]) === 0 ? -1 : 1 });
    } else setSorting({ type: null, value: null });
  };

  const clearResult = () => {
    setCategory("");
    setApproval("");
    setTextSearch("");

    getProperties(0, "");
    setSorting({ type: null, order: null });
  };

  // const debouncePropertyList = debounce(() => getProperties(0), 1000);

  useEffect(() => {
    setTimeout(() => {
      getProperties();
    }, 1000);
  }, [pagination, sorting, isApprove]);

  useEffect(() => {
    const query = { type: CATEGORY_TYPE.PROPERTY };

    categoriesList(query, loader);
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <>
      <PropertyCarTour />
      <div className={commonStyles["vendor-listing"]}>
        <div className="d-flex justify-content-between mb-3 align-items-center no-margin">
          <ul className="paginaion">
            <li>
              <Link to="/">Dashboard</Link>
            </li>
            <li className="active">
              <Link to="/properties">Properties</Link>
            </li>
          </ul>
        </div>

        <div className={commonStyles["filter-main"]}>
          <div className={commonStyles["filter-left"]}>
            <div className="form-group position-relative mobFilter">
              <span className={commonStyles["filer-iic"]}>
                <img src={FilterIcon} alt="filter" />
              </span>
              <select
                name="categories"
                onChange={handleApprovalChange}
                className="mx-1"
                value={approval}
              >
                <option value="">Filter by Approval</option>
                <option key="1" value="1">
                  Approved
                </option>
                <option key="0" value="0">
                  Pending
                </option>
              </select>
            </div>
            <div className="form-group position-relative mobFilter">
              <span className={commonStyles["filer-iic"]}>
                <img src={FilterIcon} alt="Filter" />
              </span>
              <select
                name="categories"
                onChange={handleCategoryChange}
                className="mx-1"
                value={category}
              >
                <option value="">Filter by Category</option>
                {categories.map((category) => (
                  <option key={category._id} value={category._id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group position-relative mobFilter">
              <span className={commonStyles["filer-iic"]}>
                <img src={FilterIcon} alt="filter" />
              </span>
              <select
                name="sorting"
                onChange={handleSortingChange}
                className="me-1"
                value={`${sorting.type}_${sorting.order}`}
              >
                <option value="">Sort</option>
                <option value={SORTING_FIELDS.NAME_ASC}>Name (ASC)</option>
                <option value={SORTING_FIELDS.NAME_DEC}>Name (DEC)</option>
                <option value={SORTING_FIELDS.REFERENCE_NO_ASC}>
                  Referenco No (ASC)
                </option>
                <option value={SORTING_FIELDS.REFERENCE_NO_DEC}>
                  Referenco No (DEC)
                </option>
                <option value={SORTING_FIELDS.UNIT_NUMBER_ASC}>
                  Unit No (ASC)
                </option>
                <option value={SORTING_FIELDS.UNIT_NUMBER_DEC}>
                  Unit No (DEC)
                </option>
              </select>
            </div>
            <div className="form-group position-relative mobile-search">
              <span className={tableStyles["search-c"]}>
                <img className="desk-ic" src={SearchIcon} alt="search" />
                <img className="mob-ic" src={nsearch} alt="search" />
              </span>
              <input
                type="text"
                placeholder="Search by name or ref number"
                onChange={(e) => setTextSearch(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") debouncePropertyList();
                }}
                value={textSearch}
              />
              {textSearch.length || category || approval || sorting.order ? (
                <button
                  className={tableStyles["search-button"]}
                  onClick={clearResult}
                >
                  <i className="fa fa-eraser" />
                </button>
              ) : null}
            </div>
            <div className="propertyType">
              <select>
                <option>Propery Type</option>
              </select>
              <span>
                <img src={dn} alt="search" />
              </span>
            </div>
            <div className="FilterMob">
              <span onClick={() => setMobFilter(!mobFilter)}>
                <img src={fltic} alt="" />
              </span>
              {mobFilter && (
                <div className="filterMobDropdown">
                  <div className="form-group position-relative ">
                    <span className={commonStyles["filer-iic"]}>
                      <img src={FilterIcon} alt="filter" />
                    </span>
                    <select
                      name="categories"
                      onChange={handleApprovalChange}
                      className="mx-1"
                      value={approval}
                    >
                      <option value="">Filter by Approval</option>
                      <option key="1" value="1">
                        Approved
                      </option>
                      <option key="0" value="0">
                        Pending
                      </option>
                    </select>
                  </div>
                  <div className="form-group position-relative ">
                    <span className={commonStyles["filer-iic"]}>
                      <img src={FilterIcon} alt="Filter" />
                    </span>
                    <select
                      name="categories"
                      onChange={handleCategoryChange}
                      className="mx-1"
                      value={category}
                    >
                      <option value="">Filter by Category</option>
                      {categories.map((category) => (
                        <option key={category._id} value={category._id}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group position-relative ">
                    <span className={commonStyles["filer-iic"]}>
                      <img src={FilterIcon} alt="filter" />
                    </span>
                    <select
                      name="sorting"
                      onChange={handleSortingChange}
                      className="me-1"
                      value={`${sorting.type}_${sorting.order}`}
                    >
                      <option value="">Sort</option>
                      <option value={SORTING_FIELDS.NAME_ASC}>
                        Name (ASC)
                      </option>
                      <option value={SORTING_FIELDS.NAME_DEC}>
                        Name (DEC)
                      </option>
                      <option value={SORTING_FIELDS.REFERENCE_NO_ASC}>
                        Referenco No (ASC)
                      </option>
                      <option value={SORTING_FIELDS.REFERENCE_NO_DEC}>
                        Referenco No (DEC)
                      </option>
                      <option value={SORTING_FIELDS.UNIT_NUMBER_ASC}>
                        Unit No (ASC)
                      </option>
                      <option value={SORTING_FIELDS.UNIT_NUMBER_DEC}>
                        Unit No (DEC)
                      </option>
                    </select>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="webadd-btn">
            {user.userType === USER_TYPE.VENDOR ? (
              <Button
                type="button"
                handleClick={() => navigate(APP_ROUTES.ADD_PROPERTY)}
                title="Add"
              />
            ) : null}
          </div>
        </div>

        <div className={` mobile-slider ${commonStyles["new-tab-design"]}`}>
          <div className="row">
            {loadingPropertyList ? (
              <div className="d-flex justify-content-center align-items-center flex-column loading-text">
                <h5>{MESSAGES.LOADING}</h5>
              </div>
            ) : null}
            {!loadingPropertyList && propertiesList.length === 0 ? (
              <p className="text-center">{MESSAGES.PROPERTY.NOT_FOUND}</p>
            ) : null}
            {!loadingPropertyList &&
              propertiesList.map((property) => (
                <div className="col-md-4" key={property._id}>
                  <div className={commonStyles["inner-tab-deisign"]}>
                    <div className={commonStyles["top-card-had"]}>
                      <div className={commonStyles["left-slider"]}>
                        <Slider {...settings}>
                          {property?.imageCategories?.length > 0
                            ? property.imageCategories[0].categoriesImages?.map(
                                (image, key) => (
                                  <div key={key}>
                                    <img src={image} alt="property" />
                                  </div>
                                )
                              )
                            : property.images.map((image, key) => (
                                <div key={key}>
                                  <img src={image} alt="property" />
                                </div>
                              ))}
                        </Slider>

                        <div className={commonStyles.overlay}>
                          <div className={commonStyles["action-btn"]}>
                            {user.userType === USER_TYPE.VENDOR ? (
                              <button
                                className={commonStyles["delet-btn"]}
                                onClick={() => deleteProperty(property._id)}
                              >
                                <img src={DeleteIcon} alt="deleteProperty" />
                              </button>
                            ) : null}
                            <Link to={`/property/${property._id}`}>
                              <button className={commonStyles["view-btn"]}>
                                {" "}
                                <img src={ViewIcon} alt="view-property" />
                              </button>
                            </Link>
                          </div>
                        </div>
                        <div
                          className={`${commonStyles["webview"]} ${commonStyles["locaton-card"]}`}
                        >
                          <p>
                            <img
                              className="show-web"
                              src={LocationWhiteIcon}
                              alt="location"
                            />
                            <img
                              className="show-mob"
                              src={grlocation}
                              alt="location"
                            />
                            {property.location}
                          </p>
                        </div>
                      </div>

                      <div className={commonStyles["loc-left"]}>
                        <h3>{property.name}</h3>
                        <div
                          className={`${commonStyles["mobview"]} ${commonStyles["locaton-card"]}`}
                        >
                          <p>
                            <img
                              className="show-web"
                              src={LocationWhiteIcon}
                              alt="location"
                            />
                            <img
                              className="show-mob"
                              src={grlocation}
                              alt="location"
                            />
                            {property.location}
                          </p>
                        </div>
                        {user.userType === USER_TYPE.ADMIN && (
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={property.isApproved}
                              id="flexSwitchCheckDefault"
                              onChange={() =>
                                switchHandler(
                                  property._id,
                                  !property.isApproved
                                )
                              }
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    {/* <div className={commonStyles.newMobPrice}>
                      <span>$ 1200</span>
                    </div> */}
                    <div className={commonStyles["bed-rooms"]}>
                      <ul>
                        <li>
                          <img src={GuestIcon} alt="guests" />
                          {property.personCapacity} Guest
                        </li>
                        <li>
                          <img src={BedIcon} alt="bedrooms" />
                          {property.bedRoomsCount} Bedroom
                        </li>
                        <li>
                          <img src={BathroomIcon} alt="bathrooms" />
                          {property.bathRoomsCount} Bathroom
                        </li>
                      </ul>
                    </div>
                    <div className={commonStyles.refrela}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className={commonStyles["rf-left"]}>
                            <h6>Reference No:</h6>
                            <span>{property.referenceNumber}</span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className={commonStyles["rf-left"]}>
                            <h6>Unit No:</h6>
                            <span>{property.unitNumber}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>

          {user.userType === USER_TYPE.VENDOR ? (
            // <Link to={APP_ROUTES.ADD_PROPERTY}>
            //   <button
            //     type="button"
            //     className="add-mob-btn"
            //     handleClick={() => navigate(APP_ROUTES.ADD_PROPERTY)}
            //     title=""
            //   >
            //     <span>
            //       <img src={plus} alt="" />
            //     </span>
            //   </button>
            // </Link>
            <AddButton route={APP_ROUTES.ADD_PROPERTY} />
          ) : null}

          <Pagination
            count={propertiesCount}
            setPagination={setPagination}
            pageSize={pagination.limit}
          />
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  propertyList: (query, loader) =>
    dispatch(CommonAction.propertyList(query, loader)),
  approveProperty: (params, loader, pagination) =>
    dispatch(AdminPropertyAction.approve(params, loader, pagination)),
  removeProperty: (query, loader, pagination) =>
    dispatch(VendorPropertyAction.remove(query, loader, pagination)),
  categoriesList: (query, loader) =>
    dispatch(CommonAction.categoriesList(query, loader)),
});

export default connect(null, mapDispatchToProps)(Properties);
