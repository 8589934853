import * as Yup from "yup";

import { MESSAGES } from "../constants";

export const TourCalendarPriceSchema = Yup.object().shape({
    price: Yup.number().required(MESSAGES.VALIDATION_ERRORS.REQUIRED).min(1, MESSAGES.VALIDATION_ERRORS.NON_ZERO_AMOUNT),
});

export const TourCalendarMaximumPersonSchema = Yup.object().shape({
    maximumPerson: Yup.number().required(MESSAGES.VALIDATION_ERRORS.REQUIRED).min(1, MESSAGES.VALIDATION_ERRORS.NON_ZERO_PERSON),
});

export const TourCalendarPickupSchema = Yup.object().shape({
    pickUpDrop: Yup.number().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
});

export const TourCalendarSchema = Yup.object().shape({
    subCategories: Yup
        .array()
        .of(
            Yup.object().shape({
                price: Yup.number().required(MESSAGES.VALIDATION_ERRORS.REQUIRED).min(1, MESSAGES.VALIDATION_ERRORS.NON_ZERO_AMOUNT),
                maximumPerson: Yup.number().required(MESSAGES.VALIDATION_ERRORS.REQUIRED).min(1, MESSAGES.VALIDATION_ERRORS.NON_ZERO_PERSON),
                pickUpDrop: Yup.number().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
            })
        )
        .required(),
});