import publicRoutes from "./public";
import privateRoutes from "./private";
import { APP_ROUTES } from "./appRoutes";

export const routes = (setLoader) => {
    const appRoutes = [...publicRoutes(setLoader), ...privateRoutes(setLoader)];

    return appRoutes;
};

export  {
    APP_ROUTES
};