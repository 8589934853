export const TopBar = {
    "/dashboard": "Dashboard",
    "/users": "Users",
    "/user": "User",
    "/vendors": "Vendors",
    "/vendor": "Vendor",
    "/properties": "Properties",
    "/property": "Property",
    "/cars": "Cars",
    "/tours": "Tours",
    "/car": "Car",
    "/categories": "Categories",
    "/category": "Sub Categories",
    "/faqs": "FAQs",
    "/faq": "FAQ",
    "/aboutUs": "About Us",
    "/contactUs": "Contact Us",
    "/logout": "Logout",
    "/cities": "Cities",
    "/city": "City",
    "/notifications": "Notifications",
    "/propertyBookings": "Property Requests",
    "/carBookings": "Car Requests",
    "/sub-admins": "Sub Admins",
    "/chats": "Chat",
};
