import {Axios} from "../../../lib";
import { Toaster } from "../../../components/Toaster";
import ADMIN_ACTIONS from "../actions";
import { API_ENDPOINTS, MESSAGES, TOAST_TYPE } from "../../../constants";

const list = (query, loader) => async (dispatch) => {
    try {
        dispatch({ type: ADMIN_ACTIONS.REQUEST_VENDOR_LIST, status: true });

        const response = await Axios.get(API_ENDPOINTS.LIST_VENDORS, query, loader);
        dispatch({ type: ADMIN_ACTIONS.VENDOR_LIST_SUCCESS, vendors: response.data.vendors, count: response.data.count });
        dispatch({ type: ADMIN_ACTIONS.REQUEST_VENDOR_LIST, status: false });
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.SOMETHING_WENT_WRONG);
    }
};

const specific = (query, loader) => async (dispatch) => {
    try {
        const response = await Axios.get(API_ENDPOINTS.SPECIFIC_VENDOR, query, loader);
        dispatch({ type: ADMIN_ACTIONS.SPECIFIC_VENDOR_SUCCESS, vendor: response.data });
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.SOMETHING_WENT_WRONG);
    }
};

const update = (query, payload, loader) => async (dispatch) => {
    try {
        await Axios.patch(API_ENDPOINTS.UPDATE_VENDOR, payload, loader);

        Toaster(TOAST_TYPE.SUCCESS, `Vendor ${payload.isBlocked ? "blocked" : "unblocked"} successfully`);
        dispatch(list(query, loader));
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.VENDOR.UPDATE_ERROR);
    }
};

export {
    list,
    update,
    specific,
};