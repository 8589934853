import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Switch from "react-switch";
import { connect, useSelector } from "react-redux";
import { debounce } from "lodash";

import { Pagination } from "../../../components";
import { AdminUserAction } from "../../../store/admin/thunk";
import { MESSAGES, SORTING_FIELDS } from "../../../constants";
import { FilterIcon, SortIcon, SearchIcon } from "../../../assets/images";
import tableStyles from "../../../components/Table/style.module.css";
import commonStyles from "../../Common/styles/common.module.css";

const User = ({ loader, getUserList, blockUser }) => {
    const { user: { userList, count, loading } } = useSelector((state) => state.admin);

    const [sorting, setSorting] = useState({ type: null, order: null });
    const [pagination, setPagination] = useState({ offset: 0, limit: 10 });
    const [textSearch, setTextSearch] = useState("");
    const [isBlocked, setBlocked] = useState("");

    const getUsers = async (offset = pagination.offset, search = textSearch) => {
        let query = {
            limit: pagination.limit,
            offset,
            sort: sorting.type,
            sortType: sorting.order,
        };

        if (search && search.length) query.searchString = search;
        if (isBlocked) query.isBlocked = parseInt(isBlocked) === 1;

        getUserList(query, loader);
    };

    const handleSorting = (field) => {
        if (sorting.type === field) {
            setSorting({ ...sorting, order: -1 * sorting.order });
        }
        else {
            setSorting({ type: field, order: 1 });
        }
    };

    const debounceUsersList = debounce(() => getUsers(0), 500);

    const switchHandler = async (userId, isBlocked) => {
        const payload = {
            user_id: userId, isBlocked
        };
        blockUser(pagination, payload, loader);
    };

    const handleIsBlockedChange = (e) => {
        setBlocked(e.target.value.length ? e.target.value : null);

        setPagination({ offset: 0, limit: pagination.limit });
    };

    const clearResult = () => {
        setBlocked("");
        setTextSearch("");

        getUsers(0, "");
    };

    useEffect(() => {

        getUsers();
    }, [pagination, sorting]);

    return (
        <div className={commonStyles["vendor-listing"]}>
            <div className="d-flex justify-content-between align-items-center">
                <ul className="paginaion">
                    <li><Link to="/">Dashboard</Link></li>
                    <li className="active"><Link to="/users">Users</Link></li>
                </ul>
            </div>
            <div className={commonStyles["filter-main"]}>
                <div className={commonStyles["filter-left"]}>
                    <div className="form-group">
                        <span className={commonStyles["filer-iic"]}><img src={FilterIcon} alt="Filter" /></span>
                        <select name="categories" onChange={handleIsBlockedChange} className="mx-1" value={isBlocked}>
                            <option value="">Filter by Status</option>
                            <option key="1" value="1">Blocked</option>
                            <option key="0" value="0">Active</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <span className={tableStyles["search-c"]}>
                            <img src={SearchIcon} alt="search" />
                        </span>
                        <input
                            type='text'
                            placeholder='Search by name'
                            onChange={(e) => setTextSearch(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    debounceUsersList();
                                }
                            }}
                            value={textSearch}
                        />
                        {(textSearch.length || isBlocked) ?
                            <button className={tableStyles["search-button"]} onClick={clearResult}>
                                <i className="fa fa-eraser" />
                            </button>
                            :
                            null
                        }
                    </div>
                </div>
            </div>
            <div className={tableStyles["listing-table"]}>
                <div className={tableStyles["listing-table-scrl"]}>
                    <table cellPadding={0} cellSpacing={0}>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Image</th>
                                <th className="cursor-pointer" onClick={() => handleSorting(SORTING_FIELDS.FIRST_NAME)}>
                                    <span className="me-1">Name</span>
                                    {sorting.type !== SORTING_FIELDS.FIRST_NAME ? <img src={SortIcon} width={15} height={15} /> : null}
                                    {sorting.type === SORTING_FIELDS.FIRST_NAME ? sorting.order === 1 ? <i className="fa fa-angle-up" /> : <i className="fa fa-angle-down" /> : null}
                                </th>
                                <th className="cursor-pointer" onClick={() => handleSorting(SORTING_FIELDS.EMAIL)}>
                                    <span className="me-2">Email</span>
                                    {sorting.type !== SORTING_FIELDS.EMAIL ? <img src={SortIcon} width={15} height={15} /> : null}
                                    {sorting.type === SORTING_FIELDS.EMAIL ? sorting.order === 1 ? <i className="fa fa-angle-up" /> : <i className="fa fa-angle-down" /> : null}
                                </th>
                                <th>Phone No.</th>
                                <th>Account Verified</th>
                                <th>Blocked</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? <tr className={tableStyles.noresult}><td colSpan={8}>{MESSAGES.LOADING}</td></tr> : null}
                            {!loading && userList.map((user, index) => (
                                <tr key={user._id}>
                                    <td>{pagination.offset + index + 1}</td>
                                    <td>
                                        <img src={user.image} alt="user" />
                                    </td>
                                    <td>{user.firstName} {user.lastName}</td>
                                    <td>{user.email}</td>
                                    <td>{user.phoneNumber}</td>
                                    <td className={user.isVerified ? "activebr" : "inactive"}>
                                        <span>{user.isVerified ? "verified" : "not verified"}</span>
                                    </td>
                                    <td>
                                        <Switch
                                            onColor="#e89532"
                                            onChange={() => switchHandler(user._id, !user.isBlocked)}
                                            checked={user.isBlocked}
                                        />
                                    </td>
                                    <td className={tableStyles["action-btns"]}>

                                        <Link to={`/users/${user._id}`}>
                                            <i className="fas fa-eye"></i>
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                            {!loading && userList.length === 0 ? <tr className={tableStyles.noresult}><td colSpan={8}>No user found</td></tr> : null}
                        </tbody>
                    </table>
                </div>
                <Pagination count={count + 1} setPagination={setPagination} />
            </div>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
    getUserList: (query, loader) => dispatch(AdminUserAction.list(query, loader)),
    blockUser: (query, payload, loader) => dispatch(AdminUserAction.update(query, payload, loader))
});

export default connect(null, mapDispatchToProps)(User);
