import React, { useState } from "react";
import { Field, useFormik, FormikProvider } from "formik";
import { connect, useSelector } from "react-redux";
import axios from "axios";
import Autocomplete from "react-google-autocomplete";

import GoogleMap from "../../../../components/Map";
import { CityAreaSchema } from "../../../../schemas";
import {
  AdminBuildingAction,
  AdminCitiesAction,
} from "../../../../store/admin/thunk";
import { TIMEZONE_URL } from "../../../../constants";
import { ENV_CONFIG } from "../../../../utils";

const AddBuilding = ({
  loader,
  create,
  areaId,
  setIsAddBuilding,
  setSelectBuilding,
  setBuildings,
}) => {
  const [buildingLocation, setBuildingLocation] = useState({
    latitude: 23.8859,
    longitude: 45.0792,
    address: "Saudi Arabia",
  });

  const createBuilding = async ({ name }) => {
    const response = await axios.get(
      `${TIMEZONE_URL}/${buildingLocation.latitude},${buildingLocation.longitude}`
    );

    if (response.data && response.data.timezone_id) {
      const payload = {
        name,
        areaId: areaId,
        latitude: buildingLocation.latitude.toString(),
        longitude: buildingLocation.longitude.toString(),
        timezone: response.data.timezone_id,
      };
      const res = await create(payload, loader);
      if (res?.status) {
        setBuildings((pre) => [
          ...pre,
          {
            _id: res?.data?._id,
            name: res?.data?.name,
            latitude: res?.data?.latitude,
            longitude: res?.data?.longitude,
          },
        ]);
        setSelectBuilding(res?.data?._id);
        setIsAddBuilding(false);
      }
    }
  };

  const onDragEnd = async (event) => {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${event.latLng.lat()},${event.latLng.lng()}&key=${
        ENV_CONFIG.GOOGLE_MAP_KEY
      }`
    );

    if (response.ok) {
      const data = await response.json();
      if (data.results.length > 0) {
        setBuildingLocation({
          address: data.results[0].formatted_address,
          latitude: event.latLng.lat(),
          longitude: event.latLng.lng(),
        });
      }
    }
  };

  const buildingFormik = useFormik({
    initialValues: {
      name: "",
      address: "",
      latitude: 23.8859,
      longitude: 45.0792,
    },
    onSubmit: (values) => {
      createBuilding(values);
    },
    validationSchema: CityAreaSchema,
  });

  const handleAddressChange = (newAddress) => {
    setBuildingLocation({
      ...buildingLocation,
      address: newAddress.target.value,
    });
  };

  return (
    <div className="edit-users-sc bg-white">
      <FormikProvider value={buildingFormik}>
        <div className="form-group">
          <label>Name</label>
          <Field name="name" placeholder="Enter name" />
          {buildingFormik.errors.name && buildingFormik.touched.name ? (
            <div className="error">{buildingFormik.errors.name}</div>
          ) : null}
        </div>
        <div className="form-group">
          <label>Building Location</label>
          <Autocomplete
            onPlaceSelected={(location) => {
              let latitude = location.geometry.location.lat(),
                address = location.formatted_address,
                longitude = location.geometry.location.lng();
              setBuildingLocation({ address, latitude: latitude, longitude });
            }}
            className="mb-2"
            defaultValue={buildingLocation.address}
            value={buildingLocation.address}
            onChange={handleAddressChange}
          />
          <span>Note : Drag the pointer to the exact building location</span>
          <GoogleMap
            isMarkerShown
            draggable={true}
            onDragEnd={onDragEnd}
            positionalLat={buildingLocation.latitude}
            positionalLong={buildingLocation.longitude}
            latitudeCenter={buildingLocation.latitude}
            longitudeCenter={buildingLocation.longitude}
          />
        </div>
        <button
          className="mt-2"
          type="button"
          onClick={buildingFormik.submitForm}
        >
          {"Add"}
        </button>
      </FormikProvider>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  create: (payload, loader) =>
    dispatch(AdminBuildingAction.create(payload, loader)),
});

export default connect(null, mapDispatchToProps)(AddBuilding);
