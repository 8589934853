import React, { useEffect, Fragment, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";

import { VendorPromotionAction } from "../../../../store/vendor/thunk";
import Button from "../../../../components/Button";
import style from "./style.module.css";
import tableStyles from "../../../../components/Table/style.module.css";

const PropertyPromotions = (props) => {
    const navigate = useNavigate();

    const { promotions: { promotions } } = useSelector((state) => state.vendor);

    const [filter, setPromotionFilter] = useState(null);

    const addNewPromotion = () => navigate(`/properties/${props.propertyId}/promotions/select`);

    const deletePromotion = (promotionId) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to remove this promotion ?",
            confirmButtonText: "Yes",
            showCancelButton: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                let payload = {
                    id: promotionId,
                };
                props.remove(payload, props.loader, props.propertyId);
            }
        });
    };

    useEffect(() => {
        if (props.propertyId) {
            props.list(props.propertyId, props.loader, filter);

        }
    }, [filter, props.propertyId]);

    return (
        <Fragment>
            <div className="d-flex justify-content-between align-items-start my-3">
                <p className={style.heading}>Promotions</p>
                <div className="d-flex align-items-center">
                    <div className="px-2">
                        <select className="form-select w-100" onChange={(event) => setPromotionFilter(event.target.value)}>
                            <option value={null}>All</option>
                            <option value="ACTIVE">Active</option>
                            <option value="UPCOMING">Upcoming</option>
                            <option value="PAST">Past</option>
                        </select>
                    </div>
                    <div>
                        <Button title="Add" type="button" handleClick={addNewPromotion} />
                    </div>
                </div>
            </div>
            <div className={tableStyles["listing-table"]}>
                <div className={tableStyles["listing-table-scrl"]}>
                    <table cellPadding={0} cellSpacing={0}>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Promotion Name</th>
                                <th>Discount</th>
                                <th>Bookable Dates</th>
                                <th>Stay Dates</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {promotions && promotions.length === 0 ? <tr><td colSpan={6} className={`text-center ${style.noPromotion}`}>No Promotion</td></tr> : null}
                            {promotions.map((promotion, index) => (
                                <tr key={promotion._id}>
                                    <td>{++index}</td>
                                    <td>{promotion.promotionName}</td>
                                    <td>{promotion.discount}</td>
                                    <td>
                                        {moment(promotion.bookableStartDate).format("DD MMM YYYY")} - {" "}
                                        {
                                            promotion.bookableEndDate !== "NOW" ?
                                                moment(promotion.bookableEndDate).format("DD MMM YYYY") :
                                                promotion.bookableEndDate
                                        }
                                    </td>
                                    <td>{moment(promotion.stayStartDate).format("DD MMM YYYY")} - {" "}
                                        {
                                            promotion.stayEndDate !== "NOW" ?
                                                moment(promotion.stayEndDate).format("DD MMM YYYY") :
                                                promotion.stayEndDate
                                        }
                                    </td>
                                    <td>
                                        <button onClick={() => deletePromotion(promotion._id)}>
                                            <i className="fas fa-trash"></i>
                                        </button>
                                        <Link to={`/properties/${props.propertyId}/promotions/${promotion._id}/edit`}>
                                            <i className="far fa-edit"></i>
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </Fragment>
    )
};

const mapDispatchToProps = (dispatch) => ({
    list: (propertyId, loader, status) => dispatch(VendorPromotionAction.list(propertyId, loader, status)),
    remove: (promotionId, loader, propertyId) => dispatch(VendorPromotionAction.remove(promotionId, loader, propertyId)),
})

export default connect(null, mapDispatchToProps)(PropertyPromotions);