import ADMIN_ACTIONS from "../actions";

const initialState = {
    categories: [],
    count: 0,
    subCategories: [],
    subCategoryCount: 0,
    category: {},
    activeCategory: "",
    categoryLoader: false,
    subCategoryLoader: false,
    subCategory: {},
};

const adminCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADMIN_ACTIONS.LOADING_SUB_CATEGORIES_LIST:
            return {
                ...state,
                subCategoryLoader: action.status,
            };
        case ADMIN_ACTIONS.LOADING_CATEGORIES_LIST:
            return {
                ...state,
                categoryLoader: action.status,
            };
        case ADMIN_ACTIONS.CATEGORIES_LIST_SUCCESS:
            return {
                ...state,
                categories: action.categories,
                count: action.count
            };

        case ADMIN_ACTIONS.SUB_CATEGORIES_LIST_SUCCESS:
            return {
                ...state,
                subCategories: action.subCategories,
                subCategoryCount: action.count,
                activeCategory: action.activeCategory
            };

        case ADMIN_ACTIONS.SET_SPECIFIC_CATEGORY: 
            return {
                ...state,
                category: action.category,
            };

        case ADMIN_ACTIONS.SET_SPECIFIC_SUB_CATEGORY:
            return {
                ...state,
                subCategory: action.category,
            };

        default:
            return state;
    }
};

export default adminCategoryReducer;