import React from "react";
import { Navigate } from "react-router-dom";

import { storage } from "../utils";
import { APP_ROUTES } from "../routes";


const WithAuth = (props) => {
    if (storage.getToken()) {

        if (props.role.includes(props.activeRole)) {
            return props.children;
        }

        else return <Navigate to={APP_ROUTES.DASHBOARD} />;
    }
    else return <Navigate to={APP_ROUTES.LOGIN} />
}

export default WithAuth;
