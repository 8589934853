import React from "react";
import { Formik, Form, Field } from "formik";

import { UserSchema } from "../../../../schemas";
 
const Edituser = () => {

    return (
        <div className="edit-users-sc">
            <Formik
                initialValues={{
                    name: "",
                    email: "",
                }}
                validationSchema={UserSchema}
                onSubmit={() => {
                    // same shape as initial values
                }}
            >
                {({ errors, touched }) => (
                    <Form>
                        <div className="form-group">
                            <Field name="name" placeholder="Enter name" />
                            {errors.name && touched.name ? (
                                <div className="error">{errors.name}</div>
                            ) : null}
                        </div>

                        <div className="form-group">
                            <Field name="email" type="email" placeholder="Enter email" />
                            {errors.email && touched.email ? (
                                <div className="error">{errors.email}</div>
                            ) : null}
                        </div>
                        <div className="form-group">
                            <Field
                                name="phone"
                                type="number"
                                placeholder="Enter phone number"
                            />
                            {errors.phone && touched.phone ? (
                                <div className="error">{errors.phone}</div>
                            ) : null}
                        </div>

                        <div className="form-group">
                            <div className="verified">
                                <Field name="verified" type="radio" value="Verified" /> Verified
                            </div>
                            <div className="verified">
                                <Field name="verified" type="radio" value="Unverified" />
                                Unverified
                            </div>
                            {errors.radio && touched.radio ? (
                                <div className="error">{errors.radio}</div>
                            ) : null}
                        </div>
                        <button type="submit">Add</button>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default Edituser;
