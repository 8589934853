import {Axios} from "../../../lib";
import { Toaster } from "../../../components";
import { API_ENDPOINTS, MESSAGES, TOAST_TYPE } from "../../../constants";
import { APP_ROUTES } from "../../../routes";
import ADMIN_ACTIONS from "../actions";

const list = (payload, loader) => async (dispatch) => {
    try {
        const { data } = await Axios.get(API_ENDPOINTS.LIST_AREAS_BUILDINGS, payload, loader);
        dispatch({ type: ADMIN_ACTIONS.BUILDING_LIST_SUCCESS, buildings: data.location, count: data.count });
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.SOMETHING_WENT_WRONG);
    }
};

const update = (payload, loader, navigate, params) => async (dispatch) => {
    try {
        const response = await Axios.patch(API_ENDPOINTS.CREATE_UPDATE_BUILDING, payload, loader);

        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, MESSAGES.BUILDING.UPDATE_SUCCESS);

            if(payload.name || payload.image) {
                navigate(APP_ROUTES.BUILDINGS_LIST);
            }
            else {
                dispatch(list(params, loader));
            }
        }
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.BUILDING.UPDATE_ERROR);
    }
};

const create = (payload, loader, navigate) => async () => {
    try {
        const response = await Axios.post(API_ENDPOINTS.CREATE_UPDATE_BUILDING, payload, loader);
        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, MESSAGES.BUILDING.CREATE_SUCCESS);

            if(payload.name || payload.image) {
                navigate ? navigate() : "";
            }
            return response
        }
        else {
            Toaster(TOAST_TYPE.ERROR, response.error || MESSAGES.BUILDING.CREATE_ERROR );
        }
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, err?.error || MESSAGES.BUILDING.CREATE_ERROR);
    }
};

export {
    list,
    update,
    create
};