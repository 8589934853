import React, { useEffect, useState } from "react";
import { Field, useFormik, FormikProvider } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";
import { connect, useDispatch, useSelector } from "react-redux";
import MdEditor from "react-markdown-editor-lite";
import MarkdownIt from "markdown-it";

import MultiSelect from "../../../../components/MultiSelect";
import { AddCarSchema } from "../../../../schemas";
import { CommonAction } from "../../../../store/common/thunk";
import { VendorCarAction } from "../../../../store/vendor/thunk";
import { Button } from "../../../../components";
import { CATEGORY_TYPE, EDITOR_PLUGINS, FILE_UPLOAD_TYPE, IMAGE_FILE_TYPES, MAXIMUM_FILE_SIZE_LIMIT, MESSAGES, MINIMUM_FILE_UPLOAD_LIMIT, TOAST_TYPE } from "../../../../constants";
import { Toaster } from "../../../../components/Toaster";
import DropImage from "../../../../components/DropImage";
import COMMON_ACTIONS from "../../../../store/common/actions";
import { processImage, uploadImage } from "../../../../utils/imageHelper";
// import styles from './style.module.css';
import addstyle from "../../../Common/styles/common.module.css";

const AddCar = ({ loader, categoriesList, subCategoriesList, specificCar, updateCar, createCar }) => {
    const { slug } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mdParser = new MarkdownIt({ html: true, linkify: true, typographer: true });

    const { categories, subCategories, images } = useSelector((state) => state.common);
    const { cars: { car } } = useSelector((state) => state.vendor);

    const [location, setLocation] = useState({});

    useEffect(() => {
        if (slug) {
            const params = { slug };
            specificCar(params, loader);
        }
    }, [slug]);

    useEffect(() => {
        if (Object.keys(car).length) {

            subCategoriesList(car.category_id._id, loader);

            carFormik.setValues({
                brand: car.brand,
                name: car.name,
                model: car.model,
                price: car.price,
                categoryId: car.category_id._id,
                color: car.color,
                mileage: car.features.mileage,
                seats: car.features.seats,
                engine: car.features.engine,
                bhp: car.features.bhp,
                bootSpace: car.features.boot_space,
                description: car.description,
                subCategory: car.subCategory_ids.map(item => ({ value: item._id, label: item.name })),
                fuel: car.fuel,
                transmission: car.features.transmission,
            });

            dispatch({ type: COMMON_ACTIONS.SET_IMAGES, key: "selectedImage", value: car.images });
            dispatch({ type: COMMON_ACTIONS.SET_IMAGES, key: "images", value: car.images });
        }
    }, [car]);

    const handleValidate = async () => {
        const errors = {};
        let fileImages = [];
        for (let i = 0; i < images.length; i++) {
            if (images[i] instanceof File) {
                const result = processImage(images[i]);
                fileImages.push(result);
            }
        }

        const processedImages = await Promise.all(fileImages);
        const invalidAspectRatio = processedImages.filter(item => item.is4by3 === false);
        const invalidFileTypes = processedImages.filter(item => !IMAGE_FILE_TYPES.includes(item.file.type));
        const invalidOldFileSize = images.filter(item => item.size > MAXIMUM_FILE_SIZE_LIMIT);
        const invalidNewFileSize = processedImages.filter(item => item.file.size > MAXIMUM_FILE_SIZE_LIMIT);

        if (images.length < MINIMUM_FILE_UPLOAD_LIMIT) {
            errors.images = MESSAGES.MINIMUM_UPLOAD_FILE_LIMIT;
        }
        else if (invalidAspectRatio.length) {
            errors.images = MESSAGES.FILE_UPLOAD_ASPECT_RATIO;
        }
        else if (invalidFileTypes.length) {
            errors.images = MESSAGES.VALID_IMAGE_FILE_TYPE;
        }
        else if (invalidNewFileSize.length || invalidOldFileSize.length) {
            errors.images = MESSAGES.UPLOAD_FILE_SIZE_LIMIT;
        }

        return errors;
    };

    const getLocation = (locData) => {
        const location = locData.formatted_address,
            latitude = locData.geometry.location.lat(),
            longitude = locData.geometry.location.lng();

        setLocation({ location, latitude, longitude });
    };

    const carFormik = useFormik({
        validate: handleValidate,
        enableReinitialize: true,
        initialValues: {
            name: "",
            brand: "",
            model: "",
            price: "",
            color: "",
            categoryId: "",
            subCategory: [],
            fuel: "",
            description: "",
            images: "",
            discount: "",
            weekly: "",
            bhp: "",
            bootSpace: "",
            engine: "",
            mileage: "",
            seats: "",
            transmission: "",
            monthly: "",
        },
        onSubmit: async (values) => {
            let uploadedImages = await Promise.all(
                images?.map((val) => {
                    if (typeof val !== "string") {
                        const formData = new FormData();
                        formData.append("file", val);
                        formData.append("type", FILE_UPLOAD_TYPE.CAR);
                        return uploadImage(formData);
                    }
                })
            );

            let fileError = uploadedImages.find(item => item && item.status === false && item.error === "File too large");

            if (fileError) {
                Toaster(TOAST_TYPE.ERROR, MESSAGES.UPLOAD_FILE_SIZE_LIMIT);
                return;
            }

            uploadedImages = uploadedImages.filter(item => item !== undefined).map((val) => val.data.fileUrl);
            uploadedImages = [...uploadedImages, ...images.filter(item => typeof (item) === "string")];

            const params = {
                name: values.name,
                brand: values.brand,
                model: values.model,
                price: values.price,
                description: values.description,
                images: uploadedImages,
                color: values.color,
                features: {
                    mileage: values.mileage,
                    transmission: values.transmission,
                    seats: values.seats,
                    engine: values.engine,
                    bhp: values.bhp,
                    boot_space: values.bootSpace,
                },
                category_id: values.categoryId,
                subCategory_ids: values.subCategory.map(item => item.value),
                fuel: values.fuel,
                latitude: location.latitude,
                longitude: location.longitude,
                location: location.location,
                currency: "AED",
            };

            if (!slug) createCar(params, loader, navigate);
            else {
                delete params.currency;
                updateCar({ ...params, car_id: car._id }, loader, navigate);
            }
        },
        validationSchema: AddCarSchema
    });

    useEffect(() => {
        const query = { type: CATEGORY_TYPE.CAR };

        categoriesList(query, loader);

        if (!slug) {
            dispatch({ type: COMMON_ACTIONS.SET_IMAGES, key: "selectedImage", value: [] });
            dispatch({ type: COMMON_ACTIONS.SET_IMAGES, key: "images", value: [] });
            carFormik.resetForm();
        }
    }, []);

    useEffect(() => {
        if (slug && images.length) {
            handleValidate();
        }
    }, [slug, images])

    return (
        <div className={addstyle["add-property-sc"]}>
            <FormikProvider value={carFormik}>
                <div className="row">
                    <div className="col-md-12">
                        <div className={` ${addstyle["form-group"]} ${addstyle["add-image"]} ${addstyle["new-add-gallery"]}`}>
                            <DropImage formik={carFormik} />
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="row">
                            <div className={`col-md-6 ${addstyle["form-group"]}`}>
                                <label>Car Title</label>
                                <Field name="name" placeholder="Enter name" />
                                {carFormik.errors.name && carFormik.touched.name ? (
                                    <div className="error">{carFormik.errors.name}</div>
                                ) : null}
                            </div>

                            <div className={`col-md-6 ${addstyle["form-group"]}`}>
                                <label>Enter Brand</label>
                                <Field name="brand" placeholder="Enter brand" />
                                {carFormik.errors.brand && carFormik.touched.brand ? (
                                    <div className="error">{carFormik.errors.brand}</div>
                                ) : null}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="row">
                            <div className={`col-md-6 ${addstyle["form-group"]}`}>
                                <label>Enter model</label>
                                <Field
                                    min={1}
                                    name="model"
                                    placeholder="Enter model"
                                    type="number"
                                />
                                {carFormik.errors.model && carFormik.touched.model ? (
                                    <div className="error">{carFormik.errors.model}</div>
                                ) : null}
                            </div>

                            <div className={`col-md-6 ${addstyle["form-group"]}`}>
                                <label>Enter Price</label>
                                <Field name="price" placeholder="Enter price" />
                                {carFormik.errors.price && carFormik.touched.price ? (
                                    <div className="error">{carFormik.errors.price}</div>
                                ) : null}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="row">
                            <div className={`col-md-6 ${addstyle["form-group"]}`}>
                                <label>Select category</label>

                                <Field
                                    onChange={(e) => {
                                        carFormik.setFieldValue("categoryId", e.target.value);

                                        subCategoriesList(e.target.value, loader);
                                        carFormik.setFieldValue("subCategory", []);
                                    }}
                                    as="select"
                                    name="categoryId"
                                >
                                    <option value="">click to select</option>
                                    {categories.map((category) => (
                                        <option key={category._id} value={category._id}>
                                            {category.name}
                                        </option>
                                    ))}
                                </Field>

                                {carFormik.errors.categoryId && carFormik.touched.categoryId ? (
                                    <div className="error">{carFormik.errors.categoryId}</div>
                                ) : null}
                            </div>

                            <div className={`col-md-6 ${addstyle["form-group"]}`}>
                                <label>Select sub category</label>

                                <Field
                                    name="subCategory"
                                    id="multiSelectCustom"
                                    placeholder="Select sub Category"
                                    isMulti={true}
                                    component={MultiSelect}
                                    defaultOption={carFormik.values.subCategory}
                                    options={subCategories.map((val) => ({ label: val.name, value: val._id }))}
                                />

                                {carFormik.errors.subCategory && carFormik.touched.subCategory ? (
                                    <div className="error">{carFormik.errors.subCategory}</div>
                                ) : null}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="row">
                            <div className={`col-md-6 ${addstyle["form-group"]}`}>
                                <label>Enter location</label>
                                <Autocomplete
                                    onPlaceSelected={(location) => {
                                        getLocation(location);
                                    }}
                                    defaultValue={car?.location}
                                />
                            </div>

                            <div className={`col-md-6 ${addstyle["form-group"]}`}>
                                <label>Enter color</label>
                                <Field name="color" placeholder="Enter color" />
                                {carFormik.errors.color && carFormik.touched.color ? (
                                    <div className="error">{carFormik.errors.color}</div>
                                ) : null}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="row">

                            <div className={`col-md-6 ${addstyle["form-group"]}`}>
                                <label>Select fuel type</label>
                                <Field as="select" name="fuel">
                                    <option value="">Select</option>
                                    <option value="DIESEL">Diesel</option>
                                    <option value="PETROL">Petrol</option>
                                </Field>
                                {carFormik.errors.fuel && carFormik.touched.fuel ? (
                                    <div className="error">{carFormik.errors.fuel}</div>
                                ) : null}
                            </div>
                        </div>
                    </div>

                    <h3>Features</h3>

                    <div className="col-md-12">
                        <div className="row">
                            <div className={`col-md-6 ${addstyle["form-group"]}`}>
                                <label>Enter mileage</label>
                                <Field
                                    name="mileage"
                                    placeholder="Enter mileage"
                                    type="number"
                                />
                                {carFormik.touched.mileage && carFormik.errors.mileage ? (
                                    <div className="error">{carFormik.errors.mileage}</div>
                                ) : null}
                            </div>

                            <div className={`col-md-6 ${addstyle["form-group"]}`}>
                                <label>Select transmission</label>
                                <Field as="select" name="transmission">
                                    <option value="">Select Transmission</option>
                                    <option value="AUTOMATIC">Automatic</option>
                                    <option value="MANUAL">Manual</option>
                                    <option value="CVT">CVT</option>
                                </Field>
                                {carFormik.errors.transmission && carFormik.touched.transmission ? (
                                    <div className="error">{carFormik.errors.transmission}</div>
                                ) : null}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="row">
                            <div className={`col-md-6 ${addstyle["form-group"]}`}>
                                <label>Enter seats</label>
                                <Field
                                    name={"seats"}
                                    placeholder="Enter seats"
                                    type="number"
                                    min={1}
                                />
                                {carFormik.errors.seats && carFormik.touched.seats ? (
                                    <div className="error">{carFormik.errors.seats}</div>
                                ) : null}
                            </div>

                            <div className="col-md-6 form-group">
                                <label>Enter engine cc</label>
                                <Field
                                    name="engine"
                                    placeholder="Enter engine"
                                    type="number"
                                    min={1}
                                />
                                {carFormik.errors.engine && carFormik.touched.engine ? (
                                    <div className="error">{carFormik.errors.engine}</div>
                                ) : null}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="row">
                            <div className={`col-md-6 ${addstyle["form-group"]}`}>
                                <label>Enter bhp</label>
                                <Field
                                    name="bhp"
                                    placeholder="Enter bhp"
                                    type="number"
                                    min={1}
                                />
                                {carFormik.errors.bhp && carFormik.touched.bhp ? (
                                    <div className="error">{carFormik.errors.bhp}</div>
                                ) : null}
                            </div>

                            <div className={`col-md-6 ${addstyle["form-group"]}`}>
                                <label>Enter boot space</label>
                                <Field
                                    name="bootSpace"
                                    placeholder="Enter boot space"
                                    type="number"
                                    min={1}
                                />
                                {carFormik.errors.bootSpace && carFormik.touched.bootSpace ? (
                                    <div className="error">{carFormik.errors.bootSpace}</div>
                                ) : null}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="form-group description">
                            <label>Enter Description</label>
                            <MdEditor
                                plugins={EDITOR_PLUGINS}
                                style={{ height: "500px" }}
                                renderHTML={text => mdParser.render(text)}
                                onChange={({ html }) => {
                                    carFormik.setFieldValue("description", html);
                                }}
                                view={{ menu: true, md: true, html: false }}
                            />
                            {carFormik.errors.description && carFormik.touched.description ? (
                                <div className="error">{carFormik.errors.description}</div>
                            ) : null}
                        </div>
                    </div>
                </div>

                <Button type="button" title={slug ? "Edit" : "Add"} handleClick={carFormik.submitForm} />
            </FormikProvider>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    categoriesList: (query, loader) => dispatch(CommonAction.categoriesList(query, loader)),
    subCategoriesList: (categoryId, loader) => dispatch(CommonAction.subCategoriesList(categoryId, loader)),
    specificCar: (query, loader) => dispatch(VendorCarAction.specific(query, loader)),
    updateCar: (payload, loader, navigate) => dispatch(VendorCarAction.update(payload, loader, navigate)),
    createCar: (payload, loader, navigate) => dispatch(VendorCarAction.create(payload, loader, navigate)),
});

export default connect(null, mapDispatchToProps)(AddCar);
