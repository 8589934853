import * as Yup from "yup";

import { MESSAGES } from "../constants";

const editBasicCarSchema = Yup.object().shape({
    name: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
    brand: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
    model: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
    price: Yup.number().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
});

const editCarFeaturesSchema = Yup.object().shape({
    mileage: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
    transmission: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
    seats: Yup.number().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
    engine: Yup.number().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
    bhp: Yup.number().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
    bootSpace: Yup.number().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
});

const editCarLocationSchema = Yup.object().shape({
    location: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
    latitude: Yup.number().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
    longitude: Yup.number().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
});

export {
    editBasicCarSchema,
    editCarFeaturesSchema,
    editCarLocationSchema,
};