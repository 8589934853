import {Axios} from "../../../lib";
import { Toaster } from "../../../components/Toaster";
import { CommonAction } from "../../common/thunk";
import { API_ENDPOINTS, MESSAGES, TOAST_TYPE } from "../../../constants";

const approve = (payload, loader, pagination) => async (dispatch) => {
    try {
        const response = await Axios.patch(API_ENDPOINTS.APPROVE_CAR, payload, loader);

        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, `Car ${payload.isApproved ? "approved" : "unapproved"} successfully`);

            dispatch(CommonAction.carsList(pagination, loader));
        }
        else {
            Toaster(TOAST_TYPE.ERROR, response.error);
        }
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.CMS.UPDATE_ERROR);
    }
};

export {
    approve,
}; 