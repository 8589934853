import React from "react";
import { Link, useLocation } from "react-router-dom";

import { APP_ROUTES } from "../../../../routes";

const ViewSubAdmin = () => {
    const { state: { val } } = useLocation();

    return (
        <div className="edit-users-sc">
            <div className="form-group">First Name : {val.firstName}</div>
            <div className="form-group">Last Name : {val.lastName}</div>
            <div className="form-group">Email : {val.email}</div>

            <Link to={APP_ROUTES.SUB_ADMIN_LIST}>
                <button>Done</button>
            </Link>
        </div>
    );
};

export default ViewSubAdmin;
