import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Country, State, City } from 'country-state-city';
import InputMask from 'react-input-mask';
import { connect, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './style.module.css';
import { AddPayoutCardSchema } from '../../../../../schemas';
import { VendorPayoutAction } from '../../../../../store/vendor/thunk';
import addstyle from "../../../../Common/styles/common.module.css";
import { Button } from '../../../../../components';

const AddCard = (props) => {
    const navigate = useNavigate();
    const params = useParams();

    const { payouts: { specificPayout } } = useSelector((state) => state.vendor);

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    useEffect(() => {
        const countries = Country.getAllCountries();

        setCountries(countries);
    }, []);

    useEffect(() => {
        if (params.id) {
            props.specificPayoutCard(params.id);
        }
    }, [params.id]);

    useEffect(() => {
        if (Object.keys(specificPayout).length) {
            const payload = {
                accountNumber: specificPayout.accountNumber,
                billingCountry: specificPayout.billingCountry,
                bankName:  specificPayout.bankName,
                bicCode: specificPayout.bicCode,
                addressLine1: specificPayout.address.addressLine1,
                addressLine2: specificPayout.address.addressLine2,
                city: specificPayout.address.city,
                state: specificPayout.address.state,
                country: specificPayout.address.country,
            }

            addCardFormik.setValues(payload);
            const states = State.getStatesOfCountry(payload.country);
            setStates(states);

            const cities = City.getCitiesOfState(payload.country, payload.state);
            setCities(cities);
        }
    }, [specificPayout]);

    const addCardFormik = useFormik({
        initialValues: {
            accountNumber: '',
            billingCountry: '',
            bankName: '',
            bicCode: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            country: '',
        },
        validationSchema: AddPayoutCardSchema,
        enableReinitialize: params.id ? true : false,
        onSubmit: (values) => {
            const payload = {
                accountNumber: values.accountNumber,
                billingCountry: values.billingCountry,
                bankName: values.bankName,
                bicCode: values.bicCode,

                address: {
                    addressLine1: values.addressLine1,
                    addressLine2: values.addressLine2,
                    city: values.city,
                    state: values.state,
                    country: values.country,
                }
            };

            if (params.id) {
                payload['bankAccountId'] = params.id;
                props.updatePayoutCard(payload, navigate, props.loader);
            }
            else props.savePayoutCard(payload, navigate, props.loader);
        },
    });
    return (
        <div className={addstyle["add-property-sc"]}>
            <div className='container'>
                <h3 className={styles.heading}>Add Bank Details</h3>
                <form className='row'>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label className={`w-100 ${styles.label}`}>Account Number</label>
                            <InputMask maskChar=" " mask="9999 9999 9999 9999" value={addCardFormik.values.accountNumber} onChange={addCardFormik.handleChange}>
                                {(inputProps) => <input name="accountNumber" {...inputProps} type="tel" />}
                            </InputMask>

                            {addCardFormik.touched.accountNumber && addCardFormik.errors.accountNumber ? <p className="error">{addCardFormik.errors.accountNumber}</p> : null}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className={`w-100 ${styles.label}`}>Bank name</label>
                            <input
                                type="text"
                                name="bankName"
                                onChange={addCardFormik.handleChange}
                                onBlur={addCardFormik.handleBlur}
                                value={addCardFormik.values.bankName}
                            />
                            {addCardFormik.touched.bankName && addCardFormik.errors.bankName ? <p className="error">{addCardFormik.errors.bankName}</p> : null}
                        </div>
                    </div>



                    <div className="col-md-6">
                        <div className="form-group">
                            <label className={`w-100 ${styles.label}`}>Address Line 1</label>
                            <input
                                type="text"
                                name="addressLine1"
                                onChange={addCardFormik.handleChange}
                                onBlur={addCardFormik.handleBlur}
                                value={addCardFormik.values.addressLine1}
                            />
                            {addCardFormik.touched.addressLine1 && addCardFormik.errors.addressLine1 ? <p className="error">{addCardFormik.errors.addressLine1}</p> : null}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className={`w-100 ${styles.label}`}>Address Line 2</label>
                            <input
                                type="text"
                                name="addressLine2"
                                onChange={addCardFormik.handleChange}
                                onBlur={addCardFormik.handleBlur}
                                value={addCardFormik.values.addressLine2}
                            />
                            {addCardFormik.touched.addressLine2 && addCardFormik.errors.addressLine2 ? <p className="error">{addCardFormik.errors.addressLine2}</p> : null}
                        </div>
                    </div>


                    <div className="col-md-6">
                        <div className="form-group">
                            <label className={`w-100 ${styles.label}`}>Bank Identifier</label>
                            <input
                                type="text"
                                name="bicCode"
                                onChange={addCardFormik.handleChange}
                                onBlur={addCardFormik.handleBlur}
                                value={addCardFormik.values.bicCode}
                            />
                            {addCardFormik.touched.bicCode && addCardFormik.errors.bicCode ? <p className="error">{addCardFormik.errors.bicCode}</p> : null}
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label className={`w-100 ${styles.label}`}>Billing Country</label>
                            <select
                                name="billingCountry"
                                onChange={addCardFormik.handleChange}
                                value={addCardFormik.values.billingCountry}
                            >
                                <option>Select Country</option>
                                {countries.map((item, index) => <option key={index} value={item.isoCode}>{item.name}</option>)}
                            </select>
                            {addCardFormik.touched.billingCountry && addCardFormik.errors.billingCountry ? <p className="error">{addCardFormik.errors.billingCountry}</p> : null}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className={`w-100 ${styles.label}`}>Country</label>
                            <select
                                name="country"
                                value={addCardFormik.values.country}
                                onChange={(event) => {
                                    const states = State.getStatesOfCountry(event.target.value);

                                    setStates(states);
                                    addCardFormik.handleChange(event);
                                }}
                            >
                                <option>Select Country</option>
                                {countries.map((item, index) => <option key={index} value={item.isoCode}>{item.name}</option>)}
                            </select>
                            {addCardFormik.touched.country && addCardFormik.errors.country ? <p className="error">{addCardFormik.errors.country}</p> : null}
                        </div>
                    </div>


                    {
                        addCardFormik.values.country.length > 0 &&
                        <>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className={`w-100 ${styles.label}`}>State</label>
                                    <select
                                        name="state"
                                        value={addCardFormik.values.state}
                                        onChange={(event) => {
                                            const cities = City.getCitiesOfState(addCardFormik.values.country, event.target.value);
                                            setCities(cities);
                                            addCardFormik.handleChange(event);
                                        }}
                                    >
                                        <option>Select State</option>
                                        {states.map((item, index) => <option key={index} value={item.isoCode}>{item.name}</option>)}
                                    </select>
                                    {addCardFormik.touched.state && addCardFormik.errors.state ? <p className="error">{addCardFormik.errors.state}</p> : null}
                                </div>
                            </div>

                            {
                                addCardFormik.values.state.length > 0 &&
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className={`w-100 ${styles.label}`}>City</label>
                                        <select name='city' value={addCardFormik.values.city} onChange={addCardFormik.handleChange}>
                                            <option>Select City</option>
                                            {cities.map((item, index) => <option key={index} value={item.isoCode}>{item.name}</option>)}
                                        </select>
                                        {addCardFormik.touched.city && addCardFormik.errors.city ? <p className="error">{addCardFormik.errors.city}</p> : null}
                                    </div>
                                </div>
                            }
                        </>
                    }

                    <div className='d-flex justify-content-end'>
                        <Button type="button" title={'Submit'} handleClick={addCardFormik.submitForm} />
                    </div>
                </form>
            </div>

        </div>



    );
};

const mapDispatchToProps = (dispatch) => ({
    savePayoutCard: (payload, navigate, loader) => dispatch(VendorPayoutAction.savePayoutCard(payload, navigate, loader)),
    updatePayoutCard: (payload, navigate, loader) => dispatch(VendorPayoutAction.updatePayoutCard(payload, navigate, loader)),
    specificPayoutCard: (id, loader) => dispatch(VendorPayoutAction.specificPayoutCard(id, loader)),
});

export default connect(null, mapDispatchToProps)(AddCard);