import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useLocation } from "react-router-dom";

import styles from "./style.module.css";

const Pagination = ({ count, setPagination, pageSize = 10 }) => {
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState(0);
    const pageCount = Math.ceil(count / pageSize);

    useEffect(() => {
        // Retrieve saved page number from localStorage on mount
        const savedPage = parseInt(window.localStorage.getItem("PageNo"), 10);
        const savedPath = window.localStorage.getItem("pageName");

        if (savedPath === location.pathname && !isNaN(savedPage)) {
            setCurrentPage(savedPage);
            setPagination((prev) => ({ ...prev, offset: savedPage * prev.limit }));
        } else {
            setCurrentPage(0); // Default to the first page if no saved page
        }
    }, [location.pathname, setPagination, pageCount]);

    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
        window.localStorage.setItem("PageNo", selected);
        window.localStorage.setItem("pageName", location.pathname);

        setPagination((prev) => ({
            ...prev,
            offset: selected * prev.limit,
        }));
    };

    return (
        count && count > pageSize ? (
            <div className="d-flex mt-2">
                <div className={styles.paginationContainer}>
                    <ReactPaginate
                        previousLabel="<"
                        nextLabel=">"
                        breakLabel="..."
                        pageCount={pageCount} // Dynamically set pageCount
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        forcePage={currentPage} // Set current page state
                        containerClassName={styles.pagination}
                        previousLinkClassName={`${styles["pagination-button"]} text-decoration-none`}
                        nextLinkClassName={`${styles["pagination-button"]} text-decoration-none`}
                        activeClassName={styles.active}
                    />
                </div>
            </div>
        ) : null
    );
};

export default Pagination;
