import ADMIN_ACTIONS from "../actions";

const initialState = {
    vendorList: [],
    count: 0,
    createVendorSuccess: false,
    vendor: {},
    loading: false,
};

const adminVendorReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADMIN_ACTIONS.VENDOR_LIST_SUCCESS:
            return {
                ...state,
                vendorList: action.vendors,
                count: action.count,
                createVendorSuccess: false,
            };

        case ADMIN_ACTIONS.CREATE_VENDOR_SUCCESS:
            return {
                ...state,
                createVendorSuccess: action.status,
            };

        case ADMIN_ACTIONS.SPECIFIC_VENDOR_SUCCESS:
            return {
                ...state,
                vendor: action.vendor,
            };

        case ADMIN_ACTIONS.REQUEST_VENDOR_LIST:
            return {
                ...state,
                loading: action.status
            };

        default:
            return state;
    }
};

export default adminVendorReducer;