import {Axios} from "../../../lib";
import { Toaster } from "../../../components/Toaster";
import { API_ENDPOINTS, MESSAGES, TOAST_TYPE } from "../../../constants";
import ADMIN_ACTIONS from "../actions";

const list = (loader) => async (dispatch) => {
    try {
        dispatch({ type: ADMIN_ACTIONS.REQUEST_PROMOTIONS_LIST, status: true });
        const response = await Axios.get(API_ENDPOINTS.PROMOTION_CRUD, {}, loader);

        dispatch({ type: ADMIN_ACTIONS.SET_PROMOTIONS, promotions: response.data });
        dispatch({ type: ADMIN_ACTIONS.REQUEST_PROMOTIONS_LIST, status: false });
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.SOMETHING_WENT_WRONG);
    }
};

const create = (payload, loader) => async (dispatch) => {
    try {
        const response = await Axios.post(API_ENDPOINTS.PROMOTION_CRUD, payload, loader);

        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, MESSAGES.PROMOTION.CREATE_SUCCESS);
            dispatch({ type: ADMIN_ACTIONS.CREATE_PROMOTIONS_SUCCESS, status: true });
        }
    }
    catch ({ error }) {
        if (error) {
            Toaster(TOAST_TYPE.ERROR, error);
        }
    }
}

const update = (payload, loader, navigate) => async (dispatch) => {
    try {
        const response = await Axios.put(API_ENDPOINTS.PROMOTION_CRUD, payload, loader);

        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, MESSAGES.PROMOTION.UPDATE_SUCCESS);
            navigate(-1);
            dispatch({ type: ADMIN_ACTIONS.CREATE_PROMOTIONS_SUCCESS, status: true });
        }
    }
    catch ({ error }) {
        if (error) {
            Toaster(TOAST_TYPE.ERROR, error);
        }
    }
}

const remove = (promotionId, loader) => async (dispatch) => {
    try {
        const params = { promotionId };
        const response = await Axios.delete(API_ENDPOINTS.PROMOTION_CRUD, { params: params }, loader);

        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS,  MESSAGES.PROMOTION.REMOVE_SUCCESS);
            dispatch(list(loader));
        }
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.PROMOTION.REMOVE_ERROR);
    }
};

const specific = (promotionId, loader, propertyId) => async (dispatch) => {
    try {
        let params = { promotionId };

        if (propertyId) {
            params.propertyId = propertyId;
        }

        const response = await Axios.get(API_ENDPOINTS.SPECIFIC_PROMOTION, params, loader);
        dispatch({ type: ADMIN_ACTIONS.SET_SPECIFIC_PROMOTION, promotion: response.data });
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.SOMETHING_WENT_WRONG);
    }
};

export {
    list,
    create,
    remove,
    specific,
    update
};