import React from "react";
import { Link, useLocation } from "react-router-dom";
import { APP_ROUTES } from "../../routes";

import commonStyles from "../../pages/Common/styles/common.module.css";

import homen from "../../assets/images/homen.svg";
import carn from "../../assets/images/carn.svg";
import tourn from "../../assets/images/tourn.svg";

const PropertyCarTour = () => {
  const location = useLocation();

  return (
    <div className={commonStyles.catTab}>
      <ul>
        <li
          className={
            location?.pathname === APP_ROUTES.PROPERTIES_LIST
              ? commonStyles.active
              : ""
          }
        >
          <Link to={APP_ROUTES.PROPERTIES_LIST}>
            <span>
              <img src={homen} alt="" />
              Properties
            </span>
          </Link>
        </li>
        <li
          className={
            location?.pathname === APP_ROUTES.CARS_LIST
              ? commonStyles.active
              : ""
          }
        >
          <Link to={APP_ROUTES.CARS_LIST}>
            <span>
              <img src={carn} alt="" />
              Cars
            </span>
          </Link>
        </li>
        <li
          className={
            location?.pathname === APP_ROUTES.TOURS_LIST
              ? commonStyles.active
              : ""
          }
        >
          <Link to={APP_ROUTES.TOURS_LIST}>
            <span>
              <img src={tourn} alt="" />
              Tours
            </span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default PropertyCarTour;
