import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "./index.css";
import "react-markdown-editor-lite/lib/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "react-datepicker/dist/react-datepicker.css";
import "react-calendar/dist/Calendar.css";
import "react-toastify/dist/ReactToastify.css";

import App from "./App";
import store from "./store";

ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
            <ToastContainer />
            <App />
        </Provider>
    </BrowserRouter>,
    document.getElementById("root")
);
