import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import { APP_ROUTES } from "../../../routes";
import { AdminCMSAction } from "../../../store/admin/thunk";
import styles from "./style.module.css";

function About({ loader, listCMS }) {
    const { cms: { cms } } = useSelector((state) => state.admin);
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const typeParam = queryParams.get("type");

        const payload = { type: typeParam };

        listCMS(payload, loader);
    }, [location]);

    return (
        <div className={styles.about}>
            <h2>
                About
                <span>
                    <Link to={{ pathname: APP_ROUTES.EDIT_CMS, search: "?type=ABOUT_US" }}>
                        <i className="far fa-edit"></i> About us
                    </Link>
                </span>
            </h2>
            <p>{cms.description}</p>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
    listCMS: (payload, loader) => dispatch(AdminCMSAction.list(payload, loader)),
});

export default connect(null, mapDispatchToProps)(About);
