import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import Switch from "react-switch";
import DatePicker from "react-datepicker";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";

import { BookingRequest, Pagination } from "../../../../components";
import { VendorBookingRequestAction } from "../../../../store/vendor/thunk";
import { BOOKING_REQUESTS_TYPE } from "../../../../constants/promotions";
import styles from "../style.module.css";
import { CommonAction } from "../../../../store/common/thunk";
import { MESSAGES, USER_TYPE } from "../../../../constants";
import { NoBookingIcon, PhotoIcon } from "../../../../assets/images";
import commonStyles from "../../../Common/styles/common.module.css";

const CarBookingRequests = ({ loader, getCarBookings, acceptPayment, getCars }) => {
    const navigate = useNavigate();

    const { bookingRequests: { carBookings, carBookingCount, requestLoader } } = useSelector((state) => state.vendor);
    const { carsList, user } = useSelector((state) => state.common);

    const [pagination, setPagination] = useState({ offset: 0, limit: 10 });
    const [tab, setTab] = useState("");
    const [filterDate, setDates] = useState({ startDate: null, endDate: null });
    const [carFilter, setCarFilter] = useState("");

    const getBookingRequests = async (offset = pagination.offset, status = tab, carId = carFilter) => {
        let params = {
            limit: pagination.limit,
            offset,
        };

        if (status.length) {
            params.status = status;
        }

        if (carId.length) {
            params.carId = carId;
        }

        if (filterDate.startDate && filterDate.endDate) {
            params.fromDate = filterDate.startDate;
            params.toDate = filterDate.endDate;
            delete params.status;
        }

        getCarBookings(params, loader);
    };

    const switchHandler = async (bookingId) => {

        const payload = {
            type: "CAR",
            bookingId
        };

        const params = {
            bookingType: BOOKING_REQUESTS_TYPE.CAR_BOOKING,
            limit: pagination.limit,
            offset: pagination.offset,
        };

        acceptPayment(payload, loader, params);
    };

    const handleActiveTab = (tab) => {
        setTab(tab);

        getBookingRequests(0, tab);
    };

    const handleDateChange = (dates) => {
        const [startDate, endDate] = dates;

        if ((startDate && endDate) || (!startDate && !endDate)) {
            getBookingRequests();
        }
        setDates({ startDate, endDate });
    };

    const handleCarChange = (event) => {
        const { target: { value } } = event;

        setCarFilter(value);
        getBookingRequests(0, "", value);
    };

    useEffect(() => {
        getBookingRequests();
    }, [pagination]);

    useEffect(() => {
        if (user && user.userType === USER_TYPE.VENDOR) {
            const params = {
                offset: 0,
                limit: 100,
            };

            getCars(params, loader);
        }

    }, [user])

    return (
        <>
        <BookingRequest/>
        <div className={commonStyles["vendor-listing"]}>
            <div className="d-flex justify-content-between align-items-center mb-4 filter-pro">
                {/* <h3 className="mb-0">Car Reservations</h3> */}
                <div className="d-flex justify-content-between align-items-center">
                    <ul className="paginaion">
                        <li><Link to="/carBookings">Dashboard</Link></li>
                        <li className="active"><Link to="/">Car Requests</Link></li>
                    </ul>
                </div>

                <div className="d-flex filter-pro">
                    {
                        !filterDate.endDate ?
                            <select onChange={(event) => handleActiveTab(event.target.value)}>
                                <option value="">Filter by Status</option>
                                <option value="UPCOMING">Upcoming</option>
                                <option value="ACTIVE">Active</option>
                                <option value="PAST">Past</option>
                            </select> : null
                    }
                    <DatePicker
                        monthsShown={2}
                        selectsRange
                        startDate={filterDate.startDate}
                        selected={filterDate.startDate}
                        endDate={filterDate.endDate}
                        onChange={handleDateChange}
                        className={styles.filterDate}
                        dateFormat="dd MMM yyyy"
                        placeholderText="Filter by Date"
                        isClearable
                    />
                    {
                        user.userType === USER_TYPE.VENDOR ?
                            <select value={carFilter} onChange={handleCarChange} className={styles.carList}>
                                <option value="">Select Car</option>
                                {carsList.map(item => <option key={item._id} value={item._id}>{item.name}</option>)}
                            </select>
                            :
                            null
                    }
                </div>
            </div>

            {
                !requestLoader && carBookings.length === 0 ?
                    <div className={styles.noBookingContainer}>
                        <img width={300} src={NoBookingIcon} alt="no-booking" />
                        <p>No Booking Found</p>
                    </div>
                    :
                    null
            }
            {
                requestLoader ? <div className="d-flex justify-content-center align-items-center flex-column loading-text">
                    <h5>{MESSAGES.LOADING}</h5>
                </div> : null
            }

            {
                !requestLoader && carBookings.map((request) => (
                    <div key={request._id} className={styles.bookingCard} onClick={() => navigate(`/carBookings/${request._id}`)}>


                        <div className="row">
                            <div className="col-md-4">
                                <div className={styles["gray--first"]}>
                                    <div className="d-flex align-items-center">
                                        <img className={styles.userImage} src={PhotoIcon} alt="user" />
                                        <p className={styles.userName}>{request.userFirstName} {request.userLastName}</p>
                                    </div>
                                    <p className={styles.email}>
                                        <i className="far fa-envelope"></i>
                                        {request.email}
                                    </p>
                                    <p className={styles.mobileNo}>
                                        <i className="fas fa-phone-square-alt"></i>
                                        {request.phoneNumber}
                                        {/* {request.promotionData ? <span className={styles.promotion}>{request.promotionData.promotionName}</span> : null} */}
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-4 d-flex flex-column justify-content-center align-items-center">
                                <div className={styles ["gray-clr"]}>
                                    {/* <p className={styles.property}>{request.carName.length > 30 ? `${request.carName.slice(0, 30)}...` : request.carName}</p> */}
                                    <p className={styles.property}>Car Name</p>
                                    <p className={styles.checkInDates}>{moment(request.pickupDate).format("MMM DD, YYYY")} - {moment(request.dropDate).format("MMM DD, YYYY")}</p>
                                    <p className={styles.members}>{request.totalDays} Days - {request.memberString}</p>
                                    {request.voucherData && request.voucherData.voucherId ? <p className={styles.vouchers}>Voucher Used ({request.voucherData.voucherName})</p> : null}
                                </div>
                            </div>

                            <div className="col-md-4 d-flex flex-column justify-content-center align-items-end">
                                {request.paymentButton ? <div className="d-flex align-items-center justify-content-end">
                                    <p className={styles.paymentRequest}>Payment Request</p>
                                    <Switch
                                        onColor="#e89532"
                                        onChange={() => switchHandler(request._id, !request.hasDonePayment)}
                                        checked={request.hasDonePayment}
                                        disabled={request.hasDonePayment}
                                    />
                                </div> : null}
                                <div className={styles["gray--first"]}>
                                    <p className={styles.price}>{request.currencySymbol} {request.price.toFixed(2)}</p>
                                    <p className={styles.createdAt}>{moment(request.createdAt).format("MMM DD, YYYY")}</p>
                                    <p className={request.hasDonePayment ? styles.paid : styles.pending}>{request.hasDonePayment ? "Paid" : "Pending"}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
            <Pagination
                count={carBookingCount}
                setPagination={setPagination}
            />
        </div ></>
    );
};

const mapDispatchToProps = (dispatch) => ({
    getCarBookings: (query, loader) => dispatch(VendorBookingRequestAction.carBookingRequests(query, loader)),
    acceptPayment: (payload, loader, params) => dispatch(VendorBookingRequestAction.acceptBooking(payload, loader, params)),
    getCars: (query, loader) => dispatch(CommonAction.carsList(query, loader)),
})

export default connect(null, mapDispatchToProps)(CarBookingRequests);
