import "./assets/css/custom.css";
import "./assets/fonts/Stylesheet.css";
import "./assets/css/responsive.css";

import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector, connect } from "react-redux";
import io from "socket.io-client";

import { APP_ROUTES, routes } from "./routes";
import { Sidebar, Topbar, Loader } from "./components";
import AdminDashboard from "./pages/Admin/Dashboard";
import VendorDashboard from "./pages/Vendor/Dashboard";
import { USER_TYPE } from "./constants";
import { CommonAction } from "./store/common/thunk";
import COMMON_ACTIONS from "./store/common/actions";
import { ENV_CONFIG, storage } from "./utils";
import { WithAuth } from "./hoc";
import sidebarStyles from "./components/Layout/Sidebar/style.module.css";

const App = ({ loader, userDetails, getCurrency, getCurrentLocation }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user, socket } = useSelector((state) => state.common);

  const [collapse, setCollapse] = useState(true);
  const [showLoader, setLoader] = useState(0);
  const [appRoutes, setAppRoutes] = useState([]);

  const excludeSidebarTopbar =
    pathname === APP_ROUTES.LOGIN ||
    pathname === APP_ROUTES.FORGET_PASSWORD ||
    pathname.includes("reset-password");

  const style = excludeSidebarTopbar
    ? { width: "100%", transition: "inherit", padding: "0" }
    : { transition: "0.5s" };

  const getUserLocation = () => {
    const location = window.navigator && window.navigator.geolocation;

    if (location) {
      location.getCurrentPosition(
        async (position) => {
          getCurrentLocation(position, loader);
        },
        () => {
          dispatch({
            type: COMMON_ACTIONS.SET_USER_LOCATION,
            latitude: null,
            longitude: null,
            timezone: null,
          });
        }
      );
    }
  };

  const handleSidebarChange = () => setCollapse(!collapse);

  const setSocket = async (token) => {
    if (socket && !socket.connected) {
      const socket = io(ENV_CONFIG.SOCKET_URL, { auth: { token } });

      dispatch({ type: COMMON_ACTIONS.SET_USER_SOCKET, socket });
    }
  };

  useEffect(() => {
    const token = storage.getToken();

    if (token) {
      setSocket(token);
      userDetails(navigate, loader);
    } else {
      navigate(APP_ROUTES.LOGIN);
    }

    getUserLocation();
    getCurrency(loader);
  }, []);

  useEffect(() => {
    const tempRoutes = routes(setLoader).map((route, key) => (
      <Route
        exact
        path={route.path}
        key={key}
        element={
          user && user.userType && route.privateComponent ? (
            <WithAuth
              role={route.role ?? []}
              activeRole={user.userType}
              user={user}
              route={route}
            >
              {route.element}
            </WithAuth>
          ) : (
            route.element
          )
        }
      />
    ));
    setAppRoutes(tempRoutes);
  }, [user]);

  return (
    <div
      className={`${sidebarStyles.app} ${
        collapse ? ` ${sidebarStyles.active}` : ""
      }`}
    >
      <Loader progress={showLoader} />
      <div className="main-dashboard">
        {excludeSidebarTopbar ? null : (
          <div className={sidebarStyles["left-sidebar"]}>
            <Sidebar pathname={pathname} open={handleSidebarChange} />
          </div>
        )}

        <div className={sidebarStyles["right-sidebar"]} style={style}>
          {excludeSidebarTopbar ? null : (
            <Topbar
              open={handleSidebarChange}
              pathname={pathname}
              collapseSidebar={collapse}
            />
          )}
          <Routes>
            {user ? (
              <Route
                path={APP_ROUTES.DASHBOARD}
                element={
                  user.userType === USER_TYPE.ADMIN ? (
                    <WithAuth
                      role={[USER_TYPE.ADMIN]}
                      activeRole={user.userType}
                    >
                      <AdminDashboard loader={setLoader} />
                    </WithAuth>
                  ) : (
                    <WithAuth
                      role={[USER_TYPE.VENDOR]}
                      activeRole={user.userType}
                    >
                      <VendorDashboard loader={setLoader} />
                    </WithAuth>
                  )
                }
              />
            ) : null}

            {appRoutes}
          </Routes>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  userDetails: (navigate, loader) =>
    dispatch(CommonAction.getUserDetails(navigate, loader)),
  getCurrency: (loader) => dispatch(CommonAction.getCurrency(loader)),
  getCurrentLocation: (position, loader) =>
    dispatch(CommonAction.getCurrentLocation(position, loader)),
});

export default connect(null, mapDispatchToProps)(App);
