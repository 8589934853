import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Field, useFormik, FormikProvider } from "formik";
import { connect, useSelector } from "react-redux";
import axios from "axios";
import Autocomplete from "react-google-autocomplete";

import GoogleMap from "../../../../components/Map";
import { CityAreaSchema } from "../../../../schemas";
import { AdminBuildingAction, AdminCitiesAction } from "../../../../store/admin/thunk";
import { TIMEZONE_URL } from "../../../../constants";
import { ENV_CONFIG } from "../../../../utils";

const AddBuilding = ({ loader, create, update, specific }) => {
    const navigate = useNavigate();
    const params = useParams();

    const { locations: { area, building } } = useSelector((state) => state.admin);

    const [buildingLocation, setBuildingLocation] = useState({ latitude: 23.8859, longitude: 45.0792, address: "Saudi Arabia" });

    const navigateToBuildings = () => {
        navigate(`/cities/${params.citySlug}/areas/${params.areaSlug}/buildings`);
    };

    const createBuilding = async ({ name }) => {
        const response = await axios.get(`${TIMEZONE_URL}/${buildingLocation.latitude},${buildingLocation.longitude}`);

        if (response.data && response.data.timezone_id) {
            const payload = {
                name,
                areaId: area._id,
                latitude: buildingLocation.latitude.toString(),
                longitude: buildingLocation.longitude.toString(),
                timezone: response.data.timezone_id,
            };

            create(payload, loader, navigateToBuildings);
        }
    };

    const updateBuilding = async (values) => {
        const response = await axios.get(`${TIMEZONE_URL}/${buildingLocation.latitude},${buildingLocation.longitude}`);

        if (response.data && response.data.timezone_id) {
            const payload = {
                name: values.name,
                buildingId: building._id,
                latitude: buildingLocation.latitude.toString(),
                longitude: buildingLocation.longitude.toString(),
                timezone: response.data.timezone_id,
            };

            update(payload, loader, navigateToBuildings);
        }
    };

    const onDragEnd = async (event) => {
        const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${event.latLng.lat()},${event.latLng.lng()}&key=${ENV_CONFIG.GOOGLE_MAP_KEY}`
        );

        if (response.ok) {
            const data = await response.json();
            if (data.results.length > 0) {
                setBuildingLocation({ address: data.results[0].formatted_address, latitude: event.latLng.lat(), longitude: event.latLng.lng() })
            }
        }

    };

    const buildingFormik = useFormik({
        initialValues: { name: "", address: "", latitude: 23.8859, longitude: 45.0792 },
        onSubmit: (values) => params && params.buildingSlug ? updateBuilding(values) : createBuilding(values),
        validationSchema: CityAreaSchema
    });

    const getDetails = async (building) => {
        buildingFormik.setFieldValue("name", building.name);

        const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${building.latitude},${building.longitude}&key=${ENV_CONFIG.GOOGLE_MAP_KEY}`
        );

        if (response.ok) {
            const data = await response.json();
            if (data.results.length > 0) {
                setBuildingLocation({ address: data.results[0].formatted_address, latitude: building.latitude, longitude: building.longitude })
            }
        }
    };


    useEffect(() => {
        if (params && params.buildingSlug && Object.values(building).length) {
            getDetails(building);
        }
    }, [building]);

    const handleAddressChange = (newAddress) => {
        setBuildingLocation({ ...buildingLocation, address: newAddress.target.value });
    };

    useEffect(() => {

        if (params && params.buildingSlug) {
            specific({ slug: params.buildingSlug }, loader, "BUILDING");
        }
    }, [params.buildingSlug]);

    useEffect(() => {

        if (params && params.areaSlug) {
            specific({ slug: params.areaSlug }, loader, "AREA");
        }
    }, [params.areaSlug]);

    return (
        <div className="edit-users-sc bg-white">
            <FormikProvider value={buildingFormik}>
                <h4>{params && params.slug ? "Edit Building" : "Add New Building"}</h4>
                <div className="form-group">
                    <label>Name</label>
                    <Field name="name" placeholder="Enter name" />
                    {buildingFormik.errors.name && buildingFormik.touched.name ? (
                        <div className="error">{buildingFormik.errors.name}</div>
                    ) : null}
                </div>
                <div className="form-group">
                    <label>Building Location</label>
                    <Autocomplete
                        onPlaceSelected={(location) => {
                            let latitude = location.geometry.location.lat(),
                                address = location.formatted_address,
                                longitude = location.geometry.location.lng();
                            setBuildingLocation({ address, latitude: latitude, longitude })
                        }}
                        className="mb-2"
                        defaultValue={buildingLocation.address}
                        value={buildingLocation.address}
                        onChange={handleAddressChange}
                    />
                    <GoogleMap
                        isMarkerShown
                        draggable={true}
                        onDragEnd={onDragEnd}
                        positionalLat={buildingLocation.latitude}
                        positionalLong={buildingLocation.longitude}
                        latitudeCenter={buildingLocation.latitude}
                        longitudeCenter={buildingLocation.longitude}
                    />
                </div>
                <button className="mt-2" type="button" onClick={buildingFormik.submitForm}>{params && params.buildingSlug ? "Update" : "Add"}</button>
            </FormikProvider>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    update: (payload, loader, navigate) => dispatch(AdminBuildingAction.update(payload, loader, navigate)),
    create: (payload, loader, navigate) => dispatch(AdminBuildingAction.create(payload, loader, navigate)),
    specific: (payload, loader, type) => dispatch(AdminCitiesAction.specific(payload, loader, type))
});

export default connect(null, mapDispatchToProps)(AddBuilding);
