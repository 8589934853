import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import { connect, useSelector } from "react-redux";
import MarkdownIt from "markdown-it";

import { CommonAction } from "../../../../store/common/thunk";
import EditCar from "./EditPopup";
import { EDIT_CAR_TYPE, USER_TYPE } from "../../../../constants";
import {
    CarBootSpaceIcon,
    CarEngineIcon,
    CarFuelIcon,
    CarMileageIcon,
    CarModalIcon,
    CarSeatIcon,
    CarSpeedIcon,
    CarTransmissionIcon,
    LocationIcon
} from "../../../../assets/images";
import viewStyles from "../../styles/view.module.css";

const ViewCar = (props) => {
    let { id } = useParams();
    const mdParser = new MarkdownIt({ html: true, linkify: true, typographer: true });

    const { car, user, carLoader } = useSelector((state) => state.common);

    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();
    const [editCar, setEditCar] = useState({ show: false, type: "" });

    const closeEditCarModal = () => setEditCar({ show: false, type: "" });

    const handleCarOptionClick = (type) => {
        if (user.userType !== USER_TYPE.VENDOR) return;

        setEditCar({ show: true, type });
    };

    useEffect(() => {
        const query = { id };

        props.specificCar(query, props.loader);
    }, []);

    if (carLoader) {
        return <div className="d-flex justify-content-center align-items-center flex-column loading-text">
            <h5>Loading</h5>
        </div>
    }
    else if (!Object.keys(car).length) {
        return (
            <div className={`d-flex justify-content-center align-items-center flex-column ${viewStyles["no-data"]}`}>
                <i className="fa fa-exclamation-triangle" />
                <h4>No Car found</h4>
            </div>
        )
    }

    return (
        <div className="dasboad-property-detail">
            <div className={viewStyles["product-slider-main"]}>
                <div className="main-sider">
                    <div className={viewStyles["side-left"]}>
                        <Slider
                            asNavFor={nav2}
                            ref={(slider1) => setNav1(slider1)}
                            dots={true}
                        >
                            {car.images &&
                                car.images.map((slideimg, key) => (
                                    <div key={key}>
                                        <img src={slideimg} alt="" />
                                    </div>
                                ))}
                        </Slider>
                    </div>
                    <div className={viewStyles["side-sider"]}>
                        <div className="mobile-slide-custom">
                        <Slider
                            asNavFor={nav1}
                            ref={(slider2) => setNav2(slider2)}
                            slidesToShow={3}
                            swipeToSlide={true}
                            focusOnSelect={true}
                        >
                            <div>
                                <img src={car.images && car.images[0]} alt="" />
                            </div>
                            <div>
                                <img src={car.images && car.images[1]} alt="" />
                            </div>
                            <div>
                                <img src={car.images && car.images[2]} alt="" />
                            </div>
                        </Slider>
                        </div>
                        {
                            user.userType === USER_TYPE.VENDOR ?
                                <div onClick={() => setEditCar({ show: true, type: EDIT_CAR_TYPE.IMAGES })} className={viewStyles["show-all"]}>
                                    <p>Edit</p>
                                </div> : null
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className={viewStyles["slider-desc"]}>
                            <h4>
                                <div
                                    onClick={() => handleCarOptionClick(EDIT_CAR_TYPE.NAME)}
                                    className={`name d-flex ${user.userType === USER_TYPE.VENDOR ? "cursor-pointer" : ""}`}
                                >
                                    {car.name}
                                </div>
                                <label>
                                    AED {car.price}
                                </label>
                            </h4>
                            <h5
                                onClick={() => handleCarOptionClick(EDIT_CAR_TYPE.LOCATION)}
                                className={`loc d-flex ${user.userType === USER_TYPE.VENDOR ? "cursor-pointer" : ""}`}
                            >
                                <img src={LocationIcon} alt="location" />
                                {car.location}
                            </h5>

                            <div
                                onClick={() => handleCarOptionClick(EDIT_CAR_TYPE.DESCRIPTION)}
                                className={`description d-flex ${user.userType === USER_TYPE.VENDOR ? "cursor-pointer" : ""}`}
                            >
                                <h4>Description</h4>
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: mdParser.render(car.description) }} />

                            <div
                                onClick={() => handleCarOptionClick(EDIT_CAR_TYPE.FEATURES)}
                                className={`description d-flex ${user.userType === USER_TYPE.VENDOR ? "cursor-pointer" : ""}`}
                            >
                                <h4>Features</h4>
                            </div>
                            <ul className={`${viewStyles["car-amnites"]} ${viewStyles["aminity-wh-img"]}`} >
                                <li>
                                    <img src={CarModalIcon} alt="seats" />
                                    {car && car.model}
                                </li>
                                <li>
                                    <img src={CarFuelIcon} alt="fuel" />
                                    {car && car.fuel}
                                </li>
                                <li>
                                    <img src={CarMileageIcon} alt="mileage" />
                                    {car.features && car.features.mileage} mpg
                                </li>
                                <li>
                                    <img src={CarTransmissionIcon} alt="transmission" />
                                    {car.features && car.features.transmission}
                                </li>
                                <li>
                                    <img src={CarSeatIcon} alt="seats" />
                                    {car.features && car.features.seats} Seats
                                </li>
                                <li>
                                    <img src={CarEngineIcon} alt="engine" />
                                    {car.features && car.features.engine} CC Engine
                                </li>
                                <li>
                                    <img src={CarSpeedIcon} alt="bhp" />
                                    {car.features && car.features.bhp} BHP
                                </li>
                                <li>
                                    <img src={CarBootSpaceIcon} alt="boot_space" />
                                    {car.features && car.features.boot_space} Boot space
                                </li>
                            </ul>
                            <div className='description d-flex mb-2'>
                                <h4>View on map</h4>
                            </div>
                            <div id="map-show" className={viewStyles["map"]}>
                                <iframe id="iframeId" height="500px" width="100%" src={`https://maps.google.com/maps?q=${car && car.latitude},${car && car.longitude}&h1=es;&output=embed`}></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="my-gallary">
                <div
                    className="modal fade"
                    id="gallerymodal"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                <div className="modal-images">
                                    <div>
                                        <img src={`https://maps.google.com/maps?q=${car && car.latitude},${car && car.longitude}&h1=es;&output=embed`} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <EditCar {...editCar} car={car} closeEditCarModal={closeEditCarModal} loader={props.loader} />
        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
    specificCar: (query, loader) => dispatch(CommonAction.specificCar(query, loader)),
});

export default connect(null, mapDispatchToProps)(ViewCar);
