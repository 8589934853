import { combineReducers } from "@reduxjs/toolkit";

import { login, userTypes, cms } from "./slices/authSlice";
import { currencyType } from "./slices/currency";
import adminDashboardReducer from "./admin/reducers/dashboard";
import adminSubAdminReducer from "./admin/reducers/subAdmin";
import commonReducer from "./common/reducers/common";
import adminUserReducer from "./admin/reducers/user";
import adminVendorReducer from "./admin/reducers/vendor";
import adminPromotionReducer from "./admin/reducers/promotions";
import vendorPromotionReducer from "./vendor/reducers/promotions";
import vendorPropertyReducer from "./vendor/reducers/property";
import adminLocationReducer from "./admin/reducers/location";
import adminVouchersReducer from "./admin/reducers/vouchers";
import vendorCarReducer from "./vendor/reducers/cars";
import vendorBookingRequestReducer from "./vendor/reducers/bookingRequest";
import adminCategoryReducer from "./admin/reducers/category";
import vendorTourReducer from "./vendor/reducers/tour";
import vendorPayoutReducer from "./vendor/reducers/payouts";
import admninFAQReducer from "./admin/reducers/faq";
import adminCMSReducer from "./admin/reducers/cms";
import adminPayoutReducer from "./admin/reducers/payouts";

const rootReducer = combineReducers({
    auth: login,
    userTypes: userTypes,
    cms: cms,
    currencyType: currencyType,
    admin: combineReducers({
        dashboard: adminDashboardReducer,
        subAdmin: adminSubAdminReducer,
        user: adminUserReducer,
        vendor: adminVendorReducer,
        promotions: adminPromotionReducer,
        locations: adminLocationReducer,
        vouchers: adminVouchersReducer,
        categories: adminCategoryReducer,
        faq: admninFAQReducer,
        cms: adminCMSReducer,
        payouts: adminPayoutReducer,
    }),
    vendor: combineReducers({
        promotions: vendorPromotionReducer,
        properties: vendorPropertyReducer,
        cars: vendorCarReducer,
        bookingRequests: vendorBookingRequestReducer,
        tours: vendorTourReducer,
        payouts: vendorPayoutReducer,
    }),
    common: commonReducer,
});

export default rootReducer;