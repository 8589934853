import {Axios} from "../../../lib";
import { Toaster } from "../../../components/Toaster";
import { API_ENDPOINTS, MESSAGES } from "../../../constants";
import { APP_ROUTES } from "../../../routes";
import { TOAST_TYPE } from "../../../constants";
import ADMIN_ACTIONS from "../actions";

const list = (params, loader) => async (dispatch) => {
    try {
        const { data } = await Axios.get(API_ENDPOINTS.VOUCHER_CRUD, params, loader);
        dispatch({ type: ADMIN_ACTIONS.VOUCHERS_LIST_SUCCESS, vouchers: data.data, count: data.count });
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.SOMETHING_WENT_WRONG);
    }
};

const claimedUsers = (params, loader) => async (dispatch) => {
    try {
        const { data } = await Axios.get(API_ENDPOINTS.CLAIMED_VOUCHER_USERS, params, loader);
        dispatch({ type: ADMIN_ACTIONS.CLAIMED_VOUCHER_USERS_LIST, users: data.users, count: data.count });
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.SOMETHING_WENT_WRONG);
    }
};

const create = (payload, loader, navigate) => async () => {
    try {
        const response = await Axios.post(API_ENDPOINTS.VOUCHER_CRUD, payload, loader);

        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, MESSAGES.VOUCHER.CREATE.SUCCESS);
            navigate(APP_ROUTES.VOUCHERS);
        }
    }
    catch ({ error }) {
        if (error) {
            Toaster(TOAST_TYPE.ERROR, error);
        }
    }
}

const update = (payload, loader, navigate) => async () => {
    try {
        const response = await Axios.put(API_ENDPOINTS.VOUCHER_CRUD, payload, loader);

        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, MESSAGES.VOUCHER.EDIT.SUCCESS);
            navigate(APP_ROUTES.VOUCHERS);
        }
    }
    catch ({ error }) {
        if (error) {
            Toaster(TOAST_TYPE.ERROR, error);
        }
    }
}

const remove = (voucherId, loader) => async (dispatch) => {
    try {
        const params = { voucherId };
        const response = await Axios.delete(API_ENDPOINTS.VOUCHER_CRUD, { params: params }, loader);

        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, MESSAGES.VOUCHER.DELETE.SUCCESS);
            dispatch(list(loader));
        }
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.VOUCHER.DELETE.ERROR);
    }
};

const specific = (voucherId, loader) => async (dispatch) => {
    try {
        const params = { voucherId };

        const response = await Axios.get(API_ENDPOINTS.SPECIFIC_VOUCHER, params, loader);

        dispatch({ type: ADMIN_ACTIONS.SET_SPECIFIC_VOUCHER, voucher: response.data })
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.SOMETHING_WENT_WRONG);
    }
};

export {
    list,
    create,
    remove,
    specific,
    update,
    claimedUsers
};