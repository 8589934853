export const OPEN_CLOSE_BOOKING_TYPES = {
    CLOSE: 3,
    OPEN: 1,
    NONE: 0,
    SOLD_OUT: 2,
};

export const CALENDAR_DRAGGING_TYPE = {
    PRICE_DRAGGING: "PRICE_DRAGGING",
    NIGHT_DRAGGING: "NIGHT_DRAGGING",
};