import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormikProvider, Field, useFormik } from "formik";
import MdEditor from "react-markdown-editor-lite";
import MarkdownIt from "markdown-it";
import { connect, useSelector } from "react-redux";
import Swal from "sweetalert2";

import { AddUpdateFaqSchema } from "../../../../schemas";
import { EDITOR_PLUGINS, MESSAGES } from "../../../../constants";
import styles from "./style.module.css";
import { AdminFAQAction } from "../../../../store/admin/thunk";

const AddFaq = ({ loader, specific, create, update }) => {
    const params = useParams();
    const navigate = useNavigate();

    const { faq: { faqDetails, requestFAQ } } = useSelector((state) => state.admin);

    const mdParser = new MarkdownIt({ html: true, linkify: true, typographer: true });

    const submitFAQ = (values) => {
        const query = { page: 0, limit: 10 };
        if (params.id) {
            values = { ...values, faq_id: params.id };

            update(values, query, loader, navigate);
        }
        else {
            create(values, query, loader, navigate);
        }
    };

    const faqFormik = useFormik({
        initialValues: { question: "", answer: "" },
        validationSchema: AddUpdateFaqSchema,
        onSubmit: (values) => submitFAQ(values)
    });

    useEffect(() => {
        if (params.id) {
            specific(params.id, loader);
        }
    }, [params.id]);

    useEffect(() => {
        if (faqDetails && faqDetails._id) {
            faqFormik.setValues({
                question: faqDetails.question,
                answer: faqDetails.answer
            });
        }
    }, [faqDetails]);

    // if (params.id && requestFAQ) {
    //     return <div className="d-flex justify-content-center align-items-center flex-column loading-text">
    //         <h5>{MESSAGES.LOADING}</h5>
    //     </div>
    // }

    return (
        <div className="edit-users-sc">
            <FormikProvider value={faqFormik}>
                <div className="form-group">
                    <label>Question</label>
                    <Field name="question" placeholder="Enter question" />
                    {faqFormik.errors.question && faqFormik.touched.question ? (
                        <div className="error">{faqFormik.errors.question}</div>
                    ) : null}
                </div>

                <div className="form-group">
                    <label>Answer</label>
                    <MdEditor
                        plugins={EDITOR_PLUGINS}
                        value={faqFormik.values.answer}
                        className={styles.answer}
                        renderHTML={text => mdParser.render(text)}
                        onChange={({ text }) => faqFormik.setFieldValue("answer", text)}
                        view={{ menu: true, md: true, html: false }}
                    />
                    {faqFormik.errors.answer && faqFormik.touched.answer ? (
                        <div className="error">{faqFormik.errors.answer}</div>
                    ) : null}
                </div>

                <button onClick={faqFormik.submitForm} type="submit">{params.id ? "Update" : "Add"}</button>
            </FormikProvider>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    specific: (query, loader) => dispatch(AdminFAQAction.specific(query, loader)),
    create: (payload, query, loader, navigate) => dispatch(AdminFAQAction.create(payload, query, loader, navigate)),
    update: (payload, query, loader, navigate) => dispatch(AdminFAQAction.update(payload, query, loader, navigate)),

});

export default connect(null, mapDispatchToProps)(AddFaq);
