import React from "react";
import { useNavigate } from "react-router-dom";
import { Field, useFormik, FormikProvider } from "formik";
import { connect, useDispatch, useSelector } from "react-redux";

import { SubadminSchema } from "../../../../schemas";
import { AdminSubAdminAction } from "../../../../store/admin/thunk";
import { useEffect } from "react";
import ADMIN_ACTIONS from "../../../../store/admin/actions";
import { APP_ROUTES } from "../../../../routes";

const AddSubAdmin = ({ loader, create }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { subAdmin: { createSubAdmin } } = useSelector((state) => state.admin);

    const subAdminFormik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
        },
        onSubmit: (values) => {
            create(values, loader);
        },
        validationSchema: SubadminSchema
    });

    useEffect(() => {
        dispatch({ type: ADMIN_ACTIONS.CREATE_SUB_ADMIN_SUCCESS, status: false });
    }, []);

    useEffect(() => {
        if(createSubAdmin) {
            navigate(APP_ROUTES.SUB_ADMIN_LIST);
        }
    }, [createSubAdmin]);

    return (
        <div className="edit-users-sc">
            <FormikProvider value={subAdminFormik}>
                <form onSubmit={subAdminFormik.handleSubmit}>
                    <div className="form-group">
                        <label>First name</label>
                        <Field name="firstName" placeholder="First name" />
                        {subAdminFormik.errors.firstName && subAdminFormik.touched.firstName ? (
                            <div className="error">{subAdminFormik.errors.firstName}</div>
                        ) : null}
                    </div>

                    <div className="form-group">
                        <label>Last name</label>
                        <Field name="lastName" placeholder="Last Name" />
                        {subAdminFormik.errors.lastName && subAdminFormik.touched.lastName ? (
                            <div className="error">{subAdminFormik.errors.lastName}</div>
                        ) : null}
                    </div>

                    <div className="form-group">
                        <label>Enter Email</label>
                        <Field name="email" placeholder="Email" />
                        {subAdminFormik.errors.email && subAdminFormik.touched.email ? (
                            <div className="error">{subAdminFormik.errors.email}</div>
                        ) : null}
                    </div>

                    <div className="form-group">
                        <label>Enter Password</label>
                        <Field name="password" placeholder="Password" type="password" />
                        {subAdminFormik.errors.password && subAdminFormik.touched.password ? (
                            <div className="error">{subAdminFormik.errors.password}</div>
                        ) : null}
                    </div>

                    <button type="submit">Add</button>
                </form>
            </FormikProvider>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    create: (payload, loader) => dispatch(AdminSubAdminAction.create(payload, loader))
});

export default connect(null, mapDispatchToProps)(AddSubAdmin);
