import { createSlice } from "@reduxjs/toolkit";
import { CMS_TYPE } from "../../constants";

const authSlice = createSlice({
    name: "auth",
    initialState: {},
    reducers: { loginDetails: (state, action) => action },
});

const userTypeSlice = createSlice({
    name: "type",
    initialState: { admin: false, vendor: false },
    reducers: { userType: (state, action) => action },
});

const csmSlice = createSlice({
    name: "cms",
    initialState: { payload: CMS_TYPE.ABOUT_US },
    reducers: { cmsData: (state, action) => action },
});

export const { loginDetails } = authSlice.actions;
export const { cmsData } = csmSlice.actions;
export const { userType } = userTypeSlice.actions;

export const login = authSlice.reducer;
export const userTypes = userTypeSlice.reducer;
export const cms = csmSlice.reducer;
