import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";

import Linechart from "../../../components/Linechart/Linechart";
import Linecharttwo from "../../../components/Linechart/Linecharttwo";
import Linechartthree from "../../../components/Linechart/Linechartthree";
import { AdminDashboardAction } from "../../../store/admin/thunk";
import { APP_ROUTES } from "../../../routes";
import dashboardStyles from "../../Vendor/Dashboard/style.module.css";

function AdminDashboard({ loader, getDashboardDetails }) {

    const { dashboard: { dashboardDetails } } = useSelector((state) => state.admin);

    const adminDashboard = async () => {
        getDashboardDetails(loader);
    };

    useEffect(() => {
        adminDashboard();
    }, []);

    return (
        <Fragment>
            <div className={dashboardStyles["proerty-tabs"]}>
                <div className="row">
                    <div className="col-md-3">
                        <Link to={APP_ROUTES.REVENUES_LIST}>
                            <div className={`${dashboardStyles["inner-tabs"]} ${dashboardStyles["card-shadow"]} `}>
                                <h5>Admin Total Revenue</h5>
                                <h4>{dashboardDetails.total_revenue}</h4>
                                <span>
                                    <i className="fas fa-coins"></i>
                                </span>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-3">
                        <Link to={APP_ROUTES.DAILY_REVENUE}>
                            <div className={`${dashboardStyles["inner-tabs"]} ${dashboardStyles["card-shadow"]} `}>
                                <h5>Daily Revenue</h5>
                                <h4>{dashboardDetails.daily_revenue}</h4>
                                <span>
                                    <i className="fas fa-chart-pie"></i>
                                </span>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-3">
                        <Link to={APP_ROUTES.USERS_LIST}>
                            <div className={`${dashboardStyles["inner-tabs"]} ${dashboardStyles["card-shadow"]} `}>
                                <h5>Total Users</h5>
                                <h4>{dashboardDetails.total_users}</h4>
                                <span>
                                    <i className="fas fa-users"></i>
                                </span>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-3">
                        <Link to={APP_ROUTES.VENDOR_LIST}>
                            <div className={`${dashboardStyles["inner-tabs"]} ${dashboardStyles["card-shadow"]} `}>
                                <h5>Total Vendors</h5>
                                <h4>{dashboardDetails.total_vendors}</h4>
                                <span>
                                    <i className="fas fa-user-friends"></i>
                                </span>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="proerty-tabs mt-4">
                <div className="row">
                    <div className="col-md-4">
                        <div className={`${dashboardStyles["inner-tabs"]} ${dashboardStyles["card-shadow"]} `}>
                            <h5>Property Booking Requests</h5>
                            <h4>{dashboardDetails.total_booking_requests?.property}</h4>
                            <span>
                                <i className="fas fa-city"></i>
                            </span>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className={`${dashboardStyles["inner-tabs"]} ${dashboardStyles["card-shadow"]} `}>
                            <h5>Vehicle Booking Requests</h5>
                            <h4>{dashboardDetails.total_booking_requests?.vehicle}</h4>
                            <span>
                                <i className="fas fa-car"></i>
                            </span>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className={`${dashboardStyles["inner-tabs"]} ${dashboardStyles["card-shadow"]} `}>
                            <h5>Tour Booking Requests</h5>
                            <h4>{dashboardDetails.total_booking_requests?.tours}</h4>
                            <span>
                                <i className="fa fa-fighter-jet"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="pie-chart">
                <div className="row">
                    <div className="col-md-4">
                        <div className="pie-chart-left card-shadow">
                            <h3>Properties</h3>
                            <Linechart data={dashboardDetails.bookedProperties} />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="pie-chart-right card-shadow">
                            <h3>Cars</h3>
                            <Linecharttwo data={dashboardDetails.bookedCars} />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="pie-chart-left card-shadow">
                            <h3>Tours</h3>
                            <Linechartthree data={dashboardDetails.bookedTours} />
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="table-chart">
                <div className="row">
                    <div className="col-md-4">
                        <div className="table-chart-left card-shadow">
                            <RecentTable
                                listing={dashboardDetails.recent_properties}
                                name="Recent Properties"
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="table-chart-right card-shadow">
                            <RecentTable listing={dashboardDetails.recent_cars} name="Recent Cars" />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="table-chart-right card-shadow">
                            <RecentTable listing={dashboardDetails.recent_tours} name="Recent Tours" />
                        </div>
                    </div>
                </div>
            </div> */}
        </Fragment>
    );
}

const mapDispatchToProps = (dispatch) => ({
    getDashboardDetails: (loader) => dispatch(AdminDashboardAction.getDashboardDetails(loader))
});

export default connect(null, mapDispatchToProps)(AdminDashboard);
