import { Toaster } from "../../../components";
import { API_ENDPOINTS, MESSAGES, TOAST_TYPE, USER_TYPE } from "../../../constants";
import { Axios } from "../../../lib";
import COMMON_ACTIONS from "../../common/actions";

const getInvoiceList = (query, userType, loader) => async (dispatch) => {
    try {
        dispatch({ type: COMMON_ACTIONS.REQUEST_INVOICE_LIST, status: true });

        if (userType === USER_TYPE.ADMIN) {
            const response = await Axios.get(API_ENDPOINTS.ADMIN_INVOICE_LIST, query, loader);
            dispatch({ type: COMMON_ACTIONS.SET_INVOICE_LIST, invoices: response.data.invoices, count: response.data.count });
        }
        else {
            const response = await Axios.get(API_ENDPOINTS.VENDOR_INVOICE_LIST, query, loader);
            dispatch({ type: COMMON_ACTIONS.SET_INVOICE_LIST, invoices: response.data.invoices, count: response.data.count });
        }

        dispatch({ type: COMMON_ACTIONS.REQUEST_INVOICE_LIST, status: false });
    }
    catch (err) {
        dispatch({ type: COMMON_ACTIONS.REQUEST_INVOICE_LIST, status: false });
    }
};

const specificInvoiceDetails = (query, userType, loader) => async (dispatch) => {

    try {
        dispatch({ type: COMMON_ACTIONS.REQUEST_SPECIFIC_INVOICE, status: true });

        if (userType === USER_TYPE.ADMIN) {
            const response = await Axios.get(API_ENDPOINTS.ADMIN_SPECIFIC_INVOICE, query, loader);
            dispatch({ type: COMMON_ACTIONS.SET_SPECIFIC_INVOICE_DETAILS, invoices: response.data.payouts, count: response.data.count });
        }
        else {
            const response = await Axios.get(API_ENDPOINTS.VENDOR_SPECIFIC_INVOICE, query, loader);

            dispatch({ type: COMMON_ACTIONS.SET_SPECIFIC_INVOICE_DETAILS, invoices: response.data.payouts, count: response.data.count });
        }

        dispatch({ type: COMMON_ACTIONS.REQUEST_SPECIFIC_INVOICE, status: false });
    }
    catch (err) {
        dispatch({ type: COMMON_ACTIONS.REQUEST_SPECIFIC_INVOICE, status: false });
    }
};


const markPayoutSuccess = (payload, loader, query, userType) => async (dispatch) => {
    try {

        await Axios.post(API_ENDPOINTS.MARK_PAYOUT, payload, loader);
        Toaster(TOAST_TYPE.SUCCESS, MESSAGES.PAYOUTS.MARK_PAYOUT_SUCCESS);

        dispatch(getInvoiceList(query, userType, loader));
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.PAYOUTS.MARK_PAYOUT_ERROR);
    }
};

export {
    getInvoiceList,
    specificInvoiceDetails,
    markPayoutSuccess
};