import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Field, useFormik, FormikProvider } from "formik";
import { connect, useSelector } from "react-redux";

import { ImageCatSchema } from "../../../../schemas";
import { AdminCitiesAction } from "../../../../store/admin/thunk";
import { uploadImage } from "../../../../utils/imageHelper";
import styles from "./style.module.css";
import { createImageCat, updateImageCat, getSingleImageCategories } from "../API";

const AddImageCat = () => {

  const navigate = useNavigate();
  const [imageCategoryData, setImageCtegoryData] = useState({})

  const params = useParams();

useEffect(()=>{
  console.log(params)
    if(params && params?.id)  getSingleImageCategories(params?.id).then(res =>{
        imageCatFormik.setFieldValue("name", res?.data?.name )
      }).catch((error)=>{
        imageCatFormik.setErrors({ name: error?.error || "Something went wrong!." });
      });
   
},[params])

  const addImageCat = async ({ name }) => {
    const payload = { name };
    createImageCat(payload).then(res =>{

        if(res?.statusCode == 400) return imageCatFormik.setErrors({ name: res?.message || "Something went wrong!." });
        navigate('/image_categories')
        
      }).catch((error)=>{
        imageCatFormik.setErrors({ name: error?.error || "Something went wrong!." });
      });;
  };

  const updateCatImage = async (values) => {
      const payload = {
          name: values.name,
          id : params?.id
      };
      updateImageCat(payload).then(res =>{
        if(!res?.status) imageCatFormik.setErrors({ name: res?.error || "Something went wrong!." });
        navigate('/image_categories')
      }).catch((error)=>{
        imageCatFormik.setErrors({ name: error?.error || "Something went wrong!." });
      });
  };


    const imageCatFormik = useFormik({
      initialValues: { name : "" },
      validationSchema: ImageCatSchema,
      onSubmit: (values) =>
        params && params.id
          ? updateCatImage(values)
          : addImageCat(values),
      
    });

    

  return (
    <div className="edit-users-sc bg-white">
      <FormikProvider value={imageCatFormik}>
        <h4>{params && params.id ? "Edit Image Category" : "Add New Image Category"}</h4>

        <div className="form-group">
          <label>Name</label>
          <Field name="name" placeholder="Enter name" />
          {imageCatFormik.errors.name && imageCatFormik.touched.name ? (
            <div className="error">{imageCatFormik.errors.name}</div>
          ) : null}
        </div>

        <button type="button" onClick={imageCatFormik.submitForm}>
          {params && params.id ? "Update" : "Add"}
        </button>
      </FormikProvider>
    </div>
  );
};

export default AddImageCat;
