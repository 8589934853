import { Toaster } from "../../../components";
import { API_ENDPOINTS, TOAST_TYPE } from "../../../constants";
import { Axios } from "../../../lib";
import ADMIN_ACTIONS from "../actions";

const payoutsList = (params, loader) => async (dispatch) => {
    try {

        dispatch({ type: ADMIN_ACTIONS.REQUEST_ADMIN_PAYOUT_CARDS, status: true });
        const response = await Axios.get(API_ENDPOINTS.LIST_PAYOUT_CARD, params, loader);

        for (let item of response.data.accountData) {
            let splitArr = item.accountNumber.split(' ');

            splitArr.forEach((item, index) => {
                if (index < 3) {
                    splitArr[index] = '****';
                }
            });

            splitArr = splitArr.join(' ');
            item.accountNumber = splitArr;
        }

        dispatch({ type: ADMIN_ACTIONS.REQUEST_ADMIN_PAYOUT_CARDS, status: false });
        dispatch({ type: ADMIN_ACTIONS.ADMIN_PAYOUT_CARDS_SUCCESS, payouts: response.data.accountData, count: response.data.count });
    }
    catch (error) {
        dispatch({ type: ADMIN_ACTIONS.REQUEST_ADMIN_PAYOUT_CARDS, status: false });
    }
};

const updatePayoutCard = (payload, loader, query) => async (dispatch) => {
    try {
        const response = await Axios.put(API_ENDPOINTS.UPDATE_PAYOUT_CARD_STATUS, payload, loader);

        dispatch(payoutsList(query, loader));

        Toaster(TOAST_TYPE.SUCCESS, 'Card updated successfully');
    }
    catch (error) {
    }
};


export {
    payoutsList,
    updatePayoutCard
};