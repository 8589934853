import ADMIN_ACTIONS from "../actions";

const initialState = {
    userList: [],
    count: 0,
    user: null,
    loading: false,
};

const adminUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADMIN_ACTIONS.USER_LIST_SUCCESS:
            return {
                ...state,
                userList: action.users,
                count: action.count,
                createVendorSuccess: false,
            };

        case ADMIN_ACTIONS.SPECIFIC_USER_SUCCESS:
            return {
                ...state,
                user: action.user,
            };

        case ADMIN_ACTIONS.REQUEST_USER_LIST:
            return {
                ...state,
                loading: action.status,
            };

        default:
            return state;
    }
};

export default adminUserReducer;