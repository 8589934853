import {Axios} from "../../../lib";
import { Toaster } from "../../../components/Toaster";
import { TOAST_TYPE, USER_TYPE, MESSAGES, API_ENDPOINTS } from "../../../constants";
import { APP_ROUTES } from "../../../routes";
import ADMIN_ACTIONS from "../actions";

const list = (payload, loader, userType) => async (dispatch) => {
    try {
        const { data } = await Axios.get(API_ENDPOINTS.CREATE_UPDATE_LIST_CITIES, payload, loader);

        if(userType === USER_TYPE.VENDOR) {
            dispatch({ type: ADMIN_ACTIONS.CITIES_LIST_SUCCESS, cities: data, count: 0 });
        }
        else {
            dispatch({ type: ADMIN_ACTIONS.CITIES_LIST_SUCCESS, cities: data.cities, count: data.count });
        }
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.SOMETHING_WENT_WRONG);
    }
};

const update = (payload, loader, navigate) => async (dispatch) => {
    try {
        const response = await Axios.patch(API_ENDPOINTS.CREATE_UPDATE_LIST_CITIES, payload, loader);

        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, MESSAGES.CITY.UPDATE_SUCCESS);

            if (payload.name || payload.image) {
                navigate(APP_ROUTES.CITIES_LIST);
            }
            else {
                const params = {
                    offset: 0,
                    limit: 10,
                    showAllCities: true,
                };

                dispatch(list(params, loader));
            }
        }
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.CITY.UPDATE_ERROR);
    }
};

const create = (payload, loader, navigate) => async () => {
    try {
        const response = await Axios.post(API_ENDPOINTS.CREATE_UPDATE_LIST_CITIES, payload, loader);

        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, MESSAGES.CITY.CREATE_SUCCESS);

            if (payload.name || payload.image) {
                navigate(APP_ROUTES.CITIES_LIST);
            }
        }
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.CITY.CREATE_ERROR);
    }
};

const specific = (payload, loader, type) => async (dispatch) => {
    try {
        const response = await Axios.get(API_ENDPOINTS.SPECIFIC_CITY_AREA_BUILDINGS, payload, loader);

        if (type === "CITY") {
            dispatch({ type: ADMIN_ACTIONS.SPECIFIC_CITY_SUCCESS, location: response.data });
        }
        else if (type === "AREA") {
            dispatch({ type: ADMIN_ACTIONS.SPECIFIC_AREA_SUCCESS, location: response.data });
        }
        else {
            dispatch({ type: ADMIN_ACTIONS.SPECIFIC_BUILDING_SUCCESS, location: response.data });
        }


    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.SOMETHING_WENT_WRONG);
    }
};

export {
    list,
    update,
    create,
    specific
};