import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Field, useFormik, FormikProvider } from "formik";
import { connect, useSelector } from "react-redux";
import {
  FILE_UPLOAD_TYPE,
  IMAGE_FILE_TYPES,
  MESSAGES,
} from "../../../../constants";
import { CityAreaSchema } from "../../../../schemas";
import {
  AdminAreasAction,
  AdminCitiesAction,
} from "../../../../store/admin/thunk";
import { uploadImage } from "../../../../utils/imageHelper";
import styles from "./style.module.css";

const AddArea = ({ loader, create, update, specific }) => {
  const navigate = useNavigate();
  const params = useParams();

  const {
    locations: { city, area },
  } = useSelector((state) => state.admin);
  const [selectedImage, setSelectedImage] = useState("");
  const navigateToAreas = () => {
    navigate(`/cities/${params.citySlug}/areas`);
  };

  const createArea = async ({ name, description }) => {
    if (selectedImage.length === 0) {
      areasFormik.setFieldError("images", "Required");
      return;
    }

    const image = await uploadFile();
    const payload = { name, cityId: city?._id, description, image: image };
    create(payload, loader, navigateToAreas);
  };

  const updateArea = async (values) => {

    if (!selectedImage || selectedImage.length === 0) {
        areasFormik.setFieldError("images", "Required");
        return;
    }

    let image = selectedImage;

    if (typeof image !== "string") {
        image = await uploadFile();
    }

    const payload = {
      name: values.name,
      areaId: area._id,
      image: image,
      description: values.description
    };

    update(payload, loader, navigateToAreas);
  };
  const uploadFile = async () => {
    const formData = new FormData();
    formData.append("file", selectedImage);
    formData.append("type", FILE_UPLOAD_TYPE.CITY);

    const imageResponse = await uploadImage(formData);
    return imageResponse.data.fileUrl;
  };
  const areasFormik = useFormik({
    initialValues: { name: "", description : "" },
    onSubmit: (values) =>
      params && params.areaSlug ? updateArea(values) : createArea(values),
    validationSchema: CityAreaSchema,
  });

  useEffect(() => {
    if (params.areaSlug && Object.keys(area).length) {
      areasFormik.setFieldValue("name", area.name);
      areasFormik.setFieldValue("description", area.description);
      setSelectedImage(area.image);
    }
  }, [area]);

  useEffect(() => {
    if (params && params.citySlug) {
      specific({ slug: params.citySlug }, loader, "CITY");
    }
  }, [params.citySlug]);

  useEffect(() => {
    if (params && params.areaSlug) {
      specific({ slug: params.areaSlug }, loader, "AREA");
    }
  }, [params.areaSlug]);

  const handleImageChange = (event) => {
    const { files } = event.target;

    const invalidFileTypes = Object.values(files)
      .map((item) => item.type)
      .filter((item) => !IMAGE_FILE_TYPES.includes(item));

    if (files && files.length) {
      if (invalidFileTypes.length) {
        citiesFormik.setFieldError("images", MESSAGES.VALID_IMAGE_FILE_TYPE);
      } else {
        const selectedFiles = Array.from(files);

        setSelectedImage(selectedFiles[0]);
      }
    }
  };

  return (
    <div className="edit-users-sc bg-white">
      <FormikProvider value={areasFormik}>
        <h4>
          {params && params.slug && params.areaSlug
            ? "Edit Area"
            : "Add New Area"}
        </h4>
        <div className="form-group">
          <label>Name</label>
          <Field name="name" placeholder="Enter name" />
          {areasFormik.errors.name && areasFormik.touched.name ? (
            <div className="error">{areasFormik.errors.name}</div>
          ) : null}
          <label>Description</label>
          <Field
            as="textarea"
            name="description"
            placeholder="Enter description"
            rows="4"
            cols="50"
          />
           {areasFormik.errors.description && areasFormik.touched.description ? (
            <div className="error">{areasFormik.errors.description}</div>
          ) : null}
          <div className="form-group add-image">
            <label>Image</label>
            <Field
              name="images"
              placeholder=""
              type="file"
              accept="image/*"
              onChange={(event) => handleImageChange(event)}
              className="show-for-sr"
            />
            <div className="upl-tet">Upload Image</div>

            {areasFormik.errors.images ? (
              <div className="error mt-3">{areasFormik.errors.images}</div>
            ) : null}
            {selectedImage && (
              <div className={styles["main-img-priew"]}>
                <img
                  name="hero"
                  src={
                    typeof selectedImage === "string"
                      ? selectedImage
                      : URL.createObjectURL(selectedImage)
                  }
                  alt="Thumb"
                />
                <p onClick={() => setSelectedImage(null)}>X</p>
              </div>
            )}
          </div>
        </div>

        <button type="button" onClick={areasFormik.submitForm}>
          {params && params.areaSlug ? "Update" : "Add"}
        </button>
      </FormikProvider>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  update: (payload, loader, navigate) =>
    dispatch(AdminAreasAction.update(payload, loader, navigate)),
  create: (payload, loader, navigate) =>
    dispatch(AdminAreasAction.create(payload, loader, navigate)),
  specific: (payload, loader, type) =>
    dispatch(AdminCitiesAction.specific(payload, loader, type)),
});

export default connect(null, mapDispatchToProps)(AddArea);
