import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { connect, useSelector } from "react-redux";

import { APP_ROUTES } from "../../../../routes";
import { AdminVendorAction } from "../../../../store/admin/thunk";

const ViewVendor = ({ loader, specific }) => {
    const params = useParams();

    const { vendor: { vendor } } = useSelector((state) => state.admin);

    const getVendor = async () => {
        const payload = { vendor_id: params.id };

        specific(payload, loader);
    };

    useEffect(() => {
        getVendor();
    }, []);

    return (
        <div className="edit-users-sc bg-white">
            <div className="form-group">
                <img src={vendor.image} alt="vendor" />
            </div>

            <div className="form-group">Name : {vendor.firstName}</div>
            <div className="form-group">Email : {vendor.email}</div>
            <div className="form-group">
                Account Status :
                {`${vendor.status === "ACITVE" ? " Active" : " Inactive"}`}
            </div>

            <Link to={APP_ROUTES.VENDOR_LIST}>
                <button>Go Back</button>
            </Link>
        </div>
    );
};


const mapDispatchToProps = (dispatch) => ({
    specific: (query, loader) => dispatch(AdminVendorAction.specific(query, loader)),
});

export default connect(null, mapDispatchToProps)(ViewVendor);
