import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Field, useFormik, FormikProvider } from "formik";
import { connect, useSelector } from "react-redux";

import { CityAreaSchema } from "../../../../../schemas";
import { AdminCategoryAction } from "../../../../../store/admin/thunk";

const AddSubCategory = ({ loader, create, specificSubCategory, update }) => {
    const params = useParams();
    const navigate = useNavigate();

    const { categories: { subCategory } } = useSelector(state => state.admin);

    const subCategoriesFormik = useFormik({
        initialValues: { name: "" },
        validationSchema: CityAreaSchema,
        onSubmit: (values) => {
            if(params.subCategory) {
                const payload = {
                    ...values,
                    subcategory_id: params.subCategory
                };   
                update(payload, loader, navigate, { categoryId: params.id });
            }
            else {
                const payload = {
                    ...values,
                    category_id: params.id,
                };   
                create(payload, loader, navigate);
            }
        }
    });

    useEffect(() => {
        if (params.subCategory) {
            specificSubCategory(params.subCategory, loader);
        }
    }, [params.subCategory]);

    useEffect(() => {
        if (subCategory) {
            subCategoriesFormik.setValues({
                name: subCategory.name
            });
        }
    }, [subCategory]);

    return (
        <div className="edit-users-sc">
            <FormikProvider value={subCategoriesFormik}>
                <div className="form-group">
                    <label>Enter name</label>
                    <Field name="name" placeholder="Enter name" />
                    {subCategoriesFormik.errors.name && subCategoriesFormik.touched.name ? (
                        <div className="error">{subCategoriesFormik.errors.name}</div>
                    ) : null}
                </div>
                <button type="button" onClick={subCategoriesFormik.handleSubmit}>{params.subCategory ? "Update" : "Add"}</button>
            </FormikProvider>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    create: (payload, loader, navigate) => dispatch(AdminCategoryAction.createSubCategory(payload, loader, navigate)),
    update: (payload, loader, navigate, params) => dispatch(AdminCategoryAction.updateSubCategory(payload, loader, navigate, params)),
    specificCategory: (payload, loader) => dispatch(AdminCategoryAction.specificCategory(payload, loader)),
    specificSubCategory: (payload, loader) => dispatch(AdminCategoryAction.specificSubCategory(payload, loader))
});


export default connect(null, mapDispatchToProps)(AddSubCategory);
