import * as Yup from "yup";

import { MESSAGES } from "../constants";

const calendarPriceSchema = Yup.object().shape({
    price: Yup.number().required(MESSAGES.VALIDATION_ERRORS.REQUIRED).min(1, MESSAGES.VALIDATION_ERRORS.NON_ZERO_AMOUNT),
    openCloseStatus: Yup.number().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
    night: Yup.number().required(MESSAGES.VALIDATION_ERRORS.REQUIRED).min(1, MESSAGES.VALIDATION_ERRORS.NON_ZERO_CALENDAR_NIGHT)
});

export default calendarPriceSchema;