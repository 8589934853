import React, { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import { useSelector, connect } from "react-redux";
import MarkdownIt from "markdown-it";

import { VendorTourAction } from "../../../../store/vendor/thunk";
import EditTour from "./EditPopup";
import { CATEGORY_TYPE, EDIT_TOUR_TYPE, USER_TYPE } from "../../../../constants";
import { AdminCitiesAction } from "../../../../store/admin/thunk";
import { LocationIcon } from "../../../../assets/images";
import { CommonAction } from "../../../../store/common/thunk";
import viewStyles from "../../styles/view.module.css";


const ViewTour = ({ specificTour, loader, citiesList, categoriesList }) => {
    let params = useParams();
    const mdParser = new MarkdownIt({ html: true, linkify: true, typographer: true });

    const { tours: { tour, tourLoader } } = useSelector((state) => state.vendor);
    const { user } = useSelector((state) => state.common);

    const [editTour, setEditTour] = useState({ show: false, type: "" });
    const [selectedCategory, setCategory] = useState({ subCategory: [], category: {} });
    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();

    const closeEditTourModal = () => setEditTour({ show: false, type: "" });

    const handleTourOptionClick = (type) => setEditTour({ show: true, type: type })

    useEffect(() => {
        specificTour(params, loader);

        const query = { type: CATEGORY_TYPE.TOUR };

        categoriesList(query, loader);
    }, []);

    useEffect(() => {
        if (user && user.userType) {
            const cityQuery = { offset: 0, limit: 1000, type: "TOUR" };
            citiesList(cityQuery, loader, user.userType);
        }
    }, [user]);

    useEffect(() => {
        if (Object.keys(tour).length) {
            const selectedCategory = tour.categoryData.find(item => item.isSelected);
            const selectedSubCategory = selectedCategory.subCategoryData.filter(item => item.isSelected);

            setCategory({ category: selectedCategory, subCategory: selectedSubCategory });
        }
    }, [tour]);

    if (tourLoader) {
        return <div className="d-flex justify-content-center align-items-center flex-column loading-text">
            <h5>Loading</h5>
        </div>
    }
    else if (!Object.keys(tour).length) {
        return (
            <div className={`d-flex justify-content-center align-items-center flex-column ${viewStyles["no-data"]}`}>
                <i className="fa fa-exclamation-triangle" />
                <h4>No Tour found</h4>
            </div>
        )
    }

    return (
        <div className="dasboad-property-detail">
            <div className={viewStyles["product-slider-main"]}>
                <div className="main-sider">
                    <div className={viewStyles["side-left"]}>
                        <Slider
                            asNavFor={nav2}
                            ref={(slider1) => setNav1(slider1)}
                            dots={true}
                        >
                            {tour.images && tour.images.map((slideimg, key) => (
                                <img src={slideimg} alt="tour" key={key} />
                            ))}
                        </Slider>
                    </div>
                    <div className={viewStyles["side-sider"]}>
                    <div className="mobile-slide-custom">
                        <Slider
                            asNavFor={nav1}
                            ref={(slider2) => setNav2(slider2)}
                            slidesToShow={3}
                            swipeToSlide={true}
                            focusOnSelect={true}
                        >
                            <div>
                                <img src={tour.images && tour.images[0]} alt="tour1" />
                            </div>
                            <div>
                                <img src={tour.images && tour.images[1]} alt="tour2" />
                            </div>
                            <div>
                                <img src={tour.images && tour.images[2]} alt="tour3" />
                            </div>
                        </Slider>
                        </div>
                        <div onClick={() => setEditTour({ show: true, type: EDIT_TOUR_TYPE.IMAGES })} className={viewStyles["show-all"]}>
                            <p>Edit</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className={viewStyles["slider-desc"]}>
                            <div
                                className={`name d-flex ${user.userType === USER_TYPE.VENDOR ? "cursor-pointer" : ""}`}
                                onClick={() => handleTourOptionClick(EDIT_TOUR_TYPE.NAME)}
                            >
                                <h4 className="mb-0">{tour.name}</h4>
                            </div>
                            <h5
                                className={`loc d-flex ${user.userType === USER_TYPE.VENDOR ? "cursor-pointer" : ""}`}
                                onClick={() => handleTourOptionClick(EDIT_TOUR_TYPE.NAME)}
                            >
                                <img src={LocationIcon} alt="" />
                                {tour.cityData.name}
                            </h5>
                            <div
                                className={`description d-flex ${user.userType === USER_TYPE.VENDOR ? "cursor-pointer" : ""} `}
                                onClick={() => handleTourOptionClick(EDIT_TOUR_TYPE.DESCRIPTION)}
                            >
                                <h4>Description</h4>
                            </div>
                            <div className="cursor-pointer" onClick={() => handleTourOptionClick(EDIT_TOUR_TYPE.DESCRIPTION)}>
                                <div dangerouslySetInnerHTML={{ __html: mdParser.render(tour.description) }} />
                            </div>
                            <div
                                className={`description d-flex ${user.userType === USER_TYPE.VENDOR ? "cursor-pointer" : ""} `}
                                onClick={() => handleTourOptionClick(EDIT_TOUR_TYPE.CATEGORY)}
                            >
                                <h4 className="mt-0">Category:</h4>
                                <span className="ms-2">{selectedCategory.category && selectedCategory.category.name}</span>
                            </div>

                            <div
                                className={`description d-flex ${user.userType === USER_TYPE.VENDOR ? "cursor-pointer" : ""} `}
                                onClick={() => handleTourOptionClick(EDIT_TOUR_TYPE.CATEGORY)}
                            >
                                <h4>Sub Category</h4>
                            </div>
                            <div className={viewStyles["sub-cat-brrd"]}>
                                {selectedCategory.subCategory.map((item, key) => (
                                    <Fragment key={key}>
                                        <h6>{item.name}</h6>
                                        <h5>What to Expect</h5>
                                        <ul>
                                            {item.whatToExpect.map((it, index) => (
                                                <li key={index}>{it}</li>
                                            ))}
                                        </ul>
                                    </Fragment>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <EditTour {...editTour} tour={tour} closeEditTourModal={closeEditTourModal} slug={params.slug} />
        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
    specificTour: (query, loader) => dispatch(VendorTourAction.specific(query, loader)),
    citiesList: (payload, loader, userType) => dispatch(AdminCitiesAction.list(payload, loader, userType)),
    categoriesList: (query, loader) => dispatch(CommonAction.categoriesList(query, loader)),
});

export default connect(null, mapDispatchToProps)(ViewTour);
