import ADMIN_ACTIONS from "../actions";

const initialState = {
    cities: [],
    count: 0,
    areas: [],
    totalAreas: 0,
    buildings: [],
    totalBuildings: 0,
    city: {},
    area: {},
    building: {},
};

const adminLocationReducer = (state = initialState, action) => {
    if (action.type === ADMIN_ACTIONS.CITIES_LIST_SUCCESS) {
        return {
            ...state,
            cities: action.cities,
            count: action.count
        };
    }
    else if (action.type === ADMIN_ACTIONS.AREAS_LIST_SUCCESS) {
        return {
            ...state,
            areas: action.areas,
            count: action.count
        };
    }
    else if (action.type === ADMIN_ACTIONS.BUILDING_LIST_SUCCESS) {
        return {
            ...state,
            buildings: action.buildings,
            count: action.count
        };
    }
    else if (action.type === ADMIN_ACTIONS.SPECIFIC_CITY_SUCCESS) {
        return {
            ...state,
            city: action.location,
        };
    }
    else if (action.type === ADMIN_ACTIONS.SPECIFIC_AREA_SUCCESS) {
        return {
            ...state,
            area: action.location,
        };
    }
    else if (action.type === ADMIN_ACTIONS.SPECIFIC_BUILDING_SUCCESS) {
        return {
            ...state,
            building: action.location
        };
    }
    return state;
};

export default adminLocationReducer;