import * as Yup from "yup";
import moment from "moment";

import { MESSAGES } from "../constants";

const AddEditVoucherSchema = Yup.object().shape({
    title: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
    discount: Yup
        .number().required(MESSAGES.VALIDATION_ERRORS.REQUIRED)
        .min(1, MESSAGES.VALIDATION_ERRORS.NON_ZERO_DISCOUNT),
    startDate: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
    endDate: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED).test("voucher-date", function (endDate) {
        let { startDate } = this.parent;

        if (startDate && startDate.length && endDate) {
            startDate = moment(startDate);
            endDate = moment(endDate);

            if (!endDate.isAfter(startDate)) {
                return this.createError({ message: MESSAGES.VALIDATION_ERRORS.VOUCHER_END_DATE_RANGE, path: this.path })
            }
            return { path: this.path };
        }

        return { path: this.path };
    }),
    minimumSpend: Yup.number().required(MESSAGES.VALIDATION_ERRORS.REQUIRED).min(1, MESSAGES.VALIDATION_ERRORS.NON_ZERO_AMOUNT),
    frequencyPerUser: Yup.number().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
    type: Yup.number().required(MESSAGES.VALIDATION_ERRORS.REQUIRED).min(1, MESSAGES.VALIDATION_ERRORS.REQUIRED_DISCOUNT_TYPE),
    discountCurrency: Yup
        .string()
        .test("discount-currency", function (value) {
            const discountType = this.parent.type;

            if (discountType === 2) {
                return { path: this.path };
            }
            else if(value === "" || !value) {
                return this.createError({ message: MESSAGES.VALIDATION_ERRORS.REQUIRED_DISCOUNT_CURRENCY, path: this.path })
            }
            return { path: this.path };
        }),
    minimumSpendCurrency: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED_DISCOUNT_CURRENCY),
});

export default AddEditVoucherSchema;