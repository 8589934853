import React, { useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";
import { Axios } from "../../lib";

function Linecharttwo({ loader }) {
    const months = {
        0: "Jan",
        1: "Feb",
        2: "Mar",
        3: "Apr",
        4: "May",
        5: "Jun",
        6: "Jul",
        7: "Aug",
        8: "Sep",
        9: "Oct",
        10: "Nov",
        11: "Dec",
    };

    const [adminDash, setAdminDash] = useState([]);
    const [chartData, setChartData] = useState({
        series: [{ name: "Revenue", data: [] }],
        options: {
            chart: {
                type: "bar",
                animations: { enabled: true },
                toolbar: { show: false },
            },
            theme: { mode: "light" },
            xaxis: {
                categories: [],
                labels: { show: true },
            },
            yaxis: {
                min: 0,
                max: 9,
                tickAmount: 9,
                labels: { formatter: (value) => Math.floor(value) },
            },
            tooltip: { enabled: true },
        },
    });

    const adminDashboardgraph = async () => {
        try {
            const { status, data } = await Axios.get("v1/revenue/monthly/graph?type=CAR&graphYear=2023", {}, loader);
            if (status) {
                setAdminDash(data);
                const monthsData = data.map((val) => months[val.month]);
                const totalAmountData = data.map((val) => val.totalAmount);

                setChartData({
                    series: [{ name: "Revenue", data: totalAmountData }],
                    options: {
                        ...chartData.options,
                        xaxis: { ...chartData.options.xaxis, categories: monthsData },
                    },
                });
            }
        } catch (error) {
            console.error("ERROR-->", error);
        }
    };

    useEffect(() => {
        adminDashboardgraph();
    }, []);

    return (
        <div>
            <ApexCharts
                options={chartData.options}
                series={chartData.series}
                type="bar" // or "line", "area", etc.
                height={350}
            />
        </div>
    );
}

export default Linecharttwo;
