import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Switch from "react-switch";
import Swal from "sweetalert2";
import { connect, useSelector } from "react-redux";

import { Pagination } from "../../../components";
import { CATEGORY_TYPE, MESSAGES, SORTING_FIELDS } from "../../../constants";
import { APP_ROUTES } from "../../../routes";
import { AdminCategoryAction } from "../../../store/admin/thunk";
import { SortIcon } from "../../../assets/images";
import tableStyles from "../../../components/Table/style.module.css";
import commonStyles from "../../Common/styles/common.module.css";
import styles from "./style.module.css";


const Categories = ({ loader, listCategories, update }) => {
    const { categories: { categories, count, categoryLoader } } = useSelector((state) => state.admin);

    const [filterType, setFilterType] = useState(CATEGORY_TYPE.PROPERTY);
    const [pagination, setPagination] = useState({ offset: 0, limit: 10 });
    const [sorting, setSorting] = useState({ type: null, order: null });

    const Categories = async () => {
        const params = {
            type: filterType,
            ...pagination,
            showAllCategories: true,
            sort: sorting.type,
            sortType: sorting.order,
        };

        listCategories(params, loader);
    };

    const switchHandler = async (category_id, status) => {
        Swal.fire({
            title: "Are you sure?",
            text: `You want to ${status.toLowerCase()} this category`,
            confirmButtonText: "Yes",
            showCancelButton: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                let payload = { category_id, status, type: filterType };

                const params = {
                    type: filterType,
                    ...pagination,
                    showAllCategories: true,
                };

                update(payload, loader, {}, params);
            }
        });
    };

    const filter = (type) => {
        setFilterType(type);
    };

    const handleSorting = (field) => {
        if (sorting.type === field) {
            setSorting({ ...sorting, order: -1 * sorting.order });
        }
        else {
            setSorting({ type: field, order: 1 });
        }
    };

    useEffect(() => {
        Categories();
    }, [filterType, pagination, sorting]);

    return (
        <div className={commonStyles["vendor-listing"]}>
            <div className="d-flex justify-content-between align-items-center">
                <ul className="paginaion">
                    <li><Link to="/">Dashboard</Link></li>
                    <li className="active"><Link to="/vouchers">Categories</Link></li>
                </ul>
                <Link className={commonStyles["cm-btn"]} to={APP_ROUTES.ADD_CATEGORY}>
                    Add Category
                </Link>
            </div>
            <ul className={styles["filter-tab"]}>
                <li>
                    <span
                        className={filterType === CATEGORY_TYPE.PROPERTY ? "active" : "inactive"}
                        onClick={() => filter(CATEGORY_TYPE.PROPERTY)}
                    >
                        Property
                    </span>
                </li>
                <li>
                    <span
                        className={filterType === CATEGORY_TYPE.CAR ? "active" : "inactive"}
                        onClick={() => filter(CATEGORY_TYPE.CAR)}
                    >
                        Cars
                    </span>
                </li>
                <li>
                    <span
                        className={filterType === CATEGORY_TYPE.TOUR ? "active" : "inactive"}
                        onClick={() => filter(CATEGORY_TYPE.TOUR)}
                    >
                        Tour
                    </span>
                </li>
            </ul>
            <div className={tableStyles["listing-table"]}>
                <div className={tableStyles["listing-table-scrl"]}>
                    <table cellPadding={0} cellSpacing={0}>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Images</th>
                                <th className="cursor-pointer" onClick={() => handleSorting(SORTING_FIELDS.NAME)}>
                                    <span className="me-1">Name</span>
                                    {sorting.type !== SORTING_FIELDS.NAME ? <img src={SortIcon} width={15} height={15} /> : null}
                                    {sorting.type === SORTING_FIELDS.NAME ? sorting.order === 1 ? <i className="fa fa-angle-up" /> : <i className="fa fa-angle-down" /> : null}
                                </th>
                                {filterType === "TOUR" ? null : <th className="cursor-pointer" onClick={() => handleSorting(SORTING_FIELDS.VAT)}>
                                    <span className="me-1">Total commision</span>
                                    {sorting.type !== SORTING_FIELDS.VAT ? <img src={SortIcon} width={15} height={15} /> : null}
                                    {sorting.type === SORTING_FIELDS.VAT ? sorting.order === 1 ? <i className="fa fa-angle-up" /> : <i className="fa fa-angle-down" /> : null}
                                </th>}

                                <th className="cursor-pointer" onClick={() => handleSorting(SORTING_FIELDS.SUB_CATEGORIES_COUNT)}>
                                    <span className="me-1">No. of sub-categories</span>
                                    {sorting.type !== SORTING_FIELDS.SUB_CATEGORIES_COUNT ? <img src={SortIcon} width={15} height={15} /> : null}
                                    {sorting.type === SORTING_FIELDS.SUB_CATEGORIES_COUNT ? sorting.order === 1 ? <i className="fa fa-angle-up" /> : <i className="fa fa-angle-down" /> : null}
                                </th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            {categoryLoader ? <tr className="text-center"><td colSpan={5}>{MESSAGES.LOADING}</td></tr> : null}
                            {!categoryLoader && categories.map((category, index) => (
                                <tr key={category._id}>
                                    <td>{++index}</td>
                                    <td><img src={category.image} alt=""/></td>
                                    <td>{category.name}</td>
                                    {filterType === CATEGORY_TYPE.TOUR ? null : <td>{category.vat} %</td>}
                                    <td>{category.sub_categories_count}</td>
                                    <td>
                                        <Switch
                                            onColor="#e89532"
                                            onChange={() => switchHandler(category._id, category.status === "ACTIVE" ? "INACTIVE" : "ACTIVE")}
                                            checked={category.status === "ACTIVE" ? true : false}
                                        />
                                    </td>
                                    <td>
                                        <Link to={`/categories/${category._id}/subCategories`}>
                                            <i className="fas fa-eye"></i>
                                        </Link>
                                        <Link to={`/categories/${category._id}`}>
                                            <i className="fas fa-edit"></i>
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <Pagination count={count} setPagination={setPagination} />
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    listCategories: (payload, loader) => dispatch(AdminCategoryAction.listCategories(payload, loader)),
    update: (payload, loader, navigate, params) => dispatch(AdminCategoryAction.update(payload, loader, navigate, params)),
});

export default connect(null, mapDispatchToProps)(Categories);
