import VENDOR_ACTIONS from "../actions";

const initialState = {
    propertyBookings: [],
    propertyBookingCount: 0,
    carBookings: [],
    carBookingCount: 0,
    tourBookings: [],
    tourBookingCount: 0,
    specificBooking: {},
    requestLoader: false,
    specificBookingLoader: false,
};

const vendorBookingRequestReducer = (state = initialState, action) => {
    switch (action.type) {
        case VENDOR_ACTIONS.SET_PROPERTY_BOOKING_REQUESTS:
            return {
                ...state,
                propertyBookings: action.requests,
                propertyBookingCount: action.count,
            };

        case VENDOR_ACTIONS.SET_CAR_BOOKING_REQUESTS:
            return {
                ...state,
                carBookings: action.requests,
                carBookingCount: action.count,
            };

        case VENDOR_ACTIONS.SET_TOUR_BOOKING_REQUESTS:
            return {
                ...state,
                tourBookings: action.requests,
                tourBookingCount: action.count,
            };

        case VENDOR_ACTIONS.SET_SPECIFIC_BOOKING_REQUEST:
            return {
                ...state,
                specificBooking: action.specificBooking,
            };

        case VENDOR_ACTIONS.RESET_PAYMENT_STATUS: 
            return {
                ...state,
                specificBooking: {
                    ...state.specificBooking,
                    paymentButton: action.status,
                }
            };

        case VENDOR_ACTIONS.SET_REQUEST_LOADER:
            return {
                ...state,
                requestLoader: action.status,
            };

        case VENDOR_ACTIONS.REQUEST_SPECIFIC_BOOKING:
            return {
                ...state,
                specificBookingLoader: action.status,
            };

        default:
            return state;

    }
};

export default vendorBookingRequestReducer;