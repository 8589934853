import React from "react";

import { APP_ROUTES } from "../routes";
import ForgotPass from "../pages/Auth/ForgetPassword";
import Login from "../pages/Auth/Login";

const publicRoutes = (setLoader) => [
    {
        path: APP_ROUTES.LOGIN,
        element: <Login loader={setLoader} />,
        privateComponent: false,
        admin: true,
        vendor: true,
    },
    {
        path: APP_ROUTES.FORGET_PASSWORD,
        element: <ForgotPass loader={setLoader} />,
        privateComponent: false,
        admin: true,
        vendor: true,
    },
    // {
    //     path: "/",
    //     element: <Navigate replace to="/dashboard" loader={setLoader} />,
    //     privateComponent: false,
    //     admin: true,
    //     vendor: true,
    // },
];

export default publicRoutes;