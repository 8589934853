import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Switch from "react-switch";
import { connect, useSelector } from "react-redux";
import Swal from "sweetalert2";

import { AdminCategoryAction } from "../../../../store/admin/thunk";
import tableStyles from "../../../../components/Table/style.module.css";
import { MESSAGES } from "../../../../constants";

const SubCategories = ({ loader, list, update }) => {
    const params = useParams();

    const { categories: { subCategories, activeCategory, subCategoryLoader } } = useSelector((state) => state.admin);

    const getSubCategories = async () => {
        list(params.id, loader);
    };

    const switchHandler = async (subCategoryId, status) => {
        Swal.fire({
            title: "Are you sure?",
            text: `You want to ${status.toLowerCase()} this sub category`,
            confirmButtonText: "Yes",
            showCancelButton: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                let payload = { subcategory_id: subCategoryId, status };

                const apiParams = {
                    categoryId: params.id
                };

                update(payload, loader, {}, apiParams);
            }
        });
    };

    useEffect(() => {
        getSubCategories();
    }, []);

    return (
        <div className="vendor-listing">
            <h3>
                {activeCategory}
                <Link className="cm-btn" to={`/categories/${params.id}/new`}>
                    Add Sub Category
                </Link>
            </h3>

            <div className={tableStyles["listing-table"]}>
                <div className={tableStyles["listing-table-scrl"]}>
                    <table cellPadding={0} cellSpacing={0}>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Name</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>

                        <tbody>
                            {subCategoryLoader ? <tr className="text-center"><td colSpan={5}>{MESSAGES.LOADING}</td></tr> : null}
                            {!subCategoryLoader && subCategories.map((subCategory, index) => (
                                <tr key={subCategory._id}>
                                    <td>{++index}</td>
                                    <td>{subCategory.name}</td>
                                    <td>
                                        <Switch
                                            onColor="#e89532"
                                            onChange={() =>
                                                switchHandler(
                                                    subCategory._id,
                                                    subCategory.status === "ACTIVE"
                                                        ? "INACTIVE"
                                                        : "ACTIVE"
                                                )
                                            }
                                            checked={subCategory.status === "ACTIVE"}
                                        />
                                    </td>
                                    <td>
                                        <Link to={`/categories/${params.id}/subCategories/${subCategory._id}`}>
                                            <i className="fas fa-edit"></i>
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    list: (payload, loader) => dispatch(AdminCategoryAction.listSubCategories(payload, loader)),
    update: (payload, loader, navigate, params) => dispatch(AdminCategoryAction.updateSubCategory(payload, loader, navigate, params)),
});

export default connect(null, mapDispatchToProps)(SubCategories);
