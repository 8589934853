import React from "react";
import { Link } from "react-router-dom";

import { NotFoundIcon } from "../../assets/images";

function Page404() {
    return (
        <div className="col-lg-12 d-flex justify-content-center align-items-center border-0 rounded-lg auth-h100">
            <div
                className="w-100 p-3 p-md-5 card border-0 shadow-sm"
                style={{ maxWidth: "32rem" }}
            >
                <form className="row g-1 p-3 p-md-4">
                    <div className="col-12 text-center mb-4">
                        <img
                            src={NotFoundIcon}
                            className="w240 mb-4"
                            alt=""
                            style={{ width: "100%" }}
                        />
                        <h5>OOP! PAGE NOT FOUND</h5>
                        <span className="">
                            Sorry, the page you&apos;re looking for doesn;t exist. if you think
                            something is brlken, report a problem.
                        </span>
                    </div>
                    <div className="col-12 text-center">
                        <Link
                            to="/"
                            title=""
                            className="btn btn-primary btn-lg btn-block btn-light lift text-uppercase"
                        >
                            Back to Home
                        </Link>
                    </div>
                </form>
            </div>
        </div>
    );
}
export default Page404;
