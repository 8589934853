import * as Yup from "yup";

import { MESSAGES } from "../constants";

const AirbnbPropertySchema = Yup.object().shape({
  airbnbLink: Yup.string()
    .required(MESSAGES.VALIDATION_ERRORS.REQUIRED)
    .url(MESSAGES.VALIDATION_ERRORS.INVALID_URL),
});

export default AirbnbPropertySchema;
