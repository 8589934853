import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import io from "socket.io-client";
import moment from "moment";
import { useFormik } from "formik";
import { useRef } from "react";

import { SocketAction } from "../../../store/common/thunk";
import COMMON_ACTIONS from "../../../store/common/actions";
import { SOCKET_EVENTS, USER_TYPE } from "../../../constants";
import { VendorBookingRequestAction } from "../../../store/vendor/thunk";
import { AppIcon, ChatIcon, UserChatListIcon } from "../../../assets/images";
import { ENV_CONFIG, storage } from "../../../utils";
import styles from "./style.module.css";

const NewChats = ({ loader, listRooms, getSpecificBooking }) => {
    const dispatch = useDispatch();

    const { chatList, socket, user, messagesPagination, isLastMessage, chatLoader } = useSelector((state) => state.common);
    const { bookingRequests: { specificBooking } } = useSelector((state) => state.vendor);

    const [room, setRoom] = useState(false);
    const [disableChat, setDisableChat] = useState(false);
    const [roomMessages, setRoomMessages] = useState([]);
    const [openChat, setChatInformation] = useState(false);
    const messageEl = useRef(null);

    const messageBoxRef = useRef(null);
    const rootMessageBoxRef = useRef(null);

    const createRoom = async (room) => {
        const payload = {
            type: room.categoryType,
            bookingId: room.roomId,
        };

        dispatch({ type: COMMON_ACTIONS.RESET_MESSAGES_PAGINATION });

        getSpecificBooking(payload);

        setRoom(room);
        socket.emit(SOCKET_EVENTS.JOIN_ROOM, { roomId: room.roomId });
    };

    const newChatFormik = useFormik({
        initialValues: { message: "" },
        onSubmit: ({ message }, { resetForm }) => {
            if (message && message.length) {
                const chat = {
                    roomId: room.roomId,
                    message: message,
                    messageType: 1,
                };

                socket.emit(SOCKET_EVENTS.SEND_MESSAGE, chat);
                resetForm();
            }
        },
    });

    const getMessages = async () => {
        const params = {
            roomId: room.roomId,
            limit: messagesPagination.limit,
            offset: messagesPagination.offset,
        };

        const response = await SocketAction.getSpecificRoomMessages(params);

        let finalData = [...roomMessages, ...response.data];

        if (response.data.length && roomMessages.length) {
            if (response.data[0].roomId !== roomMessages[0].roomId) {
                finalData = response.data;
            }
        }

        const isDisable = typeof (response.disableChat) === "boolean" ? response.disableChat : false;

        setRoomMessages(finalData);
        setDisableChat(isDisable);

        dispatch({ type: COMMON_ACTIONS.SET_LAST_MESSAGE, status: response.data.length < messagesPagination.limit });
        socket.emit(SOCKET_EVENTS.READ_MESSAGE, params);
    };

    useEffect(() => {
        if (room && !isLastMessage) {
            getMessages();
        }
    }, [room, messagesPagination])


    useEffect(() => {
        if (messageBoxRef.current && rootMessageBoxRef.current && room._id) {
            setTimeout(() => {
                const observer = new IntersectionObserver((entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting && !isLastMessage) {
                            dispatch({ type: COMMON_ACTIONS.SET_MESSAGES_PAGINATION });
                        }
                    });
                }, { rootMargin: "0px", threshold: 0.1, root: rootMessageBoxRef.current });

                observer.observe(messageBoxRef.current);

                return () => observer.disconnect();
            }, 2000)
        }
    }, [messageBoxRef, room, rootMessageBoxRef, isLastMessage]);

    useEffect(() => {
        socket.on(SOCKET_EVENTS.MESSAGE_RECEIVED, function (data) {
            if (roomMessages.length) {
                const finalData = [data, ...roomMessages]

                setRoomMessages(finalData);

                listRooms(loader);
            }
        })
    }, [roomMessages]);

    useEffect(() => {

        listRooms(loader);

        const getToken = async () => {
            const token = storage.getToken();
            const socket = io(ENV_CONFIG.SOCKET_URL, { auth: { token } });

            dispatch({ type: COMMON_ACTIONS.SET_USER_SOCKET, socket });
        };

        if (socket && !socket.connected) {
            getToken();
        }

        if (roomMessages && roomMessages.length && messageEl && messageEl.current) {
            // messageEl.current?.scrollIntoView({ behavior: 'smooth', block: "end", inline: "nearest" });
        }
    }, []);

    if (!chatLoader && chatList.length === 0) {
        return <div className="d-flex flex-column align-items-center justify-content-center h-75">
            <img src={UserChatListIcon} alt="userlist" className={styles["chat-icon"]} />
            <p>Chat is currently unavailable. To start a conversation, please redirect to the Booking requests page.</p>
        </div>
    }

    return (
        <div className={styles["main-outer-chat"]}>
            <div className="row">
                <div className="col-md-12">
                    <div id={styles["chat3"]} style={{ borderRadius: "15px" }}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className={styles["left-chat"]}>
                                        <div className="rounded mb-3">
                                            <input
                                                className={`rounded ${styles["form-control"]}`}
                                                placeholder="Search"
                                                type="search"
                                            />
                                            <span
                                                className="input-group-text border-0"
                                                id="search-addon"
                                            >
                                                <i className="fas fa-search"></i>
                                            </span>
                                        </div>

                                        <div style={{ position: "relative", height: "calc(100vh - 270px)", overflow: "auto" }}>

                                            <div className="mb-0">
                                                {
                                                    chatList.length === 0 ?
                                                        <div className="d-flex flex-column align-items-center justify-content-center h-100">
                                                            <img alt="userlist" src={UserChatListIcon} className="chat-icon" />
                                                            <p>Currently, there is no chat available.</p>
                                                        </div>
                                                        :
                                                        null
                                                }
                                                {
                                                    chatList.map((item) => (
                                                        <li key={item._id} className={`cursor-pointer p-2 border-bottom ${room._id === item._id ? "bg-white" : ""}`} onClick={() => createRoom(item)}>
                                                            <div className="d-flex justify-content-between">
                                                                <div className="d-flex flex-row">
                                                                    <div>
                                                                        <img
                                                                            src={
                                                                                user.userType === USER_TYPE.ADMIN ?
                                                                                    AppIcon :
                                                                                    item.image ? item.image :
                                                                                        "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"
                                                                            }
                                                                            alt="avatar"
                                                                            className="d-flex align-self-center me-3 rounded-circle"
                                                                            width="60"
                                                                            height={60}
                                                                        />
                                                                        <span className={`badge bg-success ${styles["badge-dot"]}`}></span>
                                                                    </div>
                                                                    <div className="pt-1">
                                                                        {
                                                                            user.userType === USER_TYPE.ADMIN ?
                                                                                <p className={styles["fw-bold"]}>
                                                                                    {item.userFirstName} {item.userLastName} - {item.vendorFirstName} {item.vendorLastName}
                                                                                </p> : <p className="fw-bold mb-0">{item.userFirstName} {item.userLastName}</p>}
                                                                        <p className={` ${styles["text-muted"]} ${styles["small"]} `}>
                                                                            {item.message}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="pt-1">
                                                                    <p className="small text-muted mb-1">{moment(item.messageDate).fromNow()}</p>
                                                                    {
                                                                        item.readBy ?
                                                                            <span className="badge bg-danger rounded-pill float-end">

                                                                            </span>
                                                                            :
                                                                            null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    room ?
                                        <div className="col-md-8">
                                            <div className={styles["chat-otr"]}>
                                                <div className={styles["caht-head"]}>
                                                    <div className={styles["left-chat-hd"]}>
                                                        <span>
                                                            <img
                                                                src={
                                                                    user.userType === USER_TYPE.ADMIN ? AppIcon
                                                                        : room.image ?
                                                                            room.image : "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"
                                                                }
                                                                alt="avatar"
                                                                className="d-flex rounded-circle align-self-center me-3"
                                                                width="60"
                                                                height={60}
                                                            />
                                                        </span>
                                                        <div className={styles.details}>
                                                            {
                                                                user.userType === USER_TYPE.ADMIN ?
                                                                    <h4>{room.userFirstName} {room.userLastName} - {room.vendorFirstName} {room.vendorLastName}</h4>
                                                                    :
                                                                    <h4>{room.userFirstName} {room.userLastName}</h4>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="right-chat=hd" onClick={() => setChatInformation(!openChat)}>
                                                        <label>
                                                            <i className="fas fa-info-circle"></i>
                                                        </label>
                                                    </div>

                                                </div>

                                                <div ref={rootMessageBoxRef}>
                                                    <div id="scrollableDiv" className={`pt-3 pe-3 ${styles["message-box"]}`}>

                                                        {roomMessages.map((item, index) => (
                                                            <div
                                                                key={index}
                                                                className={`d-flex flex-row ${user.userType === item.senderType.toUpperCase() ? "justify-content-end" : "justify-content-start"}`}>
                                                                {
                                                                    user.userType !== item.senderType.toUpperCase() ?
                                                                        <img
                                                                            src={
                                                                                item.senderType && item.senderType.toUpperCase() === USER_TYPE.ADMIN ?
                                                                                    AppIcon : item.senderId &&
                                                                                        item.senderId.image ?
                                                                                        item.senderId.image
                                                                                        :
                                                                                        "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava6-bg.webp"}
                                                                            alt="avatar 1"
                                                                            style={{ width: "45px", height: "45px" }}
                                                                            className="rounded-circle"
                                                                        />
                                                                        :
                                                                        null
                                                                }
                                                                <div>
                                                                    <p
                                                                        className={`small p-2 ms-3 mb-1 rounded-3 ${user.userType === item.senderType.toUpperCase() ? "text-white bg-primary" : ""}`}
                                                                        style={{ backgroundColor: "#f5f6f7" }}
                                                                    >
                                                                        {item.message}
                                                                    </p>
                                                                    <p className={`small ms-3 mb-3 rounded-3 text-muted ${user.userType === item.senderType.toUpperCase() ? "float-end" : ""}`}>
                                                                        {moment(item.createdAt).format("hh:mm A | MMM DD")}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <div ref={messageBoxRef}></div>
                                                    </div>
                                                </div>

                                                {
                                                    !disableChat ?
                                                        <div className={`d-flex justify-content-start align-items-center pe-3 pt-3 mt-2 ${styles["text-muted"]}`}>
                                                            <img
                                                                src={
                                                                    user.userType === USER_TYPE.ADMIN ?
                                                                        AppIcon : room.image ?
                                                                            room.image : "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"
                                                                }
                                                                alt="avatar 3"
                                                                className={styles["senderImage"]}
                                                            />
                                                            <input
                                                                type="text"
                                                                className={`form-control form-control-lg ${styles.message}`}
                                                                placeholder="Type message"
                                                                onChange={newChatFormik.handleChange}
                                                                name="message"
                                                                value={newChatFormik.values.message}
                                                                onKeyUp={(event) => {
                                                                    if (event.keyCode === 13) {
                                                                        newChatFormik.submitForm(event);
                                                                    }
                                                                }}
                                                            />
                                                            <a className="ms-1 text-muted" href="#!">
                                                                <i className="fas fa-paperclip"></i>
                                                            </a>
                                                            <a className="ms-3 text-muted" href="#!">
                                                                <i className="fas fa-smile"></i>
                                                            </a>
                                                            <i className="fas fa-paper-plane ms-3" onClick={(event) => newChatFormik.submitForm(event)}></i>
                                                        </div> : <p>Chat is disabled</p>
                                                }
                                            </div>
                                        </div>
                                        :
                                        <div className="col-md-8 d-flex flex-column justify-content-center align-items-center">
                                            <img alt="chat" src={ChatIcon} width={120} height={120} />
                                            <p className="chat-information">Click on the user on left side to start a chat</p>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal ${!openChat ? "d-none" : "fade"}`} id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Reservation</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className={styles["rese-deails"]}>
                                <ul>
                                    <li>
                                        <label>Guest name:</label> {specificBooking?.user_id?.firstName} {specificBooking?.user_id?.lastName}</li>
                                    <li>
                                        <label>Check In:</label>{moment(specificBooking.checkIn).format("DD-MM-YYYY")}</li>
                                    <li>
                                        <label>Check Out:</label>{moment(specificBooking.checkOut).format("DD-MM-YYYY")}</li>
                                    <li>
                                        <label>Total Price:</label>{specificBooking.currencySymbol} {specificBooking?.overAllAmountToPaid?.toFixed(2)}
                                    </li>

                                    {
                                        specificBooking?.property_id && specificBooking.property_id._id ?
                                            <li>
                                                <label>Property Name:</label>
                                                {specificBooking.property_id.name} ({specificBooking.property_id.unitNumber})
                                            </li>
                                            :
                                            null
                                    }
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div >
    );
};

const mapDispatchToProps = (dispatch) => ({
    listRooms: (loader) => dispatch(SocketAction.getUserChatRooms(loader)),
    getSpecificBooking: (query, loader) => dispatch(VendorBookingRequestAction.specificBookingRequest(query, loader)),
});

export default connect(null, mapDispatchToProps)(NewChats);
