import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Switch from "react-switch";
import { connect, useSelector } from "react-redux";
import { debounce } from "lodash";

import { Pagination } from "../../../components";
import { AdminVendorAction } from "../../../store/admin/thunk";
import { SORTING_FIELDS } from "../../../constants";
import { FilterIcon, SortIcon, SearchIcon } from "../../../assets/images";
import tableStyles from "../../../components/Table/style.module.css";
import commonStyles from "../../Common/styles/common.module.css";

const Vendor = ({ loader, getVendorList, blockVendor }) => {
    const { vendor: { vendorList, count, loading } } = useSelector((state) => state.admin);

    const [pagination, setPagination] = useState({ offset: 0, limit: 10 });
    const [textSearch, setTextSearch] = useState("");
    const [isBlocked, setBlocked] = useState("");
    const [sorting, setSorting] = useState({ type: null, order: null });

    const getVendors = async (offset = pagination.offset, search = textSearch) => {
        let query = {
            limit: pagination.limit,
            offset,
            sort: sorting.type,
            sortType: sorting.order,
        };

        if (search && search.length) query.searchString = search;
        if (isBlocked) query.isBlocked = parseInt(isBlocked, 10) === 1;

        getVendorList(query, loader);
    };

    const handleSorting = (field) => {
        if (sorting.type === field) {
            setSorting({ ...sorting, order: -1 * sorting.order });
        }
        else {
            setSorting({ type: field, order: 1 });
        }
    };

    const switchHandler = async (vendorId, isBlocked) => {
        const payload = {
            vendorId: vendorId, isBlocked
        };
        blockVendor(pagination, payload, loader);
    };

    const handleIsBlockedChange = (e) => {
        setBlocked(e.target.value.length ? e.target.value : null)
        setPagination({ offset: 0, limit: pagination.limit });
    };

    const clearResult = () => {
        setBlocked("");
        setTextSearch("");

        getVendors(0, "");
    };

    const debounceVendorsList = debounce(() => getVendors(0), 500);

    useEffect(() => {
        getVendors();
    }, [pagination, sorting]);

    return (
        <div className={commonStyles["vendor-listing"]}>
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex justify-content-between align-items-center">
                    <ul className="paginaion">
                        <li><Link to="/">Dashboard</Link></li>
                        <li className="active"><Link to="/vendors">Vendors</Link></li>
                    </ul>
                </div>
            </div>

            <div className={commonStyles["filter-main"]}>
                <div className={commonStyles["filter-left"]}>
                    <div className="form-group">
                        <span className={commonStyles["filer-iic"]}><img src={FilterIcon} alt="Filter" /></span>
                        <select name="categories" onChange={handleIsBlockedChange} value={isBlocked}>
                            <option value="">Filter by Status</option>
                            <option key="1" value="1">Blocked</option>
                            <option key="0" value="0">Active</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <span className={tableStyles["search-c"]}>
                            <img src={SearchIcon} alt="search" />
                        </span>
                        <input
                            type='text'
                            placeholder='Search by name'
                            onChange={(e) => setTextSearch(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") debounceVendorsList();
                            }}
                            className="mx-2"
                            value={textSearch}
                        />
                        {(textSearch.length || isBlocked) ?
                            <button className={tableStyles["search-button"]} onClick={clearResult}>
                                <i className="fa fa-eraser" />
                            </button>
                            :
                            null
                        }
                    </div>
                </div>
            </div>

            <div className={tableStyles["listing-table"]}>
                <div className={tableStyles["listing-table-scrl"]}>
                    <table cellPadding={0} cellSpacing={0}>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Image</th>
                                <th className="cursor-pointer" onClick={() => handleSorting(SORTING_FIELDS.FIRST_NAME)}>
                                    <span className="me-1">Name</span>
                                    {sorting.type !== SORTING_FIELDS.FIRST_NAME ? <img src={SortIcon} width={15} height={15} /> : null}
                                    {sorting.type === SORTING_FIELDS.FIRST_NAME ? sorting.order === 1 ? <i className="fa fa-angle-up" /> : <i className="fa fa-angle-down" /> : null}
                                </th>
                                <th className="cursor-pointer" onClick={() => handleSorting(SORTING_FIELDS.EMAIL)}>
                                    <span className="me-1">Email</span>
                                    {sorting.type !== SORTING_FIELDS.EMAIL ? <img src={SortIcon} width={15} height={15} /> : null}
                                    {sorting.type === SORTING_FIELDS.EMAIL ? sorting.order === 1 ? <i className="fa fa-angle-up" /> : <i className="fa fa-angle-down" /> : null}
                                </th>
                                <th className="cursor-pointer" onClick={() => handleSorting(SORTING_FIELDS.NO_OF_PROPERTIES)}>
                                    <span className="me-1">No. of Properties</span>
                                    {sorting.type !== SORTING_FIELDS.NO_OF_PROPERTIES ? <img src={SortIcon} width={15} height={15} /> : null}
                                    {sorting.type === SORTING_FIELDS.NO_OF_PROPERTIES ? sorting.order === 1 ? <i className="fa fa-angle-up" /> : <i className="fa fa-angle-down" /> : null}
                                </th>
                                <th className="cursor-pointer" onClick={() => handleSorting(SORTING_FIELDS.NO_OF_CARS)}>
                                    <span className="me-1">No. of Cars</span>
                                    {sorting.type !== SORTING_FIELDS.NO_OF_CARS ? <img src={SortIcon} width={15} height={15} /> : null}
                                    {sorting.type === SORTING_FIELDS.NO_OF_CARS ? sorting.order === 1 ? <i className="fa fa-angle-up" /> : <i className="fa fa-angle-down" /> : null}
                                </th>
                                <th>Status</th>
                                <th>Blocked</th>
                                <th>Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            {loading ? <tr className={tableStyles.noresult}><td colSpan={9}>Loading</td></tr> : null}
                            {!loading && vendorList.map((vendor, index) => (
                                <tr key={vendor._id}>
                                    <td>{pagination.offset + index + 1}</td>
                                    <td>
                                        <img src={vendor.image} alt="vendor" />
                                    </td>
                                    <td>
                                        {vendor.firstName} {vendor.lastName}
                                    </td>
                                    <td>{vendor.email}</td>
                                    <td>{vendor.no_of_properties}</td>
                                    <td>{vendor.no_of_cars}</td>
                                    <td
                                        className={
                                            vendor.status === "ACTIVE" ? "activebr" : "inactive"
                                        }
                                    >
                                        <span>
                                            {vendor.status === "ACTIVE" ? "Active" : "Inactive"}
                                        </span>
                                    </td>
                                    <td>
                                        <Switch
                                            onColor="#e89532"
                                            onChange={() =>
                                                switchHandler(vendor._id, !vendor.isBlocked)
                                            }
                                            checked={vendor.isBlocked}
                                        />
                                    </td>
                                    <td>
                                        <span>
                                            <Link to={`/vendor/${vendor._id}`}>
                                                <i
                                                    className="fas fa-eye"

                                                ></i>
                                            </Link>
                                        </span>
                                    </td>
                                </tr>
                            ))}
                            {!loading && vendorList.length === 0 ? <tr className={tableStyles.noresult}><td colSpan={9}>No vendor found</td></tr> : null}
                        </tbody>
                    </table>
                </div>
            </div>
            <Pagination count={count} setPagination={setPagination} />
        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
    getVendorList: (query, loader) => dispatch(AdminVendorAction.list(query, loader)),
    blockVendor: (query, payload, loader) => dispatch(AdminVendorAction.update(query, payload, loader))
});

export default connect(null, mapDispatchToProps)(Vendor);
