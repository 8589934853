import ADMIN_ACTIONS from "../actions";

const initialState = {
    vouchers: [],
    count: 0,
    createVoucherSuccess: false,
    voucher: {},
    claimedVouchersList: [],
    clamiedVouchersCount: 0
};

const adminVouchersReducer = (state = initialState, action) =>  {
    if(action.type === ADMIN_ACTIONS.VOUCHERS_LIST_SUCCESS) {
        return {
            ...state,
            vouchers: action.vouchers,
            count: action.count,
            voucher: {},
        };
    }
    else if(action.type === ADMIN_ACTIONS.CREATE_UPDATE_VOUCHER_SUCCESS) {
        return {
            ...state,
            createVoucherSuccess: action.status,
        };
    }
    else if(action.type === ADMIN_ACTIONS.SET_SPECIFIC_VOUCHER) {
        return {
            ...state,
            voucher: action.voucher,
        };
    }
    else if(action.type === ADMIN_ACTIONS.CLAIMED_VOUCHER_USERS_LIST) {
        return {
            ...state,
            claimedVouchersList: action.users,
            clamiedVouchersCount: action.count,
        };
    }

    return state;
};

export default adminVouchersReducer;