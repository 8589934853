import { Axios } from "../../../../lib";

export const getImageCategories = async(loader) => {
    return await Axios.get(`v2/imageCategories`, {}, loader);
}

export const getSingleImageCategories = async(params) => {
    return await Axios.get(`v2/imageCategories/${params}`);
}

export const createImageCat = async(params) => {
    return await Axios.post(`v2/imageCategories`, params);
}

export const updateImageCat = async(params) => {
    return await Axios.patch(`v2/imageCategories/${params?.id}`, {name : params?.name} );
}


