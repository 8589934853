import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import { connect, useSelector } from "react-redux";
import moment from "moment";
import CurrencyCodes from "currency-codes";

import { AdminVoucherSchema } from "../../../../schemas";
import { AdminVoucherAction } from "../../../../store/admin/thunk";
import Button from "../../../../components/Button";
import styles from "./style.module.css";
import promotionStyles from '../../Promotions/Add/style.module.css';
import { Toaster } from "../../../../components/Toaster";
import { MESSAGES, TOAST_TYPE } from "../../../../constants";

const AddEditVoucher = (props) => {
    const navigate = useNavigate();
    const params = useParams();

    const [editVoucher, setEdit] = useState(false);
    const [isDisabled, setDisabledForm] = useState(false);

    const { vouchers: { voucher } } = useSelector((state) => state.admin);

    const addEditVoucherFormik = useFormik({
        initialValues: {
            title: "",
            discount: 0,
            startDate: "",
            endDate: "",
            frequencyPerUser: "",
            minimumSpend: "",
            minimumSpendCurrency: "",
            discountCurrency: "",
            type: 0,
        },
        enableReinitialize: true,
        validationSchema: AdminVoucherSchema,
        onSubmit: (values) => {
            if (parseInt(values.type, 10) === 2) {
                delete values.discountCurrency;
            }


            if (!editVoucher) {
                // values.minimumSpendCurrency = values.minimumSpendCurrency;

                props.create(values, props.loader, navigate);
            }
            else {
                values.voucherId = params.id;
                values.discountCurrency = "AED";
                values.minimumSpendCurrency = "AED";
                props.update(values, props.loader, navigate);
            }
        },
    });

    useEffect(() => {
        if (params.id) {
            props.specific(params.id, props.loader);
            setEdit(true);
        }
    }, []);

    useEffect(() => {
        if (Object.keys(voucher).length) {
            if (moment().isAfter(moment(voucher.startDate)) && moment().isBefore(moment(voucher.endDate))) {
                Toaster(TOAST_TYPE.ERROR, MESSAGES.VOUCHER.EDIT.STARTED);
                setDisabledForm(true);
            }
            else if (moment().isAfter(moment(voucher.endDate))) {
                Toaster(TOAST_TYPE.ERROR, MESSAGES.VOUCHER.EDIT.ENDED);
                setDisabledForm(true);
            }
            addEditVoucherFormik.setValues({
                title: voucher.title,
                discount: voucher.discount,
                type: voucher.type,
                startDate: new Date(voucher.startDate),
                endDate: new Date(voucher.endDate),
                frequencyPerUser: voucher.frequencyPerUser,
                minimumSpend: voucher.minimumSpend,
                discountCurrency: voucher.discountCurrency,
                minimumSpendCurrency: voucher.minimumSpendCurrency,
            });
        }
    }, [voucher])

    return (
        <div className={`container bg-white p-4 ${styles.addPromotionCard}`}>
            <p className={styles.heading}>{editVoucher ? "Edit" : "Add"} Voucher</p>
            <div className="row">
                <form className={promotionStyles['promo-form']}>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className={`w-100 ${styles.label}`}>Title</label>
                            <input
                                type="text"
                                name="title"
                                disabled={isDisabled}
                                onChange={addEditVoucherFormik.handleChange}
                                onBlur={addEditVoucherFormik.handleBlur}
                                value={addEditVoucherFormik.values.title}
                            />
                            {addEditVoucherFormik.touched.title && addEditVoucherFormik.errors.title ? <p className="error">{addEditVoucherFormik.errors.title}</p> : null}
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="form-group">
                            <label className={`w-100 ${styles.label}`}>Discount</label>
                            <p className={styles.subLabel}>How much of a discount do you want to give?</p>
                            <input
                                type="number"
                                name="discount"
                                disabled={isDisabled}
                                onChange={addEditVoucherFormik.handleChange}
                                value={addEditVoucherFormik.values.discount}
                            />
                            {addEditVoucherFormik.touched.discount && addEditVoucherFormik.errors.discount ? <p className="error">{addEditVoucherFormik.errors.discount}</p> : null}
                        </div>
                    </div>
                    <div className="row">
                        <div className={parseInt(addEditVoucherFormik.values.type) === 1 ? "col-md-4" : "col-md-6"}>
                            <div className="form-group">
                                <label className={`w-100 ${styles.label}`}>Discount Type</label>
                                <select
                                    name="type"
                                    className="form-control"
                                    value={addEditVoucherFormik.values.type}
                                    onChange={addEditVoucherFormik.handleChange}
                                    disabled={isDisabled}
                                >
                                    <option value={0}>Select</option>
                                    <option value={1}>Fixed</option>
                                    <option value={2}>Percentage</option>
                                </select>

                                {addEditVoucherFormik.touched.type && addEditVoucherFormik.errors.type ? <p className="error">{addEditVoucherFormik.errors.type}</p> : null}
                            </div>
                        </div>
                        {parseInt(addEditVoucherFormik.values.type) === 1 ?
                            <div className={parseInt(addEditVoucherFormik.values.type) === 1 ? "col-md-4" : "col-md-6"}>
                                <div className="form-group">
                                    <label className={`w-100 ${styles.label}`}>Discount Currency</label>
                                    <select
                                        name="discountCurrency"
                                        className="form-control"
                                        value={addEditVoucherFormik.values.discountCurrency}
                                        onChange={addEditVoucherFormik.handleChange}
                                        disabled={isDisabled}
                                    >
                                        <option value=''>Select</option>
                                        {CurrencyCodes.codes().map((item, index) => (
                                            <option key={index} value={item}>{item}</option>
                                        ))}
                                    </select>

                                    {
                                        addEditVoucherFormik.touched.discountCurrency && addEditVoucherFormik.errors.discountCurrency ?
                                            <p className="error">{addEditVoucherFormik.errors.discountCurrency}</p>
                                            :
                                            null
                                    }
                                </div>
                            </div> : null}
                        <div className={parseInt(addEditVoucherFormik.values.type) === 1 ? "col-md-4" : "col-md-6"}>
                            <div className="form-group">
                                <label className={`w-100 ${styles.label}`}>Minimum Spend Currency</label>
                                <select
                                    name="minimumSpendCurrency"
                                    className="form-control"
                                    value={addEditVoucherFormik.values.minimumSpendCurrency}
                                    onChange={addEditVoucherFormik.handleChange}
                                    disabled={isDisabled}
                                >
                                    <option value=''>Select</option>
                                    {CurrencyCodes.codes().map((item, index) => (
                                        <option key={index} value={item}>{item}</option>
                                    ))}
                                </select>

                                {
                                    addEditVoucherFormik.touched.minimumSpendCurrency && addEditVoucherFormik.errors.minimumSpendCurrency ?
                                        <p className="error">{addEditVoucherFormik.errors.minimumSpendCurrency}</p>
                                        :
                                        null
                                }
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="form-group">
                                <label className={`w-100 ${styles.label}`}>Start Date</label>
                                <DatePicker
                                    name="startDate"
                                    onChange={(date) => addEditVoucherFormik.setFieldValue("startDate", new Date(date))}
                                    selected={addEditVoucherFormik.values.startDate}
                                    minDate={new Date()}
                                    disabled={isDisabled}
                                    dateFormat="dd-MMM-yyyy"
                                />

                                {addEditVoucherFormik.touched.startDate && addEditVoucherFormik.errors.startDate ? <p className="error">{addEditVoucherFormik.errors.startDate}</p> : null}
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="form-group">
                                <label className={`w-100 ${styles.label}`}>End Date</label>
                                <DatePicker
                                    name="endDate"
                                    onChange={(date) => addEditVoucherFormik.setFieldValue("endDate", new Date(date))}
                                    selected={addEditVoucherFormik.values.endDate}
                                    minDate={new Date()}
                                    disabled={isDisabled}
                                    dateFormat="dd-MMM-yyyy"
                                />
                                {addEditVoucherFormik.touched.endDate && addEditVoucherFormik.errors.endDate ? <p className="error">{addEditVoucherFormik.errors.endDate}</p> : null}
                            </div>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-md-6">
                            <label className={`w-100 ${styles.label}`}>Frequency per user</label>
                            <p className={styles.subLabel}>How much time can a single user use this voucher for?</p>
                            <input
                                type="number"
                                name="frequencyPerUser"
                                onChange={addEditVoucherFormik.handleChange}
                                value={addEditVoucherFormik.values.frequencyPerUser}
                                disabled={isDisabled}
                            />
                            {
                                addEditVoucherFormik.touched.frequencyPerUser && addEditVoucherFormik.errors.frequencyPerUser ?
                                    <p className="error">{addEditVoucherFormik.errors.frequencyPerUser}</p>
                                    :
                                    null
                            }
                        </div>
                        <div className="col-md-6">
                            <label className={`w-100 ${styles.label}`}>Minimum Amount</label>
                            <p className={styles.subLabel}>Minimum amount required to avail this voucher</p>
                            <input
                                type="number"
                                name="minimumSpend"
                                onChange={addEditVoucherFormik.handleChange}
                                value={addEditVoucherFormik.values.minimumSpend}
                                disabled={isDisabled}
                            />
                            {addEditVoucherFormik.touched.minimumSpend && addEditVoucherFormik.errors.minimumSpend ? <p className="error">{addEditVoucherFormik.errors.minimumSpend}</p> : null}
                        </div>
                    </div>
                    <Button type="button" handleClick={addEditVoucherFormik.submitForm} title={editVoucher ? "Update" : "Create"} />
                </form>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    create: (payload, loader, navigate) => dispatch(AdminVoucherAction.create(payload, loader, navigate)),
    update: (payload, loader, navigate) => dispatch(AdminVoucherAction.update(payload, loader, navigate)),
    specific: (voucherId, loader) => dispatch(AdminVoucherAction.specific(voucherId, loader)),
});

export default connect(null, mapDispatchToProps)(AddEditVoucher);