import { Toaster } from "../../../components";
import { Axios } from "../../../lib";
import { BOOKING_REQUESTS_TYPE } from "../../../constants/promotions";
import { API_ENDPOINTS, BOOKING_MEMBER_TYPES, MESSAGES, TOAST_TYPE } from "../../../constants";
import VENDOR_ACTIONS from "../actions";

const bookingRequests = (params, loader) => async (dispatch) => {
    try {
        const { data: { bookingRequests, count } } = await Axios.get(API_ENDPOINTS.LIST_BOOKING_REQUESTS, params, loader);

        if (params.bookingType === BOOKING_REQUESTS_TYPE.PROPERTY_BOOKING) {
            dispatch({ type: VENDOR_ACTIONS.SET_PROPERTY_BOOKING_REQUESTS, requests: bookingRequests, count: count });
        }
        else if (params.bookingType === BOOKING_REQUESTS_TYPE.CAR_BOOKING) {
            dispatch({ type: VENDOR_ACTIONS.SET_CAR_BOOKING_REQUESTS, requests: bookingRequests, count: count });
        }
        else {
            dispatch({ type: VENDOR_ACTIONS.SET_TOUR_BOOKING_REQUESTS, requests: bookingRequests, count: count });
        }
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.SOMETHING_WENT_WRONG);
    }
};

const specificBookingRequest = (params, loader) => async (dispatch) => {
    try {
        dispatch({ type: VENDOR_ACTIONS.REQUEST_SPECIFIC_BOOKING, status: true });
        const { data } = await Axios.get(API_ENDPOINTS.SPECIFIC_BOOKING_REQUEST, params, loader);

        const members = data.members.sort((a, b) => a.name - b.name);

        const isAdult = members.find(item => item.name === BOOKING_MEMBER_TYPES.ADULTS);
        const isChildren = members.find(item => item.name === BOOKING_MEMBER_TYPES.CHILDREN);
        const isInfant = members.find(item => item.name === BOOKING_MEMBER_TYPES.INFANTS);

        let memberString = `${isAdult ? isAdult.count : 0} 
            Adult ${isChildren && isChildren.count ? `, ${isChildren.count} children` : ""}  
            ${isInfant && isInfant.count ? `, ${isInfant.count} infant` : ""}`;

        data.memberString = memberString;

        dispatch({ type: VENDOR_ACTIONS.SET_SPECIFIC_BOOKING_REQUEST, specificBooking: data });
        dispatch({ type: VENDOR_ACTIONS.REQUEST_SPECIFIC_BOOKING, status: false });
    }
    catch (err) {
        dispatch({ type: VENDOR_ACTIONS.REQUEST_SPECIFIC_BOOKING, status: false });
    }
};


const propertyBookingRequests = (params, loader) => async (dispatch) => {
    try {
        dispatch({ type: VENDOR_ACTIONS.SET_REQUEST_LOADER, status: true });
        const { data: { data, count } } = await Axios.get(API_ENDPOINTS.LIST_PROPERTY_RESERVATIONS, params, loader);

        for (const item of data) {
            if (item.members && item.members.length) {
                const members = item.members.sort((a, b) => a.name - b.name);

                const isAdult = members.find(item => item.name === BOOKING_MEMBER_TYPES.ADULTS);
                const isChildren = members.find(item => item.name === BOOKING_MEMBER_TYPES.CHILDREN);
                const isInfant = members.find(item => item.name === BOOKING_MEMBER_TYPES.INFANTS);

                let memberString = `${isAdult ? isAdult.count : 0} 
                    Adult ${isChildren && isChildren.count > 0 ? `, ${isChildren.count} children, ` : ""} 
                    ${isInfant && isInfant.count > 0 ? `${isInfant.count} infant` : ""}`;

                item.memberString = memberString;
            }
        }

        dispatch({ type: VENDOR_ACTIONS.SET_PROPERTY_BOOKING_REQUESTS, requests: data, count: count });
        dispatch({ type: VENDOR_ACTIONS.SET_REQUEST_LOADER, status: false });
    }
    catch (err) {
        dispatch({ type: VENDOR_ACTIONS.SET_REQUEST_LOADER, status: false });
    }
};

const carBookingRequests = (params, loader) => async (dispatch) => {
    try {
        dispatch({ type: VENDOR_ACTIONS.SET_REQUEST_LOADER, status: true });
        const { data: { data, count } } = await Axios.get(API_ENDPOINTS.LIST_CAR_RESERVATIONS, params, loader);

        for (const item of data) {
            if (item.members && item.members.length) {
                const members = item.members.sort((a, b) => a.name - b.name);

                const isAdult = members.find(item => item.name === BOOKING_MEMBER_TYPES.ADULTS);
                const isChildren = members.find(item => item.name === BOOKING_MEMBER_TYPES.CHILDREN);
                const isInfant = members.find(item => item.name === BOOKING_MEMBER_TYPES.INFANTS);

                let memberString = `${isAdult ? isAdult.count : 0} Adult, ${isChildren ? isChildren.count : 0} children, ${isInfant ? isInfant.count : 0} infant`;
                item.memberString = memberString;
            }
        }

        dispatch({ type: VENDOR_ACTIONS.SET_CAR_BOOKING_REQUESTS, requests: data, count: count });
        dispatch({ type: VENDOR_ACTIONS.SET_REQUEST_LOADER, status: false });
    }
    catch (err) {
        dispatch({ type: VENDOR_ACTIONS.SET_REQUEST_LOADER, status: false });
    }

};


const acceptBooking = (payload, loader) => async (dispatch) => {
    try {
        await Axios.patch(API_ENDPOINTS.ACCEPT_BOOKINGS, payload, loader);
        Toaster(TOAST_TYPE.SUCCESS, MESSAGES.PAYOUTS.PAYMENT_ACCEPTED);

        dispatch({ type: VENDOR_ACTIONS.RESET_PAYMENT_STATUS, status: false });
    }
    catch (err) {
        Toaster(TOAST_TYPE.SUCCESS, MESSAGES.PAYOUTS.PAYMENT_ACCEPT_ERROR);
    }
};

export {
    acceptBooking,
    bookingRequests,
    propertyBookingRequests,
    specificBookingRequest,
    carBookingRequests,
};