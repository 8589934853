import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Switch from "react-switch";
import Swal from "sweetalert2";
import { connect, useSelector } from "react-redux";

import { Pagination } from "../../../components";
import { AdminAreasAction } from "../../../store/admin/thunk";
import { SORTING_FIELDS } from "../../../constants";
import { SortIcon } from "../../../assets/images";
import tableStyles from "../../../components/Table/style.module.css";
import commonStyles from "../../Common/styles/common.module.css";

const Areas = ({ loader, list, update }) => {
    const location = useLocation();
    const params = useParams();

    const { locations: { areas, count } } = useSelector((state) => state.admin);

    const [pagination, setPagination] = useState({ offset: 0, limit: 10 });
    const [sorting, setSorting] = useState({ type: null, order: null });

    const getAreas = async () => {
        const payload = {
            slug: params.citySlug,
            ...pagination,
            sort: sorting.type,
            sortType: sorting.order,
        };

        list(payload, loader);
    };

    const handleSorting = (field) => {
        if (sorting.type === field) {
            setSorting({ ...sorting, order: -1 * sorting.order });
        }
        else {
            setSorting({ type: field, order: 1 });
        }
    };

    const switchHandler = async (areaId, status) => {
        Swal.fire({
            title: "Are you sure?",
            text: `You want to ${status.toLowerCase()} this area`,
            confirmButtonText: "Yes",
            showCancelButton: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                const payload = { areaId, status };

                const listParams = {
                    ...pagination,
                    slug: params.citySlug,
                };

                update(payload, loader, {}, listParams);
            }
        });
    };

    useEffect(() => {
        getAreas();
    }, [pagination, sorting]);

    return (
        <div className={commonStyles["vendor-listing"]}>
            <div className="d-flex justify-content-between align-items-center">
                <ul className="paginaion">
                    <li><Link to="/">Dashboard</Link></li>
                    <li><Link to="/cities">Cities</Link></li>
                    <li><Link className="active" to={`/cities/${params.citySlug}/areas`}>Areas</Link></li>
                </ul>
                <Link state={location.state} className={commonStyles["cm-btn"]} to={`/cities/${params.citySlug}/add-area`}>
                    Add Area
                </Link>
            </div>
            <div className={tableStyles["listing-table"]}>
                <div className={tableStyles["listing-table-scrl"]}>
                    <table cellPadding={0} cellSpacing={0}>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Image</th>
                                <th className="cursor-pointer" onClick={() => handleSorting(SORTING_FIELDS.NAME)}>
                                    <span className="me-1">Name</span>
                                    {sorting.type !== SORTING_FIELDS.NAME ? <img src={SortIcon} width={15} height={15} /> : null}
                                    {sorting.type === SORTING_FIELDS.NAME ? sorting.order === 1 ? <i className="fa fa-angle-up" /> : <i className="fa fa-angle-down" /> : null}
                                </th>
                                <th>Description</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {areas.length === 0 ? <tr><td colSpan={4} className="text-center py-3">No Area found</td></tr> : null}
                            {areas.map((area, index) => (
                                <tr key={area._id}>
                                    <td>{++index}</td>                                    
                                    <td>
                                        <img src={area.image} alt={area.name} />
                                    </td>
                                    <td>{area.name}</td>
                                    <td>{area.description}</td>
                                    <td>
                                        <Switch
                                            onColor="#e89532"
                                            onChange={() =>
                                                switchHandler(
                                                    area._id,
                                                    area.status === "ACTIVE" ? "INACTIVE" : "ACTIVE"
                                                )
                                            }
                                            checked={area.status === "ACTIVE" ? 1 : 0}
                                        />
                                    </td>
                                    <td>
                                        <Link to={`/cities/${params.citySlug}/areas/${area.slug}/buildings`}>
                                            <i className="fas fa-eye" />
                                        </Link>
                                        <Link to={`/cities/${params.citySlug}/areas/${area.slug}`}>
                                            <i className="fas fa-edit" />
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <Pagination count={count} setPagination={setPagination} />
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    list: (payload, loader) => dispatch(AdminAreasAction.list(payload, loader)),
    update: (payload, loader, navigate, params) => dispatch(AdminAreasAction.update(payload, loader, navigate, params)),
});

export default connect(null, mapDispatchToProps)(Areas);
