import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Swal from "sweetalert2";

import { AdminPayoutsAction } from '../../../store/admin/thunk';
import commonStyles from '../../../pages/Common/styles/common.module.css';
import tableStyles from "../../../components/Table/style.module.css";
import { APP_ROUTES } from '../../../routes';
import { MESSAGES } from '../../../constants';

const ViewPayoutCard = ({ loader, listPayoutCards, updatePayoutCard }) => {

    const { payouts: { payoutLoader, payoutCards } } = useSelector((state) => state.admin);

    const [pagination, setPagination] = useState({ offset: 0, limit: 10 });

    const listPayoutCard = (offset = pagination.offset) => {
        let query = {
            limit: pagination.limit,
            offset,
        };

        listPayoutCards(query, loader);
    };

    const approveDisapproveCard = async (id) => {
        Swal.fire({
            title: 'Are you sure ?',
            text: 'You want to approve/disapprove the card ?',
            icon: 'info',
            showCancelButton: true,
            cancelButtonText: 'Disapprove',
            confirmButtonText: 'Approve',
            showCloseButton: true,

        }).then(async (result) => {
            let query = {
                limit: pagination.limit,
                offset: pagination.offset,
            };
            if (result.isConfirmed) {
                let payload = { bankAccountId: id, cardStatus: 2 };

                updatePayoutCard(payload, loader, query);
            }
            else if (result.dismiss === Swal.DismissReason.cancel) {
                let payload = { bankAccountId: id, cardStatus: 3 };

                updatePayoutCard(payload, loader, query);
            }
        });
    };

    useEffect(() => {
        listPayoutCard();
    }, []);

    return (
        <div className={commonStyles["vendor-listing"]}>
            <div className="d-flex justify-content-between align-items-center">
                <ul className="paginaion">
                    <li>
                        <Link to="/">Dashboard</Link>
                    </li>
                    <li className='active'>
                        <Link to={APP_ROUTES.VIEW_ADMIN_PAYOUTS}>Payment Card</Link>
                    </li>
                </ul>
            </div>
            <div className={tableStyles["listing-table"]}>
                <div className={tableStyles["listing-table-scrl"]}>
                    <table cellPadding={0} cellSpacing={0}>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Bank Account No.</th>
                                <th>Bank Name</th>
                                <th>Bank Identifier Code</th>
                                <th>Vendor</th>
                                <th>Country</th>
                                <th>City</th>
                                <th>State</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                payoutLoader ?
                                    <tr className={tableStyles.noresult}>
                                        <td colSpan={9}>{MESSAGES.LOADING}</td>
                                    </tr>
                                    :
                                    null
                            }
                            {
                                !payoutLoader && payoutCards.length === 0 ?
                                    <tr className={tableStyles.noresult}>
                                        <td colSpan={9}>{MESSAGES.PAYOUTS.NO_CARD_FOUND}</td>
                                    </tr>
                                    :
                                    null
                            }

                            {!payoutLoader && payoutCards.map((item, index) => (
                                <tr key={index}>
                                    <td>{++index}</td>
                                    <td>{item.accountNumber}</td>
                                    <td>{item.bankName}</td>
                                    <td>{item.bicCode}</td>
                                    <td>{item.vendorData.firstName} {item.vendorData.lastName}</td>
                                    <td>{item.address.country}</td>
                                    <td>{item.address.state}</td>
                                    <td>{item.address.city}</td>
                                    <td>
                                        {/* <Link to={`/payout/settings/card/${item._id}`}> */}
                                        <i className="fas fa-eye" onClick={() => approveDisapproveCard(item._id)}></i>
                                        {/* </Link> */}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    listPayoutCards: (params, loader) => dispatch(AdminPayoutsAction.payoutsList(params, loader)),
    updatePayoutCard: (params, loader, query) => dispatch(AdminPayoutsAction.updatePayoutCard(params, loader, query)),
});

export default connect(null, mapDispatchToProps)(ViewPayoutCard);
