import VENDOR_ACTIONS from "../actions";

const initialState = {
    payoutLoader: false,
    payouts: [],
    payoutsCount: 0,
    payoutCardCount: 0,
    payoutCards: [],
    payoutCardLoader: false,
    specificPayoutLoader: false,
    specificPayout: {},
};

const vendorPayoutReducer = (state = initialState, action) => {

    switch (action.type) {
        case VENDOR_ACTIONS.REQUEST_PAYOUTS:
            return {
                ...state,
                payoutLoader: action.status,
            };


        case VENDOR_ACTIONS.SET_PAYOUTS:
            return {
                ...state,
                payouts: action.payouts,
                payoutsCount: action.count,
            };

        case VENDOR_ACTIONS.PAYOUT_CARD_SUCCESS:
            return {
                ...state,
                payoutCards: action.payoutCards,
            };

        case VENDOR_ACTIONS.REQUEST_PAYOUT_CARD:
            return {
                ...state,
                payoutCardLoader: action.status,
            };

        case VENDOR_ACTIONS.REQUEST_SPECIFIC_PAYOUT_CARD:
            return {
                ...state,
                specificPayoutLoader: action.status,
            };

        case VENDOR_ACTIONS.SPECIFIC_PAYOUT_CARD_SUCCESS:
            return {
                ...state,
                specificPayout: action.specificPayout,
            };
        default:
            return state;
    }
};

export default vendorPayoutReducer;