import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";

import { Pagination } from "../../../components";
import { CommonAction } from "../../../store/common/thunk";
import tableStyles from  "../../../components/Table/style.module.css";
import commonStyles from  "../../Common/styles/common.module.css";

const Notifications = ({ loader, notificationList }) => {
    const { user, notificationTab } = useSelector((state) => state.common);

    const [pagination, setPagination] = useState({ offset: 0, limit: 10 });

    const getNotifications = async () => {
        notificationList({ userType: user.userType, ...pagination }, loader, true);
    };

    useEffect(() => {
        getNotifications();
    }, [pagination]);

    return (
        <div className={commonStyles["vendor-listing"]}>
            <div className="d-flex justify-content-between align-items-center">
                <ul className="paginaion">
                    <li><Link to="/">Dashboard</Link></li>
                    <li className="active"><Link to="/">Notifications</Link></li>
                </ul>
            </div>
            <div className={tableStyles["listing-table"]}>
                <div className={tableStyles["listing-table-scrl"]}>
                    <table cellPadding={0} cellSpacing={0}>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Name</th>
                                <th>Title</th>
                                <th>Body</th>
                            </tr>
                        </thead>

                        <tbody>
                            {notificationTab.notifications.map((notify, index) => (
                                <tr key={notify._id}>
                                    <td>{++index}</td>
                                    <td>{notify.sender_id?.firstName}</td>
                                    <td>{notify.title}</td>
                                    <td>{notify.body}</td>
                                </tr>
                            ))}
                        </tbody> 
                    </table>
                </div>
            </div>
            <Pagination
                count={notificationTab.count + 1}
                setPagination={setPagination}
            />
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    notificationList: (params, loader, replace) => dispatch(CommonAction.notificationsList(params, loader, replace))
});

export default connect(null, mapDispatchToProps)(Notifications);
