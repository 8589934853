import * as Yup from "yup";

import { MESSAGES } from "../constants";

const AddPayoutCardSchema = Yup.object().shape({
    accountNumber:
        Yup
            .string()
            .required(MESSAGES.VALIDATION_ERRORS.REQUIRED)
            .matches(/^\d{4}\s?\d{4}\s?\d{4}\s?\d{4}$/, 'Invalid account number format. It should be in 16-digit format separated by spaces.'),
    billingCountry: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
    bankName: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
    bicCode: Yup
        .string()
        .required(MESSAGES.VALIDATION_ERRORS.REQUIRED)
        .matches(/^[A-Za-z]{4}[A-Za-z]{2}[A-Za-z0-9]{2}([A-Za-z0-9]{3})?$/, 'Invalid BIC code format.'),
    addressLine1: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
    addressLine2: Yup.string(),
    city: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
    state: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
    country: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
});

export default AddPayoutCardSchema;