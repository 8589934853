
import {Axios} from "../../../lib";
import VENDOR_ACTIONS from "../actions";
import { Toaster } from "../../../components/Toaster";
import { CommonAction } from "../../common/thunk";
import { API_ENDPOINTS, MESSAGES, TOAST_TYPE } from "../../../constants";

const pricingList = (params, loader, resetDates) => async (dispatch) => {
    try {
        const { data: { data, promotionData } } = await Axios.get(API_ENDPOINTS.LIST_UPDATE_PROPERTY_PRICES, params, loader);

        dispatch({ type: VENDOR_ACTIONS.SET_PROPERTY_PRICE, pricing: data, promotions: promotionData });

        if (resetDates) {
            dispatch({ type: VENDOR_ACTIONS.SET_PRICE_DATES, selectedDates: [] });
        }
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.SOMETHING_WENT_WRONG);
    }
};

const updateCalendarPrice = (payload, loader, params) => async (dispatch) => {
    try {
        await Axios.put(API_ENDPOINTS.LIST_UPDATE_PROPERTY_PRICES, payload, loader);

        Toaster(TOAST_TYPE.SUCCESS, MESSAGES.CALENDAR.UPDATE_SUCCESS);

        params = { fromDate: params.startDate, toDate: params.endDate, propertyId: payload.propertyId };

        dispatch(pricingList(params, loader, true));
        dispatch({ type: VENDOR_ACTIONS.SET_PRICE_MODAL, status: false });
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.PROPERTY.PRICE.UPDATE_ERROR);
    }
};

const remove = (params, loader, pagination) => async (dispatch) => {
    try {
        const response = await Axios.delete(API_ENDPOINTS.PROPERTY_CRUD, { params }, loader);
        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, MESSAGES.PROPERTY.REMOVE_SUCCESS);

            dispatch(CommonAction.propertyList(pagination, loader));
        }
        else {
            Toaster(TOAST_TYPE.ERROR, response.error);
        }
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.PROPERTY.REMOVE_ERROR);
    }
};

const update = (payload, loader, id) => async (dispatch) => {
    try {
        const response = await Axios.patch(API_ENDPOINTS.PROPERTY_CRUD, payload, loader);
        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, MESSAGES.PROPERTY.UPDATE_SUCCESS);
            dispatch(CommonAction.specificProperty({ id }, loader));
        }
        else {
            Toaster(TOAST_TYPE.ERROR, response.error);
        }
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.PROPERTY.UPDATE_ERROR);
    }
};

export {
    pricingList,
    remove,
    updateCalendarPrice,
    update
};