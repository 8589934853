import ADMIN_ACTIONS from "../actions";

const initialState = {
    subAdminList: [],
    count: 0,
    createSubAdmin: false,
    loading: false,
};

const adminSubAdminReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADMIN_ACTIONS.SUB_ADMIN_LIST_SUCCESS:
            return {
                ...state,
                subAdminList: action.list,
                count: action.count,
            };

        case ADMIN_ACTIONS.CREATE_SUB_ADMIN_SUCCESS:
            return {
                ...state,
                createSubAdmin: action.status,
            };
        
        case ADMIN_ACTIONS.REQUEST_SUB_ADMIN_LIST:
            return {
                ...state,
                loading: action.status,
            };

        default:
            return state;
    }
};

export default adminSubAdminReducer;