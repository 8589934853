import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { connect, useSelector } from "react-redux";

import { Axios } from "../../../../lib";
import { CmsSchema } from "../../../../schemas";
import { AdminCMSAction } from "../../../../store/admin/thunk";

function Editcms({ loader, listCMS }) {
    const location = useLocation();
    const { cms: { cms } } = useSelector((state) => state.admin);

    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const typeParam = queryParams.get("type");

        const payload = { type: typeParam };

        listCMS(payload, loader);
    }, [location]);

    const [initialValues, setInitialValues] = useState({
        description: "",
        title: "",
    });

    useEffect(() => {
        if (cms) {
            setInitialValues({
                description: cms.description,
                title: cms.title,
            });
        }
    }, [cms]);

    const updateCMS = async ({ title, description }, cms_id = cms._id) => {
        try {
            const { status } = await Axios.patch(
                "v1/admin/update/cms",
                { cms_id, title, description },
                loader
            );

            if (status) navigate("/cms");
        } catch (error) { }
    };

    return (
        <div className="edit-users-sc">
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={CmsSchema}
                onSubmit={(values) => updateCMS(values)}
            >
                {({ errors, touched }) => (
                    <Form>
                        <div className="form-group">
                            <label>Add Title</label>
                            <Field name="title" placeholder="Add title" type="text" />
                            {errors.title && touched.title ? (
                                <div className="error">{errors.title}</div>
                            ) : null}
                        </div>

                        <div className="form-group">
                            <label>Add Discription</label>
                            <Field name="description" placeholder="Enter name" type="text" />
                            {errors.description && touched.description ? (
                                <div className="error">{errors.description}</div>
                            ) : null}
                        </div>

                        <button type="submit">Update</button>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
    listCMS: (payload, loader) => dispatch(AdminCMSAction.list(payload, loader)),
});

export default connect(null, mapDispatchToProps)(Editcms);
