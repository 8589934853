import { createSlice } from "@reduxjs/toolkit";

const currency = createSlice({
    name: "addcurrency",
    initialState: "INR",
    reducers: {
        currencyType: (state, action) => {
            return action;
        },
    },
});

export const { currencyType } = currency.actions;

export default currency.reducer;
