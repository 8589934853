import VENDOR_ACTIONS from "../actions";

const initialState = {
    tourPrices: [],
    availableBookings: [],
    selectedDates: [],
    priceLoader: true,
    priceModal: false,
    tourLoader: true,
    tour: {},
};

const vendorTourReducer = (state = initialState, action) => {

    switch (action.type) {
        case VENDOR_ACTIONS.SET_TOUR_PRICE:
            const { pricing } = action;

            return {
                ...state,
                tourPrices: pricing,
                priceLoader: false,
            };

        case VENDOR_ACTIONS.REQUEST_SPECIFIC_TOUR:
            return {
                ...state,
                tourLoader: action.status,
            };

        case VENDOR_ACTIONS.SET_TOUR_PRICING_LOADER:
            return {
                ...state,
                priceLoader: action.status,
            };

        case VENDOR_ACTIONS.SET_TOUR_DETAILS:
            return {
                ...state,
                tour: action.tour,
            }
        default:
            return state;
    }
};

export default vendorTourReducer;