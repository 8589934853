import {Axios} from "../../../lib";
import { Toaster } from "../../../components/Toaster";
import ADMIN_ACTIONS from "../actions";
import { API_ENDPOINTS, MESSAGES } from "../../../constants";
import { TOAST_TYPE } from "../../../constants";

const list = (query, loader) => async (dispatch) => {
    try {
        dispatch({ type: ADMIN_ACTIONS.REQUEST_SUB_ADMIN_LIST, status: true });
        const response = await Axios.get(API_ENDPOINTS.LIST_SUB_ADMIN, query, loader);

        dispatch({ type: ADMIN_ACTIONS.SUB_ADMIN_LIST_SUCCESS, list: response.data.data, count: response.data.count });
        dispatch({ type: ADMIN_ACTIONS.REQUEST_SUB_ADMIN_LIST, status: false });
    }
    catch (err) {
        dispatch({ type: ADMIN_ACTIONS.REQUEST_SUB_ADMIN_LIST, status: false });
    }
};

const update = (payload, loader) => async (dispatch) => {
    try {
        const response = await Axios.patch(API_ENDPOINTS.UPDATE_SUB_ADMIN, payload, loader);

        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, `Subadmin ${payload.isBlocked ? "blocked" : "unblocked"} successfully`);
            dispatch(list(loader));
        }
        else {
            Toaster(TOAST_TYPE.ERROR, response.error);
        }
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.SOMETHING_WENT_WRONG);
    }
};

const create = (payload, loader) => async (dispatch) => {
    try {
        const response = await Axios.post(API_ENDPOINTS.CREATE_SUB_ADMIN, payload, loader);
        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, MESSAGES.SUB_ADMIN.CREATE_SUCCESS);
            dispatch({ type: ADMIN_ACTIONS.CREATE_SUB_ADMIN_SUCCESS, status: true });
        }
        else {
            Toaster(TOAST_TYPE.ERROR, response.error);
        }
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.SOMETHING_WENT_WRONG);
    }
};

export {
    list,
    update,
    create
};