import * as Yup from "yup";
import moment from "moment";

import { MESSAGES } from "../constants";

const calendarFilterSchema = Yup.object().shape({
    startDate: Yup.string().required("Required"),
    endDate: Yup.string().required("Required").test("calendar-date", function(endDate) {
        let { startDate } = this.parent;

        if(startDate && startDate.length && endDate) {
            startDate = moment(startDate);
            endDate = moment(endDate);

            if(!endDate.isAfter(startDate)) {
                return this.createError({ message: MESSAGES.VALIDATION_ERRORS.INVALID_DATE, path: this.path })
            }
            return { path: this.path };
        }
        return { path: this.path };
    }),
});

export default calendarFilterSchema;