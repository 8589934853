import { Toaster } from "../../../components";
import { API_ENDPOINTS, MESSAGES, TOAST_TYPE } from "../../../constants";
import { Axios } from "../../../lib";
import ADMIN_ACTIONS from "../actions";

const getDashboardDetails = (loader) => async (dispatch) => {
    try {
        const response = await Axios.get(API_ENDPOINTS.ADMIN_DASHBOARD, {}, loader);
        dispatch({ type: ADMIN_ACTIONS.ADMIN_DASHBOARD_SUCCESS, dashboardDetails: response.data });
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.SOMETHING_WENT_WRONG);
    }
};

export {
    getDashboardDetails
};