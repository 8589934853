import { Toaster } from "../../../components/Toaster";
import { Axios } from "../../../lib";
import { API_ENDPOINTS, TOAST_TYPE } from "../../../constants";
import VENDOR_ACTIONS from "../actions";
import { APP_ROUTES } from "../../../routes";

const payoutsList = (params, loader, selectedBookings) => async (dispatch) => {
    try {
        dispatch({ type: VENDOR_ACTIONS.REQUEST_PAYOUTS, status: true });
        const response = await Axios.get(API_ENDPOINTS.BOOKING_PAYOUTS, params, loader);

        let payouts = response.data.payouts.map(item => {
            let index = selectedBookings.findIndex(it => it === item._id);

            if (index > -1) { 
                item.selected = true;
            }
            else item.selected = false;

            return item;
        });

        dispatch({ type: VENDOR_ACTIONS.REQUEST_PAYOUTS, status: false });
        dispatch({ type: VENDOR_ACTIONS.SET_PAYOUTS, payouts: payouts, count: response.data.count });
    }
    catch (error) {
        dispatch({ type: VENDOR_ACTIONS.REQUEST_PAYOUTS, status: false });
    }
};

const generateInvoice = (loader, type, limit, bankAccountId, selectedBookings) => async (dispatch) => {
    try {
        await Axios.post(API_ENDPOINTS.GENERATE_INVOICE, { bankAccountId, payoutIds: selectedBookings }, loader);
        Toaster(TOAST_TYPE.SUCCESS, "Invoice generated successfully")

        const query = { type, limit, offset: 0 };

        dispatch(payoutsList(query));
    }
    catch (error) {
        Toaster(TOAST_TYPE.ERROR, error.error);
    }
};

const savePayoutCard = (payload, navigate, loader) => async (dispatch) => {
    try {
        const response = await Axios.post(API_ENDPOINTS.SAVE_PAYOUT_CARD, payload, loader);
        Toaster(TOAST_TYPE.SUCCESS, response.data.message);
        navigate(APP_ROUTES.VIEW_PAYOUTS_SETTINGS);

    }
    catch (error) {
        Toaster(TOAST_TYPE.ERROR, error.error);
    }
};

const specificPayoutCard = (id, loader) => async (dispatch) => {
    try {
        dispatch({ type: VENDOR_ACTIONS.REQUEST_SPECIFIC_PAYOUT_CARD, status: true });

        const params = { bankAccountId: id };

        const response = await Axios.get(`${API_ENDPOINTS.SPECIFIC_PAYOUT_CARD}`, params, loader);

        dispatch({ type: VENDOR_ACTIONS.REQUEST_SPECIFIC_PAYOUT_CARD, status: false });
        dispatch({ type: VENDOR_ACTIONS.SPECIFIC_PAYOUT_CARD_SUCCESS, specificPayout: response.data });
    }
    catch (error) {
        dispatch({ type: VENDOR_ACTIONS.REQUEST_SPECIFIC_PAYOUT_CARD, status: false });
    }
};

const updatePayoutCard = (payload, navigate, loader) => async (dispatch) => {
    try {
        const response = await Axios.put(API_ENDPOINTS.UPDATE_PAYOUT_CARD, { params: payload }, loader);

        Toaster(TOAST_TYPE.SUCCESS, "Card updated successfully");
        navigate(APP_ROUTES.VIEW_PAYOUTS_SETTINGS);

    }
    catch (error) {
        Toaster(TOAST_TYPE.ERROR, error.error);
    }
};

const deletePayoutCard = (params, navigate, loader) => async (dispatch) => {
    try {
        const response = await Axios.delete(API_ENDPOINTS.DELETE_PAYOUT_CARD, { params }, loader);

        Toaster(TOAST_TYPE.SUCCESS, "Card removed successfully");
        navigate(APP_ROUTES.VIEW_PAYOUTS_SETTINGS);

    }
    catch (error) {
        Toaster(TOAST_TYPE.ERROR, error.error);
    }
};

const listPayoutCards = (loader, params = {}) => async (dispatch) => {
    try {
        dispatch({ type: VENDOR_ACTIONS.REQUEST_PAYOUT_CARD, status: true });
        const response = await Axios.get(API_ENDPOINTS.LIST_PAYOUT_CARD, params, loader);

        for (let item of response.data.accountData) {
            let splitArr = item.accountNumber.split(' ');

            splitArr.forEach((item, index) => {
                if (index < 3) {
                    splitArr[index] = '****';
                }
            });

            splitArr = splitArr.join(' ');
            item.accountNumber = splitArr;
        }

        dispatch({ type: VENDOR_ACTIONS.PAYOUT_CARD_SUCCESS, payoutCards: response.data.accountData });
        dispatch({ type: VENDOR_ACTIONS.REQUEST_PAYOUT_CARD, status: false });
    }
    catch (error) {
        Toaster(TOAST_TYPE.ERROR, error.error);
        dispatch({ type: VENDOR_ACTIONS.REQUEST_PAYOUT_CARD, status: false });
    }
};

export {
    payoutsList,
    generateInvoice,
    savePayoutCard,
    listPayoutCards,
    updatePayoutCard,
    specificPayoutCard,
    deletePayoutCard
};