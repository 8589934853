import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Switch from "react-switch";
import Swal from "sweetalert2";
import { connect, useSelector } from "react-redux";

import { Pagination } from "../../../components";
import { SORTING_FIELDS } from "../../../constants";
import { APP_ROUTES } from "../../../routes";
import { AdminCitiesAction } from "../../../store/admin/thunk";
import { SortIcon } from "../../../assets/images";
import tableStyles from "../../../components/Table/style.module.css";
import commonStyles from "../../Common/styles/common.module.css";

const Cities = ({ loader, list, update }) => {
    const navigate = useNavigate();

    const { locations: { cities, count } } = useSelector((state) => state.admin);
    const [pagination, setPagination] = useState({
        offset: 0,
        limit: 10,
    });
    const [sorting, setSorting] = useState({ type: null, order: null });

    const getCities = async () => {
        const params = {
            limit: pagination.limit,
            offset: pagination.offset,
            showAllCities: true,
            sort: sorting.type,
            sortType: sorting.order,
        };

        list(params, loader);
    };

    const handleSorting = (field) => {
        if (sorting.type === field) {
            setSorting({ ...sorting, order: -1 * sorting.order });
        }
        else {
            setSorting({ type: field, order: 1 });
        }
    };

    const switchHandler = async (city_id, status) => {
        Swal.fire({
            title: "Are you sure?",
            text: `You want to ${status.toLowerCase()} this city`,
            confirmButtonText: "Yes",
            showCancelButton: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                const payload = {
                    city_id,
                    status,
                };

                update(payload, loader, navigate);
            }
        });
    };

    useEffect(() => {
        getCities();
    }, [pagination, sorting]);
    return (
        <div className={commonStyles["vendor-listing"]}>
            <div className="d-flex justify-content-between align-items-center">
                <ul className="paginaion">
                    <li><Link to="/">Dashboard</Link></li>
                    <li className="active"><Link to="/cities">Cities</Link></li>
                </ul>
                <Link className={commonStyles["cm-btn"]} to={APP_ROUTES.ADD_CITY}>
                    Add City
                </Link>
            </div>

            <div className={tableStyles["listing-table"]}>
                <div className={tableStyles["listing-table-scrl"]}>
                    <table cellPadding={0} cellSpacing={0}>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Image</th>
                                <th className="cursor-pointer" onClick={() => handleSorting(SORTING_FIELDS.NAME)}>
                                    <span className="me-1">Name</span>
                                    {sorting.type !== SORTING_FIELDS.NAME ? <img src={SortIcon} width={15} height={15} /> : null}
                                    {sorting.type === SORTING_FIELDS.NAME ? sorting.order === 1 ? <i className="fa fa-angle-up" /> : <i className="fa fa-angle-down" /> : null}
                                </th>

                                <th className="cursor-pointer" onClick={() => handleSorting(SORTING_FIELDS.CITIES_PROPERTIES)}>
                                    <span className="me-1">No. of Properties</span>
                                    {sorting.type !== SORTING_FIELDS.CITIES_PROPERTIES ? <img src={SortIcon} width={15} height={15} /> : null}
                                    {sorting.type === SORTING_FIELDS.CITIES_PROPERTIES ? sorting.order === 1 ? <i className="fa fa-angle-up" /> : <i className="fa fa-angle-down" /> : null}
                                </th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            {cities.map((city, index) => (
                                <tr key={city._id}>
                                    <td>{++index}</td>
                                    <td>
                                        <img src={city.image} alt={city.name} />
                                    </td>
                                    <td>{city.name}</td>
                                    <td>{city.properties}</td>
                                    <td>
                                        <Switch
                                            onColor="#e89532"
                                            onChange={() =>
                                                switchHandler(
                                                    city._id,
                                                    city.status === "ACTIVE" ? "INACTIVE" : "ACTIVE"
                                                )
                                            }
                                            checked={city.status === "ACTIVE"}
                                        />
                                    </td>
                                    <td>
                                        <Link state={{ city }} to={`/cities/${city.slug}/areas`}>
                                            <i className="fas fa-eye"></i>
                                        </Link>
                                        <Link state={{ city }} to={`/cities/${city.slug}`}>
                                            <i className="fas fa-edit"></i>
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <Pagination count={count} setPagination={setPagination} />
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    list: (payload, loader) => dispatch(AdminCitiesAction.list(payload, loader)),
    update: (payload, loader) => dispatch(AdminCitiesAction.update(payload, loader)),
});

export default connect(null, mapDispatchToProps)(Cities);
