import React, { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
// import { getCroppedImg } from '../utils/cropImage';
import "./ImageCropper.css"; // Add CSS if needed
import { getCroppedImg } from "../../lib/imageCropLib";

const ImageCropper = ({ imageSrc, onUpload }) => {
  const [crop, setCrop] = useState({ x: 0, y: 2 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCropAndUpload = async () => {
    try {
      const croppedBlob = await getCroppedImg(imageSrc, croppedAreaPixels);
      const croppedFile = new File([croppedBlob], "cropped-image.jpg", {
        type: "image/jpeg",
      });

      // Call the upload function
      onUpload(croppedFile);
    } catch (error) {
      console.error("Error cropping the image:", error);
    }
  };

  return (
    <div className="crop-container">
      <div className="row">
        <div className="col-12">
          <Cropper
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            aspect={4 / 3} // Change aspect ratio if needed
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
        </div>
        <div className="col-12">
          <button className="crop-button" onClick={handleCropAndUpload}>
            Crop and Upload
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageCropper;
