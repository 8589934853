import { Axios } from "../../../lib";
import { Toaster } from "../../../components/Toaster";
import { API_ENDPOINTS, MESSAGES } from "../../../constants";
import { APP_ROUTES } from "../../../routes";
import { TOAST_TYPE } from "../../../constants";
import ADMIN_ACTIONS from "../actions";

const list = (payload, loader) => async (dispatch) => {
    try {
        const { data } = await Axios.get(API_ENDPOINTS.LIST_AREAS_BUILDINGS, payload, loader);
        dispatch({ type: ADMIN_ACTIONS.AREAS_LIST_SUCCESS, areas: data.location, count: data.count });
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.SOMETHING_WENT_WRONG);
    }
};

const update = (payload, loader, navigate, params) => async (dispatch) => {
    try {
        const response = await Axios.patch(API_ENDPOINTS.CREATE_UPDATE_AREA, payload, loader);

        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, MESSAGES.AREA.UPDATE_SUCCESS);

            if (payload.name || payload.image) {
                navigate(APP_ROUTES.CITIES_LIST);
            }
            else {
                dispatch(list(params, loader));
            }
        }
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.AREA.UPDATE_ERROR);
    }
};

const create = (payload, loader, navigate) => async () => {
    try {
        const response = await Axios.post(API_ENDPOINTS.CREATE_UPDATE_AREA, payload, loader);

        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, MESSAGES.AREA.CREATE_SUCCESS);

            if (payload.name || payload.image) {
                navigate();
            }
        }
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.AREA.CREATE_ERROR);
    }
};

export {
    list,
    update,
    create
};