import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { connect, useSelector } from "react-redux";

import { APP_ROUTES } from "../../../../routes";
import { AdminUserAction } from "../../../../store/admin/thunk";

const ViewUser = ({ loader, specificUser }) => {

    const params = useParams();

    const { user: { user } } = useSelector((state) => state.admin);

    useEffect(() => {
        if (params.id) {

            const query = { user_id: params.id };
            specificUser(loader, query);
        }
    }, []);

    return (
        <div className="edit-users-sc bg-white">
            <div className="form-group">Name : {user?.firstName}</div>
            <div className="form-group">Email : {user?.email}</div>
            <div className="form-group">Phone No. : {user?.phoneNumber}</div>
            <div className="form-group">
                Account Verified : {`${user?.isVerified ? "Verified" : "Not Verified"}`}
            </div>

            <Link to={APP_ROUTES.USERS_LIST} state={{ user }}>
                <button>Go Back</button>
            </Link>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    specificUser: (loader, query) => dispatch(AdminUserAction.specific(loader, query)),
});

export default connect(null, mapDispatchToProps)(ViewUser);
