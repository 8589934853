import { connect, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import React, { Fragment, useState, useEffect } from "react";

import { AdminVoucherAction } from "../../../../store/admin/thunk";
import styles from "./style.module.css";
import { Pagination } from "../../../../components";
import tableStyles from "../../../../components/Table/style.module.css";

const ClaimedUsers = (props) => {
    const params = useParams();

    const { vouchers: { claimedVouchersList, clamiedVouchersCount } } = useSelector((state) => state.admin);
    const [pagination, setPagination] = useState({ offset: 0, limit: 10 });

    useEffect(() => {
        if (params && params.id) {
            const payload = {
                voucherId: params.id,
                ...pagination,
            };
            props.claimedUsers(payload, props.loader);
        }
    }, [pagination]);

    return (
        <Fragment>
            <div className="d-flex justify-content-between align-items-start my-3">
                <p className={styles.heading}>Claimed Users</p>
            </div>
            <div className={tableStyles["listing-table"]}>
                <div className={tableStyles["listing-table-scrl"]}>
                    <table cellPadding={0} cellSpacing={0}>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Name</th>
                                <th>Image</th>
                                <th>Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            {claimedVouchersList.length === 0 ? <tr className={tableStyles.noresult}><td colSpan={4}>No User</td></tr> : null}
                            {claimedVouchersList.map((item, index) => (
                                <tr key={item._id}>
                                    <td>{++index}</td>
                                    <td>{item.firstName} {item.lastName}</td>
                                    <td><img src={item.image} /></td>
                                    <td>{item.count}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="d-flex justify-content-center">
                <Pagination
                    count={clamiedVouchersCount}
                    setPagination={setPagination}
                />
            </div>
        </Fragment>
    );
};

const mapDispatchToProps = (dispatch) => ({
    claimedUsers: (params, loader) => dispatch(AdminVoucherAction.claimedUsers(params, loader)),
})

export default connect(null, mapDispatchToProps)(ClaimedUsers);