import ADMIN_ACTIONS from "../actions";

const initialState = {
    promotions: [],
    createPromotion: false,
    promotion: null,
    loading: false,
};

const adminPromotionReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADMIN_ACTIONS.SET_PROMOTIONS:
            return {
                ...state,
                promotions: action.promotions,
            };

        case ADMIN_ACTIONS.CREATE_PROMOTIONS_SUCCESS:
            return {
                ...state,
                createPromotion: action.status,
            };

        case ADMIN_ACTIONS.SET_SPECIFIC_PROMOTION:
            return {
                ...state,
                promotion: action.promotion,
                createPromotion: false,
            };
        
        case ADMIN_ACTIONS.REQUEST_PROMOTIONS_LIST:
            return {
                ...state,
                loading: action.status,
            };

        default:
            return state;
    }


};

export default adminPromotionReducer;