import * as Yup from "yup";
import { MESSAGES } from "../constants";

const AddCarSchema = Yup.object().shape({
    image: Yup.object().optional(MESSAGES.VALIDATION_ERRORS.REQUIRED),
    brand: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
    model: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
    price: Yup.number().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
    color: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
    categoryId: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
    subCategory: Yup.array().required(MESSAGES.VALIDATION_ERRORS.REQUIRED).min(1, MESSAGES.VALIDATION_ERRORS.REQUIRED),
    fuel: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
    name: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
    mileage: Yup.number().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
    transmission: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
    seats: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
    engine: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
    bhp: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
    bootSpace: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
    description: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
});

export default AddCarSchema;