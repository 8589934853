import React from "react";
import { useSelector } from "react-redux";

import styles from "./style.module.css";
import { UploadImageIcon, CreatePropertyIcon } from "../../../assets/images";
import { CREATE_PROPERTY_LOADER } from "../../../constants";

const AddLoader = () => {
    const { properties: { createPropertyLoader } } = useSelector(state => state.vendor);

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                {createPropertyLoader && <img src={createPropertyLoader === CREATE_PROPERTY_LOADER.UPLOAD_IMAGE ? UploadImageIcon : CreatePropertyIcon} />}
                <p>{createPropertyLoader === CREATE_PROPERTY_LOADER.UPLOAD_IMAGE ? "Uploading Images ..." : "Creating Property"}</p>
            </div>
        </div>
    );
};

export default AddLoader;