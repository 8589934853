import { ErrorMessage, Field, FieldArray, FormikProvider, useFormik } from "formik";
import React, { Fragment, useEffect } from "react";

import { TourCalendarSchema } from "../../../../schemas/tourCalendarSchema";

const CalendarPopup = (props) => {
    const calendarFormik = useFormik({
        initialValues: { subCategories: [] },
        validationSchema: TourCalendarSchema,
        onSubmit: (values) => {

            // if (props.values && props.values.length) {
            //     const groupedValues = props.values.reduce((result, item) => {
            //         if (!result[item.subCategoryId]) {
            //             result[item.subCategoryId] = [];
            //         }

            //         result[item.subCategoryId].push(item);

            //         return result;
            //     }, []);

            //     payload = {
            //         selectedDates: props.values.map(item => moment(item.date).format('YYYY-MM-DD')),
            //         subCategoryData: Object.keys(groupedValues).map(item => ({
            //             _id: item,
            //             ...values,
            //         })),
            //     };
            // }
            // else {
            //     let updatePayload = {};

            //     if (props.type === 'price') updatePayload.price = values.price;
            //     else if (props.type === 'maximumPerson') updatePayload.maximumPerson = values.maximumPerson;
            //     else updatePayload.pickupAndDrop = values.pickUpDrop == 1;

            //     payload = {
            //         selectedDates: [props.values.date],
            //         subCategoryData: [{
            //             ...updatePayload,
            //             _id: props.values.subCategoryId,
            //         }]
            //     };
            // }
            props.updateTourCalendar({
                selectedDates: Array.from(new Set(props.values.map(item => item.date))),
                subCategoryData: values.subCategories.map(item => ({ price: item.price, pickupAndDrop: item.pickUpDrop ? true : false, maximumPerson: item.maximumPerson, _id: item._id }))
            });
            props.closeModal(false);
        },
        enableReinitialize: true
    });

    useEffect(() => {
        if (props.values) {

            if (props.calendarDates.length === 0) {

                //     if (props.type === 'price') calendarFormik.setFieldValue('price', props.values.price);
                //     else if (props.type === 'maximumPerson') calendarFormik.setFieldValue('maximumPerson', props.values.maximumPerson);
                //     else calendarFormik.setFieldValue('pickUpDrop', props.values.pickupAndDrop ? 1 : 0);
            }
            else {
                const groupedValues = props.values.reduce((result, item) => {
                    if (!result[item.subCategoryId]) {
                        result[item.subCategoryId] = {};
                    }
                    result[item.subCategoryId] = { name: item.name, price: 0, pickUpDrop: 0, maximumPerson: 0, _id: item.subCategoryId };

                    return result;
                }, []);

                calendarFormik.setFieldValue("subCategories", Object.values(groupedValues));

            }
        }

    }, [props.values]);

    return (
        <div
            className={`modal fade ${props.show ? "show d-block" : ""}`}
            id="calendar-price"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">Change Tour</h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => props.closeModal(false)}
                        ></button>
                    </div>
                    <div className="px-4 pb-3">
                        <FormikProvider value={calendarFormik}>
                            <FieldArray
                                name="subCategories"
                                render={() => (
                                    calendarFormik.values.subCategories.map((item, index) => (
                                        <Fragment key={index}>
                                            <h3 className="pt-3">{item.name}</h3>
                                            <div className="mb-2">
                                                <label>Tour price</label>
                                                <Field
                                                    name={`subCategories.${index}.price`}
                                                    type="number"
                                                    validate={(value) => {
                                                        if (!/^\d*$/.test(value)) {
                                                            return "Please enter a whole number for the price.";
                                                        }
                                                        return "";
                                                    }}
                                                    placeholder="Enter price"
                                                    value={item.price}
                                                    className="form-control"
                                                />

                                                <ErrorMessage component="div" className="error" name={`subCategories.${index}.price`} />
                                            </div>

                                            <div className="mb-2">
                                                <label>Maximum Persons</label>
                                                <Field
                                                    min={1}
                                                    name={`subCategories.${index}.maximumPerson`}
                                                    type="number"
                                                    placeholder="Enter total number of person"
                                                    pattern="[0-9]*"
                                                    validate={(value) => {
                                                        if (!/^\d*$/.test(value)) {
                                                            return "Please enter a whole number";
                                                        }
                                                        return "";
                                                    }}
                                                    value={item.maximumPerson}
                                                    className="form-control"
                                                />
                                                <ErrorMessage component="div" className="error" name={`subCategories.${index}.maximumPerson`} />
                                            </div>

                                            <div className="mb-2">
                                                <label>Pick up/drop facility</label>
                                                <Field
                                                    onChange={(e) => calendarFormik.setFieldValue(`subCategories.${index}.pickUpDrop`, e.target.value)}
                                                    as="select"
                                                    name={`subCategories.${index}.pickUpDrop`}
                                                    value={item.pickUpDrop}
                                                    className="form-control"
                                                >
                                                    <option value="">Click to select</option>
                                                    <option value={1}>Yes</option>
                                                    <option value={0}>No</option>
                                                </Field>
                                                <ErrorMessage component="div" className="error" name={`subCategories.${index}.pickUpDrop`} />
                                            </div>
                                        </Fragment>
                                    ))

                                )}
                            />
                        </FormikProvider>
                    </div>
                    {/* <div className="modal-body">
                        
                        {calendarFormik.touched.price && calendarFormik.errors.price ? <p className='text-danger mb-0'>{calendarFormik.errors.price}</p> : null}
                    </div>
                    <div className="modal-body">
                        <label>Pick up/drop facility</label>
                        <select
                            onChange={(e) => calendarFormik.setFieldValue(`pickUpDrop`, e.target.value)}
                            as="select"
                            name="pickUpDrop"
                            value={calendarFormik.values.pickUpDrop}
                        >
                            <option value="">Click to select</option>
                            <option value={1}>Yes</option>
                            <option value={0}>No</option>
                        </select>
                    </div>
                    <div className="modal-body">
                        <label>Maximum Person allowed</label>
                        <input
                            type="text"
                            name="maximumPerson"
                            id="maximumPerson"
                            className="form-control px-3 py-2"
                            value={calendarFormik.values.maximumPerson}
                            onChange={calendarFormik.handleChange}
                            onBlur={calendarFormik.handleBlur}
                        />
                        {calendarFormik.touched.maximumPerson && calendarFormik.errors.maximumPerson ? <p className='text-danger mb-0'>{calendarFormik.errors.maximumPerson}</p> : null}
                    </div> */}
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={calendarFormik.submitForm}>
                            Update
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CalendarPopup;
