import * as Yup from "yup";

import { MESSAGES } from "../constants";

const CategorySchema = Yup.object().shape({
  name: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
  type: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
  vat: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
  image: Yup.string().required("Image is required"),
  // image: Yup.mixed()
  //   .notRequired("Image is required")
  //   .test(
  //     "fileSize",
  //     "File too large",
  //     (value) => value && value.size <= 1024 * 1024 // 1MB
  //   )
  //   .test(
  //     "fileFormat",
  //     "Unsupported Format",
  //     (value) =>
  //       value && ["image/jpg", "image/jpeg", "image/png"].includes(value.type)
  //   ),
});

export default CategorySchema;
