import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Switch from "react-switch";
import Swal from "sweetalert2";
import { connect, useSelector } from "react-redux";

import { Pagination } from "../../../components";
import { SORTING_FIELDS } from "../../../constants";
import { APP_ROUTES } from "../../../routes";
import { AdminCitiesAction } from "../../../store/admin/thunk";
import { SortIcon } from "../../../assets/images";
import tableStyles from "../../../components/Table/style.module.css";
import commonStyles from "../../Common/styles/common.module.css";
import { getImageCategories } from "./API";


export const ImageCat = ({ loader, list, update }) => {

    const navigate = useNavigate();
    const [categories, setCategories] = useState([])

    const getCategories = () => {
        getImageCategories(loader).then(res => {
            setCategories(res?.data);
        })
    }
    useEffect(()=>{
        getCategories();
    },[])

    const { locations: { cities, count } } = useSelector((state) => state.admin);

    const [pagination, setPagination] = useState({
        offset: 0,
        limit: 10,
    });
    const [sorting, setSorting] = useState({ type: null, order: null });
    return (
        <div className={commonStyles["vendor-listing"]}>
            <div className="d-flex justify-content-between align-items-center">
                <ul className="paginaion">
                    <li><Link to="/">Dashboard</Link></li>
                    <li className="active"><Link to="/image_categories">Image Categories</Link></li>
                </ul>
                <Link className={commonStyles["cm-btn"]} to={APP_ROUTES.ADD_IMAGE_CATEGORY}>
                    Add Category
                </Link>
            </div>

            <div className={tableStyles["listing-table"]}>
                <div className={tableStyles["listing-table-scrl"]}>
                    <table cellPadding={0} cellSpacing={0}>
                        <thead>
                            <tr>
                                <th className="cursor-pointer" onClick={() => handleSorting(SORTING_FIELDS.NAME)}>
                                    <span className="me-1">Name</span>
                                </th>
                                <th>Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            {categories.map((category, index) => (
                                <tr key={category._id}>
                                    <td>{category.name}</td>
                                    <td>
                                        {/* <Link to={`/`}>
                                            <i className="fas fa-eye"></i>
                                        </Link> */}
                                        <Link  to={`/image_categories/${category._id}`}>
                                            <i className="fas fa-edit"></i>
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {/* <Pagination count={count} setPagination={setPagination} /> */}
            </div>
        </div>
    );
};


export default ImageCat