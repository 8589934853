import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import momentTz from "moment-timezone";
import { connect, useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { useFormik } from "formik";

import PricePopup from "./PricePopup";

import { CALENDAR_DRAGGING_TYPE, OPEN_CLOSE_BOOKING_TYPES } from "../../../../constants/calendar";
import { VendorPropertyAction } from "../../../../store/vendor/thunk";
import { CommonAction } from "../../../../store/common/thunk";
import VENDOR_ACTIONS from "../../../../store/vendor/actions";
import styles from "../style.module.css";
import { CalendarLoader, PhotoIcon } from "../../../../assets/images";
import PropertyPromotions from "../../Property/Promotions";
import { CalendarFilterSchema } from "../../../../schemas";

const PropertyCalendar = (props) => {
    const dispatch = useDispatch();

    const calendarDateScrollbarRef = useRef(null);
    const propertyPriceScrollbarRef = useRef(null);
    const propertyPopupRef = useRef(null);

    const { properties: { propertyPrices, priceLoader, availableBookings, promotions, priceModal, selectedDates } } = useSelector(state => state.vendor);
    const { propertiesList } = useSelector(state => state.common);

    const [isPriceDragging, setIsPriceDragging] = useState(false);
    const [isNightDragging, setIsNightDragging] = useState(false);
    const [priceDragDate, setPriceDragDate] = useState({ startDate: null, endDate: null });
    const [nightDragDate, setNightDragDate] = useState({ startDate: null, endDate: null });

    const [selectedProperty, setSelectedProperty] = useState({});
    const [showProperty, setPropertyModal] = useState(false);
    const [priceDetails, setPriceDetails] = useState(null);
    const [openCloseProperty, setOpenClose] = useState(OPEN_CLOSE_BOOKING_TYPES.NONE);
    const [standardMinNights, setStandardMinNights] = useState(false);
    const [selectedDragBox, setSelectedDragBox] = useState(CALENDAR_DRAGGING_TYPE.PRICE_DRAGGING);

    const currentDate = moment();

    const fetchCalendar = async (resetDates) => {
        const params = {
            propertyId: selectedProperty._id,
            fromDate: moment(calendarFormik.values.startDate).format("YYYY-MM-DD"),
            toDate: moment(calendarFormik.values.endDate).format("YYYY-MM-DD"),
        };

        props.getPropertyPrices(params, props.loader, resetDates);
    };

    const fetchProperties = () => {
        const query = { offset: 0, limit: 1000 };

        props.getProperties(query, props.loader);
    };

    const handleCalendarDateScroll = () => {
        if (calendarDateScrollbarRef.current && propertyPriceScrollbarRef.current) {
            const { scrollLeft } = calendarDateScrollbarRef.current;

            propertyPriceScrollbarRef.current.scrollLeft = scrollLeft;
        }
    };

    const handlePropertyPriceScroll = () => {
        if (calendarDateScrollbarRef.current && propertyPriceScrollbarRef.current) {
            const { scrollLeft } = propertyPriceScrollbarRef.current;

            calendarDateScrollbarRef.current.scrollLeft = scrollLeft;
        }
    };

    const handleMouseUp = (newEndDate, isBookable, price, dragType, minNight) => {
        setSelectedDragBox(dragType);

        if (standardMinNights) {
            setIsNightDragging(false);
            setNightDragDate({ startDate: nightDragDate.startDate, endDate: newEndDate });
        }
        else {
            setIsPriceDragging(false);
            setPriceDragDate({ startDate: priceDragDate.startDate, endDate: newEndDate });
        }

        let selectedDates = [];
        let tempPricing = [...propertyPrices];

        if ((priceDragDate.startDate && newEndDate) || (nightDragDate.startDate && newEndDate)) {
            const startDate = moment(priceDragDate.startDate ?? nightDragDate.startDate);
            const endDate = moment(newEndDate);

            for (const item of tempPricing) {
                if (
                    item.month >= startDate.month() &&
                    item.month <= endDate.month() &&
                    item.year >= startDate.year() &&
                    item.year <= endDate.year()
                ) {
                    for (const mt of item.monthlyData) {
                        if (
                            ![OPEN_CLOSE_BOOKING_TYPES.SOLD_OUT].includes(mt.bookable) &&
                            startDate.diff(moment(mt.date)) <= 0 &&
                            endDate.diff(moment(mt.date)) >= 0 &&
                            startDate.diff(moment()) >= 0 && endDate.diff(moment()) >= 0
                        ) {
                            selectedDates.push(mt._id);
                            mt.isSelected = true;

                            if (selectedDates.length === 1) mt.startDate = true;
                            else mt.startDate = false;
                        }
                        else {
                            mt.isSelected = false;
                            mt.startDate = false;
                        }
                    }
                }
            }
            dispatch({ type: VENDOR_ACTIONS.SET_PRICE_DATES, selectedDates: selectedDates });

            if (selectedDates.length) {
                if (startDate.isSame(endDate)) {
                    setPriceDetails({
                        price: parseFloat(price),
                        isBookable,
                        dragType: dragType,
                        minNight: parseInt(minNight, 10),
                    });

                    setOpenClose(isBookable);
                }
                else {
                    setOpenClose(OPEN_CLOSE_BOOKING_TYPES.NONE);
                    setPriceDetails({ price: 0, isBookable, dragType: dragType });
                }
                dispatch({ type: VENDOR_ACTIONS.SET_PRICE_MODAL, status: true });
            }
        }

        dispatch({ type: VENDOR_ACTIONS.UPDATE_PROPERTY_PRICE, propertyPrices: tempPricing });
    };

    const handleMouseDown = (date) => {
        if (standardMinNights) {
            setIsNightDragging(true);
            setNightDragDate({ startDate: date, endDate: null });
        }
        else {
            setIsPriceDragging(true);
            setPriceDragDate({ startDate: date, endDate: null })
        }
    };

    const handleMouseEnter = (date) => {


        if (standardMinNights) {
            if (!isNightDragging) return;
            setNightDragDate({ startDate: nightDragDate.startDate, endDate: date });
        }
        else {
            if (!isPriceDragging) return;
            setPriceDragDate({ startDate: priceDragDate.startDate, endDate: date });
        }
    };

    const openProperties = () => setPropertyModal(!showProperty);

    const handleOutsideClick = (event) => {

        if (propertyPopupRef.current && !propertyPopupRef.current.contains(event.target)) {
            if (event.target.className === "outer-list-pro") setPropertyModal(false);
            else setPropertyModal(true);
        }
    };

    const updatePrice = (value, status) => {
        let payload = {
            fromDate: priceDragDate.startDate || nightDragDate.startDate,
            toDate: priceDragDate.endDate || nightDragDate.endDate,
            propertyId: selectedProperty._id,
        };

        // if (selectedDragBox === CALENDAR_DRAGGING_TYPE.PRICE_DRAGGING) {
        //     payload.price = value;
        // }
        // else {
        //     payload.minNights = value;
        // }

        if (value.price || value.price === 0) {
            payload.price = value.price;
        }
        if (value.night || value.night === 0) {
            payload.minNights = value.night;
        }

        if (selectedDragBox === CALENDAR_DRAGGING_TYPE.PRICE_DRAGGING && (status !== null || !moment(payload.startDate).isSame(moment(payload.toDate)))) {
            if (status === OPEN_CLOSE_BOOKING_TYPES.OPEN) payload.openBooking = true;
            else payload.closeBooking = true;
        }

        props.updateCalendarPrice(payload, props.loader, calendarFormik.values);
    };

    const closePriceModal = () => {
        dispatch({ type: VENDOR_ACTIONS.SET_PRICE_MODAL, status: false });

        const tempPricing = [...propertyPrices];

        for (const item of tempPricing) {
            for (const innerItem of item.monthlyData) {
                innerItem.startDate = false;
                innerItem.isSelected = false;
            }
        }

        dispatch({ type: VENDOR_ACTIONS.UPDATE_PROPERTY_PRICE, propertyPrices: tempPricing });
        dispatch({ type: VENDOR_ACTIONS.SET_PRICE_DATES, selectedDates: [] });
        setPriceDragDate({ startDate: null, endDate: null });
        setNightDragDate({ startDate: null, endDate: null });
    };

    const getAvailableBookingChip = (item) => {
        if (item.open) {
            return {
                text: item.open === 1 ? "Vac.." : "Vacant"
            };
        }
        else if (item.soldOut) {
            return {
                text: item.soldOut === 1 ? "Sol.." : "Sold Out"
            };
        }
        else {
            return {
                text: item.closed === 1 ? "Clo.." : "Closed"
            }
        }
    };

    const selectCalendarStyles = (monthlyData) => {
        let disabledField = "";
        let startStyle = "";
        let endStyle = "";
        let selectedStyle = "";

        if (monthlyData.userData || !monthlyData.bookable || currentDate.diff(moment(monthlyData.date)) > 0) disabledField = "disabled";
        if (monthlyData.startDate) startStyle = styles["selected-left-box"];
        if (monthlyData._id === selectedDates[selectedDates.length - 1]) endStyle = styles["selected-right-box"];
        if (monthlyData.isSelected && !monthlyData.startDate) selectedStyle = styles["selected-box"];

        let style = `${disabledField} ${startStyle} ${endStyle} ${selectedStyle}`;

        return style;
    };

    const calendarFormik = useFormik({
        initialValues: { startDate: moment().toDate(), endDate: moment().add(1, "month").toDate() },
        onSubmit: () => {
            fetchCalendar(false);
        },
        validationSchema: CalendarFilterSchema,
    });

    useEffect(() => {
        if (showProperty) {
            window.addEventListener("click", handleOutsideClick);
        }

        return () => {
            window.removeEventListener("click", handleOutsideClick);
        };
    }, [showProperty]);

    useEffect(() => {
        if (propertiesList && propertiesList.length) {
            calendarFormik.setValues({
                startDate: momentTz().tz(propertiesList[0].timezone || "Asia/Kolkata").toDate(),
                endDate: momentTz().tz(propertiesList[0].timezone || "Asia/Kolkata").add(1, "month").toDate(),
            });

            setSelectedProperty(propertiesList[0]);
        }
    }, [propertiesList])

    useEffect(() => {
        fetchProperties();
    }, []);

    useEffect(() => {
        if (Object.keys(selectedProperty).length) {
            const resetDates = false;
            fetchCalendar(resetDates);
        }
    }, [selectedProperty]);

    if (priceLoader) {
        return (
            <div className={styles.loadingCalendar}>
                <div className='d-flex'>
                    <p className='mb-0'>Loading Calendar...</p>
                </div>
                <img alt="calendar-loader" src={CalendarLoader} />
            </div>
        );
    }

    return (
        <div className={styles["calander-outer"]}>
            <div className='d-flex justify-content-between'>
                <h5>Calendar</h5>
                {/* <div className='d-flex align-content-center'>
          <Switch
            onColor="#e89532"
            height={20}
            width={48}
            className='align-self-center pr-1'
            onChange={(value) => showHidePromotions(!showPromotions)}
            checked={showPromotions}
          />
          <p className='mb-0 align-self-center px-2'>Promotions</p>
        </div> */}
            </div>
            <div className={styles["ficed-top-nbar"]}>
                <div className='d-inline-flex align-items-center'>
                    <div className='d-flex flex-column mb-0 form-group align-self-end'>
                        {calendarFormik.touched.startDate && calendarFormik.errors.startDate ? <p className='error mb-0'>{calendarFormik.errors.startDate}</p> : null}
                        <DatePicker
                            selected={calendarFormik.values.startDate}
                            onChange={(date) => calendarFormik.setFieldValue("startDate", date)}
                            dateFormat="dd MMM yyyy"
                            minDate={new Date()}
                        />
                    </div>
                    <div className='form-group mx-2 mb-0 align-self-end'>
                        {calendarFormik.touched.endDate && calendarFormik.errors.endDate ? <p className='error mb-0'>{calendarFormik.errors.endDate}</p> : null}
                        <DatePicker
                            selected={calendarFormik.values.endDate}
                            onChange={(date) => calendarFormik.setFieldValue("endDate", date)}
                            dateFormat="dd MMM yyyy"
                            minDate={new Date()}
                            className='d-block'
                        />
                    </div>
                    <span className={`${styles["sech-btn"]}  align-self-end`} onClick={calendarFormik.submitForm}> <i className='fas fa-search align-self-center cursor-pointer' /></span>
                </div>
                <div className={styles["cat-prop"]}>
                    <button onClick={openProperties}><img src={PhotoIcon} alt="" />{selectedProperty.name}</button>
                    {showProperty &&
                        <div className={styles["outer-list-pro"]}>
                            <div className={styles["list-pro"]}>
                                <ul ref={propertyPopupRef}>
                                    {propertiesList.map((item, key) => (
                                        <li key={key}>
                                            <div>
                                                <img src={item.images && item.images.length ? item.images[0] : PhotoIcon} alt="" />
                                                <div className='text-prop'>
                                                    <h6>{item.name}</h6>
                                                    <p>{item.location}</p>
                                                </div>
                                            </div>
                                            <label className="rad">
                                                <input
                                                    type="radio"
                                                    value={item._id}
                                                    checked={selectedProperty._id === item._id}
                                                    name="radio"
                                                    onChange={() => {
                                                        setSelectedProperty(item);
                                                        openProperties();
                                                    }}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                    ))}
                                </ul>
                            </div> </div>}

                </div>
            </div>
            <div className={styles["outre-calnder-grid-outer"]} ref={calendarDateScrollbarRef} onScroll={handleCalendarDateScroll}>

                {new Array(3).fill({}).map((_, index) => (
                    <div className={styles["outre-calnder-grid"]} key={index}>
                        <div className={`${styles["day-cal"]} ${styles["empty-day-cal"]}`}>
                            <p></p>
                        </div>
                        <div className={styles["dates-inner"]}>
                            <div className={`${styles["av-cal-list-grid__cell"]} ${styles["av-cal-list-cell"]} ${styles["border-right-0"]}`}>
                                <span className={`${styles["av-cal-list-date"]} av-cal-list-date--weekend`}>
                                    <span className="av-cal-list-date__day-of-week">
                                        <p></p>
                                    </span>
                                    <p className="av-cal-list-date__day-number"></p>
                                </span>
                            </div>
                        </div>
                    </div>
                ))}

                {propertyPrices.map((item, index) => (
                    <div className={styles["outre-calnder-grid"]} key={index}>
                        <div className={styles["day-cal"]}>
                            <span>{moment().month(item.month).format("MMMM")} {item.year}</span>
                        </div>
                        <div className={styles["dates-inner"]}>
                            {item.monthlyData.map((monthlyItem, index) => (
                                <div
                                    key={index}
                                    className={
                                        `${styles["av-cal-list-grid__cell"]} ${styles["av-cal-list-cell"]}
                                        ${monthlyItem.day === 6 ? styles["av-cal-list-grid__cell--separator"] : null}`
                                    }
                                >
                                    <span className={`
                                        ${monthlyItem.day === 6 || monthlyItem.day === 0 ?
                                    `${styles["av-cal-list-date"]} ${styles["av-cal-list-date--weekend"]}` :
                                    styles["av-cal-list-date"]}
                                        `}
                                    >
                                        <span>{moment(monthlyItem.date).format("dddd").slice(0, 3)}</span>
                                        <span className={styles["av-cal-list-date__day-number"]}>
                                            {moment(monthlyItem.date).format("DD")}
                                        </span>
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <div className={styles["lower-calander-data"]}>
                <div className={styles["top-link-calander"]}>
                    <h4>{selectedProperty?.category_id?.name} <span>(Room ID: {selectedProperty.unitNumber})</span></h4>
                </div>
                <div className={styles["rooms-outer"]} ref={propertyPriceScrollbarRef} onScroll={handlePropertyPriceScroll}>
                    <div className={`${styles["room-sell"]} ${styles.romstarus}`}>
                        <label className={styles["mg-n"]}>Room status</label>
                        <table>
                            <tbody>
                                <tr className={styles["available-bookings"]}>
                                    {propertyPrices.map(item => (
                                        item.monthlyData.map((mt, index) => (
                                            <th className={styles["room-rount-header"]} key={index}>A</th>
                                        ))
                                    ))}
                                </tr>

                                <tr>
                                    {availableBookings.map((item, index) => (
                                        <td className={styles["room-rount"]} colSpan={item.open || item.closed || item.soldOut} key={index}>

                                            <p className={item.open ? styles.available : styles.booked}>{getAvailableBookingChip(item).text}</p>
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className={`${styles["room-sell"]} booked-rate`}>
                        <label>
                            <i onClick={() => setStandardMinNights(!standardMinNights)} className={`fas ${!standardMinNights ? "fa-angle-down" : "fa-angle-up"} cursor-pointer`} />
                            Standard Rate
                        </label>
                        <div className={styles["rooms-count"]}>
                            {propertyPrices.map(item => (
                                item.monthlyData.map((mt, index) => (
                                    <div
                                        key={index}
                                        id={moment(mt.date).format("YYYY-MM-DD")}
                                        className={`text-right cursor-pointer ${styles["room-rount"]} ${selectedDragBox === CALENDAR_DRAGGING_TYPE.PRICE_DRAGGING ? selectCalendarStyles(mt) : ""}`}
                                        onMouseDown={() => handleMouseDown(mt.date)}
                                        onMouseUp={() => handleMouseUp(mt.date, mt.bookable, mt.price, CALENDAR_DRAGGING_TYPE.PRICE_DRAGGING, mt.minNights)}
                                        onMouseEnter={() => handleMouseEnter(mt.date)}
                                    >
                                        {mt.startDate}
                                        <span>{mt.currencySymbol}</span>
                                        {mt.price}
                                    </div>
                                ))
                            ))}
                        </div>
                    </div>
                    {true ? <div className={`${styles["room-sell"]} booked-rate`}>
                        <label>Maximum length of stay</label>
                        <div className={styles["rooms-count"]}>
                            {propertyPrices.map(item => (
                                item.monthlyData.map((mt, index) => (
                                    <div
                                        key={index}
                                        id={moment(mt.date).format("YYYY-MM-DD")}
                                        className={`text-right cursor-pointer ${styles["room-rount"]} ${selectedDragBox === CALENDAR_DRAGGING_TYPE.NIGHT_DRAGGING ? selectCalendarStyles(mt) : ""}`}
                                        // onMouseDown={() => handleMouseDown(mt.date)}
                                        // onMouseUp={() => handleMouseUp(mt.date, mt.bookable, mt.price, CALENDAR_DRAGGING_TYPE.NIGHT_DRAGGING, mt.minNights)}
                                        // onMouseEnter={() => handleMouseEnter(mt.date)}
                                    >
                                        {mt.minNights}
                                    </div>
                                ))
                            ))}
                        </div>
                    </div> : null}
                    {promotions.map(item => (
                        <div key={item._id} className={`${styles["room-sell"]} booked-rate`}>
                            <label className={styles["room-label"]}>{item.promotionName}</label>
                            {item.promotionData.map((pd) => (
                                pd.monthlyData.map(mt => (
                                    <div
                                        key={mt._id}
                                        id={moment(mt.date).format("YYYY-MM-DD")}
                                        className={`cursor-pointer ${styles["room-rount"]} ${mt.price === "-" ? "d-flex justify-content-center" : ""}`}
                                    >
                                        {mt.startDate}
                                        {mt.price !== "-" ? <span>{mt.currencySymbol}</span> : ""}
                                        {mt.price && mt.price !== "-" ? <span>{mt.price.toFixed(2)}</span> : <span className='d-flex align-self-center justify-content-center'>-</span>}
                                    </div>
                                ))
                            ))}
                        </div>
                    ))}
                </div>

                <PropertyPromotions propertyId={selectedProperty._id} loader={props.loader} />
            </div>

            <PricePopup
                show={priceModal}
                handlePriceModal={closePriceModal}
                updatePrice={updatePrice}
                priceDetails={priceDetails}
                setPrice={setPriceDetails}
                openCloseProperty={openCloseProperty}
                setOpenClose={setOpenClose}
                selectedDates={selectedDates}
                priceDates={priceDragDate.startDate && priceDragDate.endDate ? priceDragDate : nightDragDate}
                selectedProperty={selectedProperty}
                setPriceDragDate={setPriceDragDate}
            />
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    getPropertyPrices: (params, loader, resetDates) => dispatch(VendorPropertyAction.pricingList(params, loader, resetDates)),
    getProperties: (query, loader) => dispatch(CommonAction.propertyList(query, loader)),
    updateCalendarPrice: (payload, loader, params) => dispatch(VendorPropertyAction.updateCalendarPrice(payload, loader, params)),
});

export default connect(null, mapDispatchToProps)(PropertyCalendar);