import axios from "axios";

import { Toaster } from "../components";
import { ENV_CONFIG, storage } from "../utils";

const AXIOS = axios.create({ baseURL: ENV_CONFIG.BASE_URL });

AXIOS.interceptors.request.use(
    async (config) => {
        const token = storage.getToken();
        if (token) config.headers["authorization"] = token;

        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        config.headers["timeZone"] = timeZone;
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

const Axios = {
    SUCCESS: async (res, loader) => {
        if (
            res.data &&
            (res.data.message === "Successfully." || res.data.statusCode === 200)
        ) {
            if (loader) loader(100);

            return { status: true, data: res.data.data };
        } else if (res.data.message === "Unauthorized") {
            Toaster("warn", "Token Expired. Please re-login");
            return { status: false, data: "Token Expired. Please re-login" };
        }
        else {
            // Toaster("warn", "Something Went Wrong");
            return { status: false, data: "Something Went Wrong" };
        }
    },

    FAILURE: (err) => {
        let data = { status: false };
        data.error = err.message;
        if (
            err.response &&
            err.response.data &&
            (err.response.data.success === false || err.response.data.statusCode !== 200)
        ) {
            data.error = err.response.data.message;
        }

        return data;
    },

    get: async (endPoint, query, loader = false) => {
        try {
            if (loader) loader(40);

            const res = await AXIOS.get(endPoint, { params: query });

            if (loader) {
                loader(60);
                loader(80);
            }

            return Axios.SUCCESS(res, loader);
        } catch (error) {
            if (loader) {
                loader(60);
                loader(80);
                loader(100);
            }
            return Axios.FAILURE(error);
        }
    },

    patch: async (endPoint, data, loader = false) => {
        try {
            if (loader) loader(40);
            const res = await AXIOS.patch(endPoint, data);

            if (loader) {
                loader(60);
                loader(80);
            }
            return Axios.SUCCESS(res, loader);
        } catch (error) {
            if (loader) {
                loader(60);
                loader(80);
                loader(100);
            }
            return Axios.FAILURE(error);
        }
    },

    put: async (endPoint, data, loader = false) => {
        try {
            if (loader) {
                loader(40);
            }
            const res = await AXIOS.put(endPoint, data);

            if (loader) {
                loader(60);
                loader(80);
            }
            return Axios.SUCCESS(res, loader);
        } catch (error) {
            if (loader) {
                loader(60);
                loader(80);
                loader(100);
            }

            return Axios.FAILURE(error);
        }
    },

    post: async (endPoint, data, loader = false) => {
        try {
            if (loader) loader(40);
            const res = await AXIOS.post(endPoint, data);

            if (loader) {
                loader(60);
                loader(80);
            }

            return Axios.SUCCESS(res, loader);
        } catch (error) {
            if (loader) {
                loader(60);
                loader(80);
                loader(100);
            }
            throw Axios.FAILURE(error);
        }
    },

    delete: async (endPoint, data, loader = false) => {
        try {
            if (loader) loader(40);
            const res = await AXIOS.delete(endPoint, data);

            if (loader) {
                loader(60);
                loader(80);
            }
            return Axios.SUCCESS(res, loader);
        } catch (error) {
            if (loader) {
                loader(60);
                loader(80);
                loader(100);
            }

            return Axios.FAILURE(error);
        }
    },

    getRequest: async (endPoint) => {
        try {
            const res = await axios.get(endPoint);

            return res;
        }
        catch (err) {
            return Axios.FAILURE(err);
        }
    }
};

export default Axios;
