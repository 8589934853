import React from "react";
import { Link, useLocation } from "react-router-dom";
import { APP_ROUTES } from "../../routes";

import commonStyles from "../../pages/Common/styles/common.module.css";

import homen from "../../assets/images/homen.png";
import carn from "../../assets/images/carn.png";

const BookingRequest = () => {
  const location = useLocation();

  return (
    <div className={commonStyles.catTab}>
      <ul>
        <li
          className={
            location?.pathname === APP_ROUTES.PROPERTY_REQUESTS
              ? commonStyles.active
              : ""
          }
        >
          <Link to={APP_ROUTES.PROPERTY_REQUESTS}>
            <span>
              <img src={homen} alt="" />
              Properties
            </span>
          </Link>
        </li>
        <li
          className={
            location?.pathname === APP_ROUTES.CAR_REQUESTS
              ? commonStyles.active
              : ""
          }
        >
          <Link to={APP_ROUTES.CAR_REQUESTS}>
            <span>
              <img src={carn} alt="" />
              Cars
            </span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default BookingRequest;
