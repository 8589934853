import { Axios } from "../../../lib";
import { API_ENDPOINTS, MESSAGES, TOAST_TYPE } from "../../../constants";
import ADMIN_ACTIONS from "../actions";
import { Toaster } from "../../../components";

const list = (payload, loader) => async (dispatch) => {
    try {
        const { data } = await Axios.get(API_ENDPOINTS.LIST_CMS, payload, loader);

        dispatch({ type: ADMIN_ACTIONS.SET_CMS_DETAILS, cms: data });
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.SOMETHING_WENT_WRONG);
    }
};

const update = (payload, loader) => async (dispatch) => {
    try {
        const { data } = await Axios.patch(API_ENDPOINTS.UPDATE_CMS, payload, loader);

        dispatch({ type: ADMIN_ACTIONS.SET_CMS_DETAILS, cms: data });
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.SOMETHING_WENT_WRONG);
    }
};

export {
    list,
    update,
};